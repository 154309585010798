import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  DxDataGridComponent,
  DxDropDownBoxComponent,
  DxSelectBoxComponent,
  DxTooltipComponent
} from 'devextreme-angular';
import {
  faWindowClose,
  faBolt,
  faClock,
  faFileExcel,
  faFlagCheckered,
  faPen,
  faPlus,
  faTrash,
  faLink,
  faArchive,
  faVectorSquare,
  faFilePdf,
  faFileImage,
  faFileCode,
  faFileWord,
  faFileArchive,
  faFileSignature,
  faEnvelopeOpenText,
  faFileInvoice,
  faFile,
  faFileUpload
} from '@fortawesome/free-solid-svg-icons';
import {gridDictionary} from './generator-interfaces';
import {AuthenticationService} from '../../../services/authentication.service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import {GeneratorService} from './generator.service';
import notify from 'devextreme/ui/notify';
import {ConfirmService} from '../components/confirm/confirm.service';
import {SubscriptionLike} from 'rxjs';
import {formatDate, registerLocaleData} from '@angular/common';
import {DateGroupedItemsService} from '../date-grouped-items.service';
import Ru from '@angular/common/locales/ru';
import {ConractTP} from "../../object/contract-tp/contract-tp.component";
import {LinkedInvNumbers} from "../../subobjects/linked-inv-numbers/linked-inv-numbers.component";
import {MatDialog} from "@angular/material/dialog";
import {AgreementButtonComponent} from "../../diagram-agreement/agreement-button/agreement-button.component";
import {DocumentService} from '../../../services/document.service';

@Component({
  selector: 'app-list-generator',
  templateUrl: './list-generator.component.html',
  styleUrls: ['./list-generator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DocumentService]
})
export class ListGeneratorComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() dictionary: gridDictionary;
  @Input() hideId: number[];
  @Input() dataSource: any;
  store: CustomStore;
  @Input() heightGrid;
  @Input() widthGrid;
  @Input() filterValue: any;
  @Input() idDxDataGrid: string;
  @Input() flex: boolean = false;
  @Input() orgUnits;
  @Input() isInvestElements: boolean = false;
  @Input() inputData = {typeLink: 'object'};
  @Input() sumExcludeAnnuledEnterOf:boolean = false;
  now: Date;

  @Output() rowClick = new EventEmitter<any>();
  @Output() addClick = new EventEmitter<any>();
  @Output() copyClick = new EventEmitter<any>();
  @Output() deleteClick = new EventEmitter<any>();
  @Output() refreshClick = new EventEmitter<any>();
  @Output() saveClick = new EventEmitter<any>();
  @Output() choiceClick = new EventEmitter<any>();
  @Output() dropDownClick = new EventEmitter<any>();
  @Output() reportClick = new EventEmitter<any>();
  @Output() clickCustomButton = new EventEmitter<any>();
  @Output() modifiedData = new EventEmitter<any>();
  @Output() buttonIconClick = new EventEmitter<any>();
  @Output() rowDblClick = new EventEmitter<any>();
  @Output() changeDefaultFilter = new EventEmitter<any>();
  @Output() rowIndexChange = new EventEmitter<any>();
  @Output() rowValidation = new EventEmitter<any>();
  @Output() onLoaded = new EventEmitter<any>();
  @Output() onInserted = new EventEmitter<any>();
  @Output() cellClick = new EventEmitter<any>();
  @Output() afterDelete = new EventEmitter<any>();
  // @Output() onRowPrepared = new EventEmitter<any>();
  @Output() EditingStart = new EventEmitter<any>();
  @Output() CellPrepared = new EventEmitter<any>();
  @Output() buttonAgreementClick = new EventEmitter<any>();

  @Output() columnDropDowRowClick = new EventEmitter<any>();
  @Output() selectedRowKeysChange = new EventEmitter<any>();
  @Output() buttonDeleteVersionClick = new EventEmitter<any>();
  @Output() buttonCreateVersionClick = new EventEmitter<any>();
  something: true;
  focusedIndex: any;
  idFocusedRow: any;
  sortID: string;
  @Input() selectedRowKeys: any[] = [];
  @Input() focusedRowKey: any;
  public valid = true;
  currentFilter: any;
  readOnly: any;
  @ViewChild('dataGridContainer', {static: false}) dataGrid: DxDataGridComponent;
  @ViewChild('tooltip', {static: false}) tooltip: DxTooltipComponent;
  @ViewChild('dropDownDefaultFilter', {static: false}) dropDownDefaultFilter: DxDropDownBoxComponent;
  @ViewChild('agreementButton', {static: false}) agreementButton: AgreementButtonComponent;


  @ViewChild('dropBoxSubobjectVersion', {static: false}) dropDownSubobjectVersion: DxDropDownBoxComponent;
  @ViewChild('selectBoxSubobjectHolder', {static: false}) selectBoxSubobjectHolder: DxSelectBoxComponent;
  fakeId = -1;
  faTrash = faTrash;
  faPen = faPen;
  faPlus = faPlus;
  faFileExcel = faFileExcel;
  faFlagCheckered = faFlagCheckered;
  faClock = faClock;
  faWindowClose = faWindowClose;
  faBolt = faBolt;
  faLink = faLink;
  faArchive = faArchive;
  faVectorSquare = faVectorSquare;
  faFileUpload = faFileUpload;
  faFileArchive = faFileArchive;
  externalFilter: any;
  copyingData: number;
  ifButtonClick = false;
  clickedColumnButton = false;
  subscriptions: SubscriptionLike[] = [];
  buttonOptions: any = {
    text: 'OK',
    type: 'success',
    icon: 'check',
    useSubmitBehavior: true
  };
  totalCount = 0;
  defaultFilterButton = {
    icon: 'filter',
    onClick: () => {
      this.dropDownDefaultFilter.instance.open();
    }
  };
  dropDownBox = false;
  dropDownContent = false;
  private btnAdd: any = null;
  public btnDelete: any = null;
  private btnChoice: any = null;
  private btnCopy: any = null;
  private btnSave: any = null;
  private btnReport: any = null;
  savingData = false;
  ToolTipText;
  TooltipTarget;
  ToolTipTextAdd;
  @Input() colorMarkerTooltip = `Серый - срок не указан;
Зеленый - осталось более 3х дней;
Желтый - осталось менее 3х дней;
Оранжевый - исполнить сегодня;
Красный - просрочено;`;
  loading = false;
  allowEditInvNum = false;
  paymentType = [{id: 1, name: 'Аванс'}, {id: 2, name: 'Постоплата'}];
  @Input() isContracts;
  @Input() typeLinkDocument = 'object';
  @Input() mainDocumentId;
  filteringMainDoc = true;

  constructor(private auth: AuthenticationService,
              private service: GeneratorService,
              public dialog: MatDialog,
              private confirmDialog: ConfirmService,
              private changeDetector: ChangeDetectorRef,
              private dateService: DateGroupedItemsService,
              private documentService: DocumentService) {
    registerLocaleData(Ru, 'ru');
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.calculateSummary = this.calculateSummary.bind(this);
    this.allowEditInvNum = !!this.auth.USER.rights.find(x => x.right === 'allowEditInvNum' && x.permission === 'W');
  }

  calculateSummary(options) {
    if (options.name) {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      } else {
        if (options.value) {
          const status = this.sumExcludeAnnuledEnterOf ? options.value.document_status : options.value.status;
          if (status !== 'Аннулирован') {
            options.totalValue = options.totalValue + options.value[options.name]
            //+ (options.name.includes('planCost') ? options.value.planCost : options.value.factCost);
          }
        }
      }
    }
  }

  ngOnInit() {

    if (this.dictionary && this.dictionary.columns.filter(x => x.sort).length === 0) {
      this.sortID = this.dictionary.defSort || 'desc';
    }

    if (this.dataSource) {
      this.totalCount = this.dataSource.length;
    }
    if (this.dictionary.defaultFilter && this.dictionary.defaultFilter.dataSource.initDataSource) {
      this.dictionary.defaultFilter.dataSource.initDataSource(this.dictionary.defaultFilter.dataSource.values);
    }

    this.dictionary.columns.filter(x => x.dictionary).forEach(x => {
        if (x.dictionary.dataSource.length === 0) {
          this.service.getListSimple(x.dictionary.get).subscribe(dict => x.dictionary.dataSource = dict.items ? dict.items : dict);
        }
      }
    );

    if (this.dictionary.remoteOperations) {
      this.store = new CustomStore({
        key: 'id',
        load: (options) => {
          //let filter = options.filter;
          let filter = this.dataGrid.instance.getCombinedFilter() ? this.dataGrid.instance.getCombinedFilter() : '';
          /*if (!filter) {
            filter = _filter
          }*/

          if (this.hideId && this.hideId.length > 0) {
            const arrayFilters = [];
            for (const id of this.hideId) {
              if (arrayFilters.length > 0) {
                arrayFilters.push('or');
              }
              const a = [this.dictionary.primaryKey, '=', id];
              arrayFilters.push(a);
            }

            if (filter) {
              filter = [filter, 'and', ['!', arrayFilters]];
            } else {
              filter = ['!', arrayFilters];
            }
          }

          if (this.dictionary.defaultFilter) {
            if (this.dictionary.defaultFilter.dataSource.checkData) {
              this.dictionary.defaultFilter.dataSource.checkData(this.dictionary.defaultFilter.dataSource.values);
            }
            if (filter) {
              filter = [filter, 'and'];
            } else {
              filter = [];
            }
            if (this.dictionary.defaultFilter.dataSource.getFilterInfo) {
              const f = this.dictionary.defaultFilter.dataSource.getFilterInfo(this.dictionary.defaultFilter.dataSource.values, this.externalFilter);
              filter = filter.concat(f);
            }
          }

          let filterValue = [];
          if (this.dictionary.defaultFilterValue) {
            filterValue = this.dictionary.defaultFilterValue.filterValue;
            if (!filterValue) {
              filterValue = [];
            }
            const f = this.dictionary.defaultFilterValue.getFilterValue(this.dictionary.defaultFilter && this.dictionary.defaultFilter.dataSource.values, filter);
            if (f) {
              filterValue = filterValue.concat(f);
            }
          }

          let group = options.group;
          if (group && group[0].selector === 'fullDate') {
            group = [{selector: 'date', groupInterval: 'year', isExpanded: true},
              {selector: 'date', groupInterval: 'month', isExpanded: true},
              {selector: 'date', groupInterval: 'day', isExpanded: true}];
          }

          return this.service.getList(this.dictionary.get, options.take, options.skip, filter, options.sort, group, filterValue, this.dictionary.queryParam, (options.group && !options.take)).toPromise()
            .then(result => {
              if (!options.group) {
                if (!!result.count && result.count >= 0) {
                  this.totalCount = result.count;
                } else {
                  this.totalCount = 0;
                }
              }
              const Items = result.items;
              Items.forEach(x => x.statusRow = 's');
              let _dateType;
              if (options.group) {
                const col = this.dictionary.columns.find(x => x.dataField === options.group[0].selector);
                if (col && col.dataType && col.dataType.includes('date')) {
                  _dateType = true;
                }
              }
              return {
                type: 'array',
                key: 'id',
                data: _dateType ? this.dateService.getGroupedDate(Items) : Items,
                totalCount: result.count
              };
            }).catch(error => {
              throw error.error;
            });
        },
        insert: (values) => {
          this.savingData = true;
          if (this.dictionary.fields) {
            for (const f of Object.keys(values)) {
              if (!this.dictionary.fields.includes(f)) {
                delete values[f];
              }
            }
          }
          values[this.dictionary.primaryKey] = undefined;
          return this.service.postList(this.dictionary.post, values).toPromise().catch(error => {
            throw error.error;
          });
        },
        update: (key, values) => {
          this.savingData = true;
          if (this.dictionary.fields) {
            for (const f of Object.keys(values)) {
              if (!this.dictionary.fields.includes(f)) {
                delete values[f];
              }
            }
          }
          values[this.dictionary.primaryKey] = key;
          return this.service.putList(this.dictionary.put, values).toPromise().catch(error => {
            throw error.error;
          });
        },
        onLoaded: result => {
          this.onLoaded.emit(result);
        },
        onInserted: (values, key) => {
          this.onInserted.emit({values, key});
        }
      });

      this.dataSource = new DataSource({
        store: this.store
      });
    }
  }


  ngAfterViewInit(): void {
    if (this.heightGrid) {
      this.dataGrid.height = this.heightGrid;
    }
    if (this.widthGrid) {
      this.dataGrid.width = this.widthGrid;
    }
    this.refreshData();
  }

  refreshData(dictionary = null) {
    if (dictionary) {
      this.dictionary = dictionary;
    }
    if (this.dictionary.editRole) {
      if (!this.auth.checkRights(this.dictionary.editRole, 'W', false)) {
        this.dictionary.allowAdd = this.dictionary.allowDelete = this.dictionary.allowUpdate = this.dictionary.fastDelete =
          this.dictionary.allowCopy = false;
      }
    }

    if (this.dictionary && this.dictionary.get && !this.dictionary.remoteOperations) {
      this.subscriptions.push(this.service.getListSimple(this.dictionary.get).subscribe(data => {
        const items = data.items || data;
        items.forEach(x => x.statusRow = 's');
        console.log(items);
        if (this.hideId) {
          this.dataSource = items.filter(x => !this.hideId.includes(x[this.dictionary.primaryKey]));
        } else {
          this.dataSource = items;
        }
        this.totalCount = this.dataSource.length;

        if (this.dictionary.postDataSource) {
          this.dictionary.postDataSource(this.dataSource);
        }
      }));
    } else {
      if (this.dataGrid)
      this.dataGrid.instance.refresh();
    }

    if (this.dictionary.columns) {
      this.dictionary.columns.filter(x => x.dictionary && x.dictionary.get).forEach(x => {
          this.subscriptions.push(this.service.getListSimple(x.dictionary.get).subscribe(
            dict => {
              x.dictionary.dataSource = dict.items ? dict.items : dict;
              this.dataGrid.instance.columnOption(x.dataField, 'lookup', {
                valueExpr: x.dictionary.id,
                displayExpr: x.dictionary.name,
                dataSource: x.dictionary.dataSource
              });
            }
          ));
        }
      );
    }
    if (!this.dictionary.remoteOperations) {
      this.dataGrid.instance.deselectAll();
    }
  }

  onInitNewRow(data) {
    if (this.copyingData) {
      Object.assign(data.data, this.copyingData);
    }
    data.data.statusRow = 'i';
    data.data[this.dictionary.primaryKey] = this.fakeId--;
    this.modifiedData.emit(data.data);
  }

  onRowUpdating(data) {
    const col = this.dictionary.columns.find(x => x.dataField === Object.keys(data.newData)[0]);
    if (!this.dictionary.remoteOperations) {
      if (!col.cancelUpdate) {
        const item = this.dataSource.find(x => x[this.dictionary.primaryKey] == data.key);
        if (item && item.statusRow !== 'i') {
          item.statusRow = 'u';
        }
      }
    }
    if (col.onChangeValue) {
      col.onChangeValue(this.dataSource, data);
    }
    this.modifiedData.emit();
  }

  onCellClick(e) {
    if (!this.ifButtonClick) this.cellClick.emit(e);
  }

  onRowClick(rowInfo) {
    if (!this.clickedColumnButton) {
      this.rowClick.emit(rowInfo.data);
      if (this.dictionary.choiceOnClick) {
        this.choiceClick.emit(rowInfo.data);
      }

      if (this.dictionary.selectedOnClick) {
        if (this.dictionary.selectedMode === 'single') {
          this.selectedRowKeys = [rowInfo.data[this.dictionary.primaryKey]];
        }
      }
    }
    this.clickedColumnButton = false;
  }

  onRowDblClick(rowInfo) {
    this.rowDblClick.emit(rowInfo.data);
  }

  openRelatedDocuments(e) {
    // if (this.e.connectionContractNumber && this.e.id) {
    this.ifButtonClick = true;
    const dialogRef = this.dialog.open(LinkedInvNumbers, {
      minWidth: '30%',
      maxWidth: '85%',
      maxHeight: '100vh - 100px',
      disableClose: false,
      data: {id: e.id, number: e.inventoryNumber}
    });
    dialogRef.afterClosed().subscribe(() => this.ifButtonClick = false);
    // }
  }

  onClickDelete() {
    let deletingId = this.selectedRowKeys;
    let deletedRows = [];
    for (const key of deletingId) {
      const rowIndex = this.dataGrid.instance.getRowIndexByKey(key);
      const row = this.dataGrid.instance.getVisibleRows()[rowIndex].data;
      deletedRows.push(row);
    }

    if (this.dictionary.beforeDelete) {
      deletingId = this.dictionary.beforeDelete(deletedRows);
      if (!deletingId || deletingId.length === 0) {
        return;
      }
    }

    if (this.dictionary.fastDelete) {
      this.deleteClick.emit(deletingId);

      if (this.dictionary.getDeleteField) {
        deletedRows = deletedRows.map(x => {
          x.customDeletedText = this.dictionary.getDeleteField(x);
          return x;
        });
      }
      this.confirmDialog.confirm('Подтверждение', 'Вы действительно хотите удалить выбранные элементы?', false, this.dictionary.getDeleteField ? 'customDeletedText' : this.dictionary.fieldDelete || 'name', deletedRows).subscribe(
        async result => {
          if (result) {
            const isAnyKs14 = deletedRows.some(x => x.typeId === -24);
            if (isAnyKs14) {
              const ks14Rows = deletedRows.filter(x => x.typeId === -24).map(x => x.id);
              const hasFact = await this.factCharactCheck(ks14Rows);
              if (hasFact) return;
            }
            if (this.dictionary.delete) {
              this.loading = true;
              this.subscriptions.push(this.service.delete(this.dictionary.delete, deletingId).subscribe(data => {

                if (this.dictionary.afterDelete) {
                  const deletedRows = [];
                  for (const key of deletingId) {
                    const rowIndex = this.dataGrid.instance.getRowIndexByKey(key);
                    const row = this.dataGrid.instance.getVisibleRows()[rowIndex].data;
                    if (row) {
                      deletedRows.push(row);
                    }
                  }
                  this.dictionary.afterDelete(deletedRows);
                  this.afterDelete.emit(deletingId);
                } else if (!this.dictionary.remoteOperations) {
                  this.dataSource = this.dataSource.filter(x => !deletingId.includes(x[this.dictionary.primaryKey]));
                } else if (this.dictionary.remoteOperations) {
                  this.dataGrid.instance.getDataSource().reload();
                }
                notify({message: 'Данные успешно удалены!', width: 800}, 'success', 1500);
                this.dataGrid.instance.deselectAll();
                this.updateBtnStates();
                this.loading = false;
              }, error => {
                this.loading = false;
                // notify(error.message, 'error', 2500);
              }));
            } else {
              this.deleteClick.emit(deletingId);
              this.dataSource = this.dataSource.filter(x => !deletingId.includes(x[this.dictionary.primaryKey]));
              this.totalCount = this.dataSource.length;
            }
          }
        }
      );
    } else {
      this.dataSource.filter(x => deletingId.includes(x[this.dictionary.primaryKey])).forEach(
        x => {
          if (x.statusRow !== 'd') {
            x.statusRowOld = x.statusRow;
            x.statusRow = 'd';
          } else {
            x.statusRow = x.statusRowOld;
            x.statusRowOld = null;
          }
        }
      );
      this.dataGrid.instance.refresh();
      this.modifiedData.emit();
      this.deleteClick.emit(deletedRows);
    }
  }

  async factCharactCheck(ks14Rows: any[]) {
    try {
      this.loading = true;
      const data = await this.documentService.getIsAnyFactCharacteristics(ks14Rows).toPromise();
      this.loading = false;

      if (!data) {
        return false;
      }

      const result = await this.confirmDialog.confirm('Подтверждение', 'Среди выбранных документов есть КС-14, при их удалении будут также удалены фактические значения характеристик. Желаете продолжить?', false).toPromise();

      return !result;
    } catch (error) {
      throw error.error;
    }
  }

  onChoiceRows() {
    if (this.dictionary.remoteOperations) {
      this.choiceClick.emit(this.dataGrid.instance.getSelectedRowsData());
    } else {
      this.choiceClick.emit(this.dataSource.filter(x => this.selectedRowKeys.includes(x[this.dictionary.primaryKey])));
    }
  }

  selectedRow(data: any, s = false) {
    if (s) {
      this.selectedRowKeys = data;
    } else {
      this.selectedRowKeys = data.selectedRowKeys;
    }
    this.updateBtnStates();
  }

  onAddRow() {
    if ((this.dictionary.editMode === 'cell' || this.dictionary.editMode === 'batch') && !this.dictionary.addRowCustom) {
      this.dataGrid.instance.addRow();
    } else {
      this.addClick.emit();
    }
  }

  onCopyRow() {
    if (this.selectedRowKeys.length > 0) {
      const rowIndex = this.dataGrid.instance.getRowIndexByKey(this.selectedRowKeys[this.selectedRowKeys.length - 1]);
      const row = this.dataGrid.instance.getVisibleRows()[rowIndex].data;
      if (this.dictionary.remoteOperations && this.dictionary.post) {
        this.copyingData = row;
        this.dataGrid.instance.addRow();
        this.copyingData = undefined;
      }
      this.copyClick.emit(row);
      this.dataGrid.instance.deselectAll();
    }
  }

  public addNewRow(data, newRow: boolean = true) {
    if (!this.dictionary.remoteOperations) {
      this.dataSource.push(data);
      if (this.dictionary.postDataSource) {
        this.dictionary.postDataSource(this.dataSource);
      }
      if (newRow) {
        data.statusRow = 'i';
        this.modifiedData.emit();
      }
    } else {
      this.dataSource._items.push(data);
      this.dataGrid.instance.getDataSource().reload();
    }
  }

  public updateDataRow(data, modified: boolean = true) {
    if (!this.dictionary.remoteOperations) {
      const updateItem = this.dataSource.find(x => x[this.dictionary.primaryKey] === data[this.dictionary.primaryKey]);
      const index = this.dataSource.indexOf(updateItem);
      this.dataSource[index] = data;
      if (this.dictionary.postDataSource) {
        this.dictionary.postDataSource(this.dataSource);
      }
    } else {
      if (this.dictionary.postDataSource) {
        this.dictionary.postDataSource(this.dataSource._items);
      }
      this.dataGrid.instance.getDataSource().reload();
    }
    if (modified) {
      data.statusRow = 'u';
      this.modifiedData.emit();
    }
  }

  onRefreshClick(rowInfo) {
    this.refreshClick.emit(rowInfo);
    this.refreshData();
  }

  onSaveClick(rowInfo) {
    this.saveClick.emit(rowInfo);
    this.refreshData();
    this.dataGrid.instance.deselectAll();
  }

  onClickButtonDropDown(data) {
    this.dropDownClick.emit(data.itemData);
  }

  onReportClick(data) {
    this.reportClick.emit(data.itemData);
  }

  onClickCustomButton(data) {
    this.clickCustomButton.emit(data.itemData || data);
  }

  onClickExport(data) {
    this.dataGrid.instance.exportToExcel(data.itemData.value === 2);
  }

  public closeEditor() {
    this.dataGrid.instance.saveEditData();
  }

  focusedRowIndexChange(e) {
    this.rowIndexChange.emit(this.dataGrid.instance.getKeyByRowIndex(e));
    this.focusedIndex = e;
  }

  onSelectedRowKeysChange(e) {
    this.selectedRowKeysChange.emit(e);
  }

  onEditingStart(e) {
    this.EditingStart.emit(e);
  }

  onCellPrepared(e) {
    this.CellPrepared.emit(e);
  }

  setTooltipTargetSubChecked(targetId = null, text = null) {
    this.ToolTipTextAdd = null;
    if (text && text.length > 100) {
      this.TooltipTarget = targetId ? '#' + targetId : null;
      this.ToolTipText = text;
    } else this.TooltipTarget = this.ToolTipText = null;
  }

  setCheckedTooltipTargetSubChecked(targetId, data, d) {
    if (data.checked && data.checkedOfficial && !d.row.modified) {
      this.TooltipTarget = '#' + targetId;
      this.ToolTipText = `Подтвердил(а): ${data.checkedOfficial}`;
      this.ToolTipTextAdd = data.checkedDate;
    }
  }

  onCellHoverChanged(e) {
    if (e && e.column && e.column.dataField) {
      const c = this.dictionary.columns.find(x => x.dataField === e.column.dataField);
      if (e.rowType === 'data' && c && c.getTooltipText) {
        if (e.eventType === 'mouseover') {
          this.tooltip.target = e.cellElement;
          this.tooltip.contentTemplate = c.getTooltipText(e.data);
          this.tooltip.instance.show();
        } else {
          this.tooltip.instance.hide();
        }
      }

      if (e.rowType === 'header' && c && c.captionTooltipText) {
        if (e.eventType === 'mouseover') {
          this.tooltip.target = e.cellElement;
          this.tooltip.contentTemplate = c.captionTooltipText;
          this.tooltip.instance.show();
        } else {
          this.tooltip.instance.hide();
        }
      }
    }

    if (e && e.rowType === 'data' && e.data[this.dictionary.primaryKey]) {
      this.idFocusedRow = e.data[this.dictionary.primaryKey];
    } else {
      this.idFocusedRow = 0;
    }
    this.changeDetector.detectChanges();
  }

  getTextToolTip(data) {
    console.log(data.row.values);
  }

  onToolbarPreparing(e) {
    const items = [];

    if (this.dictionary.hideBatchButton) {
      const toolbarItems = e.toolbarOptions.items;
      toolbarItems.forEach(item => {
        if (item.name == 'saveButton' || item.name == 'revertButton') {
          item.visible = false;
        }
      });
    }

    if (this.dictionary.toolbarTemplate) {
      this.dictionary.toolbarTemplate.forEach(x => items.push(
        {
          location: x.location,
          template: x.template,
          locateInMenu: 'auto',
          showText: 'inMenu',
        }
      ));
    }

    if (this.dictionary.showCaption) {
      items.push(
        {
          location: 'before',
          template: 'gridCaption',
          locateInMenu: 'auto',
          showText: 'inMenu',
        }
      );
    }

    if (this.dictionary.defaultFilter) {
      items.push(
        {
          location: !this.dictionary.defaultFilter.alligmentRight ? 'before' : 'after',
          template: 'defaultFilter',
          locateInMenu: 'auto',
          showText: 'inMenu',
        }
      );
    }

    if (this.dictionary.allowChoice) {
      items.push(
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          showText: 'inMenu',
          options: {
            hint: 'Выбрать',
            text: 'Выбрать',
            disabled: true,
            icon: 'check',
            onInitialized: (args: any) => {
              this.btnChoice = args.component;
            },
            onClick: this.onChoiceRows.bind(this)
          }
        }
      );
    }

    if (this.dictionary.allowSave) {
      items.push(
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          showText: 'inMenu',
          options: {
            hint: 'Сохранить изменения',
            text: 'Сохранить изменения',
            icon: 'save',
            onInitialized: (args: any) => {
              this.btnSave = args.component;
            },
            onClick: this.onSaveClick.bind(this)
          }
        }
      );
    }

    if (this.dictionary.allowDelete || this.dictionary.fastDelete) {
      items.push({
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        showText: 'inMenu',
        options: {
          hint: 'Удалить',
          text: 'Удалить',
          icon: 'trash',
          disabled: true,
          onInitialized: (args: any) => {
            this.btnDelete = args.component;
          },
          onClick: this.onClickDelete.bind(this)
        }
      });
    }

    if (this.dictionary.allowCopy) {
      items.push({
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        showText: 'inMenu',
        options: {
          hint: 'Копировать',
          text: 'Копировать',
          disabled: true,
          icon: 'copy',
          onInitialized: (args: any) => {
            this.btnCopy = args.component;
          },
          onClick: this.onCopyRow.bind(this)
        }
      });
    }

    if (this.dictionary.allowAdd) {
      items.push({
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        showText: 'inMenu',
        options: {
          hint: 'Добавить',
          text: 'Добавить',
          onInitialized: (args: any) => {
            this.btnAdd = args.component;
          },
          icon: 'plus',
          onClick: this.onAddRow.bind(this)
        }
      });
    }

    if (this.dictionary.dropDownButton) {
      for (const dropDown of this.dictionary.dropDownButton
        .filter(b => !b.checkRights || this.auth.checkRights(this.dictionary.editRole, b.checkRights, false))) {
        items.push({
          location: 'after',
          widget: 'dxDropDownButton',
          locateInMenu: 'auto',
          showText: 'inMenu',
          options: {
            width: 60,
            height: 46,
            items: dropDown.items,
            text: dropDown.text ? dropDown.text : undefined,
            displayExpr: 'name',
            keyExpr: 'id',
            dropDownOptions: {width: dropDown.dropDownWidth ? dropDown.dropDownWidth : undefined},
            hint: dropDown.hint ? dropDown.hint : undefined,
            icon: dropDown.icon ? dropDown.icon : undefined,
            onItemClick: this.onClickButtonDropDown.bind(this)
          }
        });
      }
    }

    if (this.dictionary.customButton) {
      for (const button of this.dictionary.customButton
        .filter(b => !b.checkRights || this.auth.checkRights(this.dictionary.editRole, b.checkRights, false))) {
        items.push({
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          showText: 'inMenu',
          options: {
            hint: button.hint,
            text: button.hint,
            icon: button.icon,
            onClick: this.onClickCustomButton.bind(this),
            visible: !button.visible
          }
        });
      }
    }

    if (this.dictionary.allowReport) {
      items.push({
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        showText: 'inMenu',
        options: {
          text: this.dictionary.allowReport,
          hint: this.dictionary.allowReport,
          icon: 'file',
          onInitialized: (args: any) => {
            this.btnReport = args.component;
          },
          onClick: this.onReportClick.bind(this)
        }
      });
    }

    if (this.dictionary.allowRefresh) {
      items.push({
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        showText: 'inMenu',
        options: {
          hint: 'Обновить',
          text: 'Обновить',
          icon: 'refresh',
          onClick: this.onRefreshClick.bind(this)
        }
      });
    }

    for (const i of items) {
      e.toolbarOptions.items.unshift(i);
    }

    if (!this.dictionary.hideExport) {
      const exportBtnItem = e.toolbarOptions.items.find(
        item => item.name === 'exportButton'
      );
      const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
      e.toolbarOptions.items[exportBtnIndex] = {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxDropDownButton',
        showText: 'inMenu',
        options: {
          width: 60,
          height: 46,
          icon: 'fa fa-file-excel-o',
          hint: 'Экспорт в excel',
          dropDownOptions: {width: 250},
          items: [
            {value: 1, name: 'Выгрузить все', icon: 'menu'},
            {value: 2, name: 'Выгрузить отмеченные', icon: 'selectall'}],
          displayExpr: 'name',
          keyExpr: 'value',
          onItemClick: this.onClickExport.bind(this)
        }
      };
    }

    if (this.dictionary.disableDropDownButtons !== undefined && this.dictionary.disableDropDownButtons !== null) {
      items.forEach(item => {
        if (item.widget === 'dxDropDownButton') {
          item.disabled = this.dictionary.disableDropDownButtons;
        }
      });
    }
  }

  onRowPrepared(e) {
    if (e.rowType == 'data' && !this.dictionary.remoteOperations) {
      const id = e.data[this.dictionary.primaryKey];
      if (id && e.data.statusRow === 'd') {
        //e.rowElement.style.backgroundColor = '#FFC5B6';
        e.rowElement.style.textDecoration = 'line-through';
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
        e.rowElement.classList.add('background-light-red');
      } else if (id && e.data.statusRow === 'i') {
        //e.rowElement.style.backgroundColor = '#AFEEFF';
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
        e.rowElement.classList.add('background-light-blue');
      } else if (id && e.data.statusRow === 'u') {
        //e.rowElement.style.backgroundColor = '#FFF3AF';
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
        e.rowElement.classList.add('background-light-yello');
      }
    } else if (e.rowType === 'data' && this.dictionary.remoteOperations) {
      if (this.dictionary.rowPrepared) {
        this.dictionary.rowPrepared(e.rowElement, e.data, this.auth.USER.id, this.focusedRowKey);
      }
    }
  }

  HeaderFilter(data) {
    data.dataSource.postProcess = (results) => {
      const filter = [];
      results.filter(x => x.value).forEach(x => {
        for (const v of x.value.split(', ')) {
          if (!filter.includes(v)) {
            filter.push(v);
          }
        }
      });
      results = [];
      results.push({text: '(Пустое)', value: null});
      filter.forEach(x => results.push({text: x, value: x}));
      return results;
    };
  }

  FilterExpression(value, selectedFilterOperations, target) {
    const column = this as any;
    if (target === 'headerFilter' && value !== null) {
      return [column.dataField, 'contains', value];
    }
    return column.defaultCalculateFilterExpression.apply(this, arguments);
  }

  getModifedRow(): any[] {
    const rows = [];
    this.dataSource.forEach(x => {
        if (x.statusRow !== 's') {
          rows.push(x);
        }
      }
    );
    return rows;
  }

  onRowValidating(event) {
    this.rowValidation.emit(event);
    this.valid = event.isValid;
  }

  public validData(): any {
    this.valid = true;
    this.dataGrid.instance.saveEditData();
    if (!this.valid) {
      notify(`Не заполнены обязательные поля в таблице "${this.dictionary.name}"`, 'error', 2500);
    }
    return this.valid;
  }

  public getKeysDataSource(key = null) {
    if (!this.dictionary.remoteOperations) {
      return this.dataSource.map(x => {
        return x[this.dictionary.primaryKey];
      });
    } else {
      return this.dataSource._items.map(x => {
        return x[key || this.dictionary.primaryKey];
      });
    }
  }

  public getKeysDataSourceWithoutDeletedRows() {
    return this.dataSource.filter(x => x.statusRow !== 'd').map(x => {
      return x[this.dictionary.primaryKey];
    });
  }

  public getDataSource() {
    if (!this.dictionary.remoteOperations) {
      return this.dataSource;
    } else {
      return this.dataSource._items;
    }
  }

  onClosed(e) {
    /*this.defaultFilter.dateStart = this.oldDefaultFilter.dateStart;
    this.defaultFilter.dateEnd = this.oldDefaultFilter.dateEnd;
    this.defaultFilter.login = this.oldDefaultFilter.login;*/
  }

  applyDefaultFilter(e, CustomFilter = false) {
    /*this.oldDefaultFilter.dateStart = this.defaultFilter.dateStart;
    this.oldDefaultFilter.dateEnd = this.defaultFilter.dateEnd;
    this.oldDefaultFilter.login = this.defaultFilter.login;*/
    this.dropDownDefaultFilter.instance.close();
    this.dictionary.defaultFilter.dataSource.filterText = this.dictionary.defaultFilter.dataSource.setFilterText(this.dictionary.defaultFilter.dataSource.values, this.externalFilter);
    this.dataGrid.instance.refresh();
    this.changeDefaultFilter.emit({
      filter: this.dictionary.defaultFilter.dataSource.values,
      data: this.externalFilter
    });
  }

  setExternalFilter(data, refresh = true) {
    this.externalFilter = data;
    this.dictionary.defaultFilter.dataSource.filterText = this.dictionary.defaultFilter.dataSource.setFilterText(this.dictionary.defaultFilter.dataSource.values, data);
    if (refresh) {
      this.dataGrid.instance.refresh();
    }
  }

  mouseenterDropDownBox(type) {
    (type === 'dropDownBox') ? this.dropDownBox = true : this.dropDownContent = true;
    this.dropDownDefaultFilter.instance.open();
  }

  mouseleaveDropDownBox(type) {
    (type === 'dropDownBox') ? this.dropDownBox = false : this.dropDownContent = false;
    setTimeout(() => {
      if (!this.dropDownBox && !this.dropDownContent) {
        this.dropDownDefaultFilter.instance.close();
      }
    }, 250);
  }

  clickButtonIcon(id, rowInfo) {
    this.clickedColumnButton = true;
    this.buttonIconClick.emit({id, data: rowInfo.data});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
    this.subscriptions = [];
    if (this.dataGrid)
      this.dataGrid.instance.dispose();
    if (this.dataSource && this.dictionary.remoteOperations) {
      this.dataSource.dispose();
    }
  }

  reload() {
    this.dataGrid.instance.getDataSource().reload();
  }

  openCustomLink(customLink, id, isObject = true, objectId?, typeLink = 'object') {
    if (id) {
      if (!isObject && !objectId) {
        this.service.getObjectBySubobjectId(id, typeLink).subscribe(data => {
          console.log(data);
          if (data && data.objectId) {
            window.open(
              window.location.origin + '/#/' + customLink + '/' + data.objectId,
              '_blank'
            );
          } else {
            notify('Не найден объект для указанного подобъекта, переход невозможен', 'error', 2500);
          }
        });
      } else {
        window.open(
          window.location.origin + '/#/' + customLink + '/' + (objectId || id),
          '_blank'
        );
      }
    }
  }

  clickColumnCustomButton(id, data) {
    if (id === 'button-agreement') this.buttonAgreementClick.emit({id, data: data.data, rowIndex: data.rowIndex})
    else {
      this.clickedColumnButton = true;
      this.buttonIconClick.emit({id, data: data.data, rowIndex: data.rowIndex});
    }
  }

  disabledSave(readOnly) {
    if (this.btnSave != null) {
      this.btnSave.option({
        disabled: readOnly
      });
    }
  }

  disabledReport(readOnly) {
    if (this.btnReport != null) {
      this.btnReport.option({
        disabled: readOnly
      });
    }
  }

  disabledAdd(readOnly) {
    if (this.btnAdd != null) {
      this.btnAdd.option({
        disabled: readOnly
      });
    }
  }

  customEditorCell(cellInfo, event) {
    cellInfo.setValue(event.value);
    this.modifiedData.emit();
  }

  checkValidationRules() {
    this.valid = true;
    const gridInstance = this.dataGrid.instance as any;
    const res = gridInstance.getController('validating').validate(true);
    return this.valid;
  }

  getColumnByFieldName(field) {
    return this.dictionary.columns.find(x => x.dataField === field);
  }

  private updateBtnStates() {
    if (this.btnDelete !== null) {
      this.btnDelete.option({
        disabled: this.selectedRowKeys.length === 0
      });
    }
    if (this.btnChoice != null) {
      this.btnChoice.option({
        disabled: this.selectedRowKeys.length === 0
      });
    }

    if (this.btnCopy != null) {
      this.btnCopy.option({
        disabled: this.selectedRowKeys.length === 0
      });
    }
  }

  deleteVersion(id) {
    this.buttonDeleteVersionClick.emit(id)
  }

  createVersion(id) {
    this.buttonCreateVersionClick.emit(id)
  }

  onEditorPreparing(e, event) {
    if (e.parentType === 'dataRow' && e.command !== 'select') {
      const onValueChanged = e.editorOptions.onValueChanged;
      e.editorOptions.onValueChanged = function (args) {
        if (e.parentType === 'dataRow' && e.dataField !== undefined && e.dataField === 'planCost' && e.row.data.defaultSetDocumentId !== undefined) {
          if (e.row.data.factCost !== undefined && e.row.data.factCost === null &&
            (e.row.data.factCostOriginal !== undefined || e.row.data.statusRow === 'i')) {
            if (args.value > 0 &&
              ((e.row.data.factCostOriginal === null || (e.row.data.factCostOriginal !== undefined && e.row.data.factCostOriginal.length === 0) ||
                e.row.data.factCostOriginal === 0) || e.row.data.statusRow === 'i')) {
              e.row.data.factCost = args.value;
              if (e.row.data.statusRow === 's') {
                e.row.data.statusRow = 'u';
              }
            }
          }
        }
        onValueChanged.apply(this, arguments);
        event.emit();
      };
    }
  }

  onContentReady(e) {
    if (this.savingData) {
      this.dataGrid.instance.deselectAll();
      this.savingData = false;
    }
  }

  public addEmptyRow() {
    this.dataGrid.instance.addRow();
  }

  setTooltipTarget(targetId = null, text = null, length = null) {
    if (length && text && text.length <= length) return;
    this.TooltipTarget = targetId ? '#' + targetId : null;
    this.ToolTipText = text;
  }

  repait() {
    this.dataGrid.instance.refresh();
  }

  public removeRows(ids) {
    this.dataSource = this.dataSource.filter(x => !ids.includes(x[this.dictionary.primaryKey]));
    this.dataGrid.instance.getDataSource().reload();
  }


  dropDownRowClick(e, d) {
    e.focusedKey = d.data.id
    this.dropDownSubobjectVersion.instance.close();
    this.columnDropDowRowClick.emit(e);
  }

  holderChanged(e, d) {
    console.log(e, d)
    //this.selectBoxSubobjectHolder.instance.close();
    this.clickedColumnButton = true;
    this.buttonIconClick.emit({id: 'holder', data: e.value, rowIndex: d.rowIndex});
  }

  getIconFile(data) {
    if (data.extension) {
      let extension = data.extension.toLowerCase().replace('.', '');
      if (['doc', 'docx', 'rtf'].includes(extension)) return faFileWord;
      else if (['xls', 'xlsx', 'csv'].includes(extension)) return faFileExcel;
      else if (['pdf'].includes(extension)) return faFilePdf;
      else if (['xml', 'html'].includes(extension)) return faFileCode;
      else if (['png', 'jpeg', 'jpg', 'tiff', 'odg'].includes(extension)) return faFileImage;
      else if (['zip', '7z', 'rar'].includes(extension)) return faFileArchive;
      else if (['sig', 'sgn'].includes(extension)) return faFileSignature;
      else if (['eml'].includes(extension)) return faEnvelopeOpenText;
      else if (['dwg'].includes(extension)) return faFileInvoice;
      else return faFile;
    } else return faLink;
  }

  getIconFileClass(data) {
    if (data.extension) {
      let extension = data.extension.toLowerCase().replace('.', '');
      if (['doc', 'docx', 'rtf'].includes(extension)) return 'font-blue';
      else if (['xls', 'xlsx', 'csv'].includes(extension)) return 'font-green';
      else if (['pdf'].includes(extension)) return 'font-red';
      else if (['xml', 'html'].includes(extension)) return 'font-purple';
      else if (['png', 'jpeg', 'jpg', 'tiff', 'odg'].includes(extension)) return 'font-orange';
      else if (['zip', '7z', 'rar'].includes(extension)) return 'font-black';
      else if (['sig', 'sgn'].includes(extension)) return 'font-yellow';
      else if (['eml'].includes(extension)) return 'font-brown';
      else if (['dwg'].includes(extension)) return 'font-orange';
      else return 'font-grey';
    } else return 'font-black';
  }

  openDocumentById(id, typeId) {
    let url = '';
    switch (typeId) {
      case -15:
      case -34:
        url = '/#/estimate/card/';
        break; // ЛС
      case -14:
        url = '/#/estimate/object/';
        break; // ОС
      case -13:
        url = '/#/estimate/consolidated/';
        break; // ССР
      case -22:
      case -36:
      case -37:
        url = '/#/estimate/project/';
        break; // ПИР, Прочее
      case -10:
        url = '/#/estimate/kstwo/grid/';
        break; // КС-2
    }
    window.open(
      window.location.origin + url + id,
      '_blank'
    );
  }

  changeTypeLinkDocument(e) {
    this.dictionary.queryParam = [['typeLink', e]];
    const column = this.dictionary.columns.find(x => x.dataField === 'object');
    if (column) {
      const objStr = 'Объект/Подобъект';
      const titStr = 'ИП/Элемент ИП';
      switch (e) {
        case 'all': column.caption = `${objStr} или ${titStr}`; break;
        case 'title': column.caption = titStr; break;
        case 'object': column.caption = objStr; break;
      }
    }
    this.dataSource.reload();
  }

  changeFilteringMainDocument(e) {
    if (e) {
      if (!this.dictionary.queryParam) {
        this.dictionary.queryParam = [];
      }
      this.dictionary.queryParam.push(['mainDocumentId', this.mainDocumentId]);
    } else {
      this.dictionary.queryParam = this.dictionary.queryParam.filter(x => x[0] !== 'mainDocumentId');
    }
    this.dataSource.reload();
  }

}
