import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DocInfo} from '../models/document.model';

const api = '/api';

@Injectable()
export class DocumentService {
  constructor(private http: HttpClient) {
  }

  public uploadFile(b64: string, fileName: string) {
    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, {type: contentType});
    };

    const blob = b64toBlob(b64);

    const formData = new FormData();
    formData.append('file', blob, fileName);
    return this.http.post<any>(`${api}/file/upload`, formData, {responseType: 'text' as 'json'});
  }

  public getDocument(id: number): Observable<DocInfo> {
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.get<any>(`${api}/document`, {params});
  }

  public getDocuments(objectId?: number, formName?: string, options?, documentId?): Observable<any> {
    let httpParams = new HttpParams();
    if (options) {
      httpParams = this.setHttpParams(httpParams, options);
    }
    if (objectId) {
      httpParams = httpParams.set('objectId', String(objectId));
    }
    if (formName) {
      httpParams = httpParams.set('form', formName);
    }
    if (documentId) {
      httpParams = httpParams.set('documentId', documentId);
    }
    if (objectId) {
      return this.http.get<any>(`${api}/document/list?common=false`, {params: httpParams});
    } else {
      return this.http.get<any>(`${api}/document/list`, {params: httpParams});
    }
  }

  public approveDocument(id: any) {
    return this.http.post<any>(`${api}/document/approve`, {id});
  }

  public cancelDocument(id: any) {
    return this.http.post<any>(`${api}/document/cancel`, {id});
  }

  public check(typeId: number, year: number, objectIds: number[]): Observable<any> {
    let params = new HttpParams()
      .set('typeId', String(typeId))
      .set('year', String(year));
    objectIds.forEach(x => params = params.append('objectId', String(x)));
    return this.http.get<any>(`${api}/document/check`, {params});
  }

  public async createArchivePackage(addAgrId): Promise<any> {
    return await this.http.post<any>('api/document/archive/create', {addAgrId}).toPromise();
  }

  public version(id: number, objectIds?: Array<number>, checkLinks = false) {
    let params = new HttpParams()
      .set('id', String(id));
    for (const objectId of objectIds) {
      params = params.append('objectId', objectId.toString());
    }
    if (checkLinks) params = params.append('checkLinks', 'true');
    return this.http.get<any>(`${api}/document/version`, {params});
  }

  public saveDocument(doc, id) {
    if (id) {
      return this.http.put<any>(`${api}/document`, doc);
    } else {
      return this.http.post<any>(`${api}/document`, doc);
    }
  }

  public async postDocument(doc): Promise<any> {
    return await this.http.post<any>(`${api}/document`, doc).toPromise();
  }

  public getHierarchy(id) {
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.get<any>(`api/document/hierarchy`, {params});
  }

  public getFields(id, contractId) {
    return this.http.get<any>(`api/document/type/fields`, {params: {id, contractId}});
  }

  public async getTypeInfo(id): Promise<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', id);
    httpParams = httpParams.append('short', 'true');
    return await this.http.get<any>(`/api/document/type`, {params: httpParams}).toPromise();
  }

  public async addReport(id, name, extension): Promise<any> {
    let params = new HttpParams()
      .set('id', String(id));
    params = params.set('name', String(name));
    params = params.set('extension', String(extension));
    return await this.http.post<any>(`${api}/document/add-report`, {}, {params}).toPromise();
  }

  public async addReportAsAttachment(reportId, documentId, name, extension): Promise<any> {
    let params = new HttpParams()
      .set('reportId', String(reportId));
    params = params.set('documentId', String(documentId));
    params = params.set('name', String(name));
    params = params.set('extension', String(extension));
    return await this.http.post<any>(`${api}/document/add-report-as-attachment`, {}, {params}).toPromise();
  }

  public async getLastId(id: number): Promise<any> {
    const params = new HttpParams()
      .set('id', String(id));
    return await this.http.get<any>(`${api}/document/last`, {params}).toPromise();
  }

  public deleteDocument(id: number): Observable<any> {
    const params = new HttpParams().set('id', String(id));
    return this.http.delete(`${api}/document`, {params});
  }

  public deleteDocuments(ids: any[]) {
    let params = new HttpParams();
    ids.forEach(x => params = params.append('id', x));
    return this.http.delete(`${api}/document`, {params, responseType: 'text' as 'json'});
  }

  public updateDocument(id: number): Observable<any> {
    const params = new HttpParams().set('id', String(id));
    return this.http.put(`${api}/document`, {params});
  }

  public getDocumentsTree(objectId, formName, xForms, parentIds?, filter?: string, sort?: any, hideCommon?, needTree?, dataField?, withoutView?, typeLink = 'object'): Observable<any> {
    let httpParams = new HttpParams();
    if (objectId && typeLink && typeLink.split('-')[0] === 'object') {
      httpParams = httpParams.set('objectId', String(objectId));
    } else if (objectId && typeLink && typeLink.split('-')[0] === 'title') {
      httpParams = httpParams.set('titleId', String(objectId));
    }
    if (formName) {
      httpParams = httpParams.set('form', formName);
    }
    if (xForms) {
      xForms.forEach(x => httpParams = httpParams.append('xForm', x));
    }
    if (parentIds) {
      parentIds.forEach(x => httpParams = httpParams.append('parent', x));
    }
    httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    if (sort) {
      httpParams = httpParams.append('sort', JSON.stringify(sort));
    }
    if (hideCommon) {
      httpParams = httpParams.set('hideCommon', hideCommon);
    }
    if (needTree) {
      httpParams = httpParams.set('needTree', needTree);
    }
    if (dataField) {
      httpParams = httpParams.set('dataField', dataField);
    }
    if (withoutView) {
      httpParams = httpParams.set('withoutView', withoutView);
    }
    if (typeLink) {
      httpParams = httpParams.set('typeLink', typeLink);
    }
    return this.http.get<Array<any>>(`${api}/document/list?common=false`, {params: httpParams});
  }

  public async getChildDocuments(parentId: number): Promise<any> {
    let params = new HttpParams().set('parent', String(parentId));
    params = params.set('hideCommon', "true");
    params = params.set('getChildren', "true");
    return await this.http.get<any>(`${api}/document/list`, {params}).toPromise();
  }

  public async getDocumentStatement(documentId: number): Promise<any> {
    const params = new HttpParams()
      .set('id', String(documentId));
    return await this.http.get<any>(`${api}/document/statement/list`, {params}).toPromise();
  }

  public updateDocumentStatement(body): Promise<any> {
    return this.http.put<any>(`${api}/document/statement`, body).toPromise();
  }

  public async getFinishPosition(documentId: number): Promise<any> {
    const params = new HttpParams()
      .set('id', String(documentId));
    return await this.http.get<any>(`${api}/estimate/finish/position`, {params}).toPromise();
  }

  public async getFinishPositionMo(documentId: number): Promise<any> {
    const params = new HttpParams()
      .set('id', String(documentId));
    return await this.http.get<any>(`${api}/estimate/finish/position/mo`, {params}).toPromise();
  }

  public async getFinishPositionTr(documentId: number): Promise<any> {
    const params = new HttpParams()
      .set('id', String(documentId));
    return await this.http.get<any>(`${api}/estimate/finish/position/tr`, {params}).toPromise();
  }

  public async getFinishLine(id1): Promise<any> {
    const params = new HttpParams()
      .set('id1', String(id1));
    return await this.http.get<any>(`${api}/document/finish-line/list`, {params}).toPromise();
  }

  public async getFinishLinePositions(id1, id2): Promise<any> {
    let params = new HttpParams()
      .set('id1', String(id1));
    params = params.set('id2', String(id2));
    return await this.http.get<any>(`${api}/document/finish-line-positions/list`, {params}).toPromise();
  }

  public async getFinishLineResources(id1, id2): Promise<any> {
    let params = new HttpParams()
      .set('id1', String(id1));
    params = params.set('id2', String(id2));
    return await this.http.get<any>(`${api}/document/finish-line-resources/list`, {params}).toPromise();
  }

  public deleteFinishLine(id1, idFrom, idTo) {
    let params = new HttpParams()
      .set('id1', String(id1));
    params = params.set('idFrom', String(idFrom));
    params = params.set('idTo', String(idTo));
    return this.http.delete<any>(`${api}/document/finish-line`, {params});
  }

  public deleteFinishLinePositions(id1, id2, idFrom, idTo) {
    let params = new HttpParams()
      .set('id1', String(id1));
    params = params.set('id2', String(id2));
    params = params.set('idFrom', String(idFrom));
    params = params.set('idTo', String(idTo));
    return this.http.delete<any>(`${api}/document/finish-line-positions`, {params});
  }

  public deleteFinishLineResources(id1, id2, idFrom, idTo) {
    let params = new HttpParams()
      .set('id1', String(id1));
    params = params.set('id2', String(id2));
    params = params.set('idFrom', String(idFrom));
    params = params.set('idTo', String(idTo));
    return this.http.delete<any>(`${api}/document/finish-line-resources`, {params});
  }

  public postFinishLine(id1, idFrom, idTo) {
    let params = new HttpParams()
      .set('id1', String(id1));
    params = params.set('idFrom', String(idFrom));
    params = params.set('idTo', String(idTo));
    return this.http.post<any>(`${api}/document/finish-line`, {}, {params});
  }

  public postFinishLinePositions(id1, id2, idFrom, idTo, prReq) {
    let params = new HttpParams()
      .set('id1', String(id1));
    params = params.set('id2', String(id2));
    params = params.set('idFrom', String(idFrom));
    params = params.set('idTo', String(idTo));
    if (prReq) {
      params = params.set('prReq', String(prReq));
    }
    return this.http.post<any>(`${api}/document/finish-line-positions`, {}, {params});
  }

  public postFinishLineResources(id1, id2, idFrom, idTo, prReq) {
    let params = new HttpParams()
      .set('id1', String(id1));
    params = params.set('id2', String(id2));
    params = params.set('idFrom', String(idFrom));
    params = params.set('idTo', String(idTo));
    if (prReq) {
      params = params.set('prReq', String(prReq));
    }
    return this.http.post<any>(`${api}/document/finish-line-resources`, {}, {params});
  }

  public getReplacementsActRow(id: number, param?): Observable<any> {
    const params = param.set('id', String(id));
    return this.http.get(`${api}/document/replacement-act`, {params});
  }

  public addReplacementActRow(id: number, body) {
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.post<any>(`${api}/document/replacement-act`, body, {params});
  }

  public deleteReplacementActRow(id) {
    let params = new HttpParams();
    id.forEach(i => {
      params = params.append('id', i);
    });
    return this.http.delete<any>(`${api}/document/replacement-act`, {params});
  }

  public updateReplacementActRow(body) {
    return this.http.put(`${api}/document/replacement-act`, body);
  }

  getLinkDocs(id, options): any {
    let httpParams = new HttpParams();
    httpParams = this.setHttpParams(httpParams, options);
    httpParams = httpParams.append('id', id);
    return this.http.get<Array<any>>(`${api}/document/links`, {params: httpParams});
  }

  setHttpParams(params, queryParams) {
    if (queryParams.take) {
      params = params.append('take', queryParams.take.toString());
    }
    if (queryParams.skip) {
      params = params.append('skip', queryParams.skip.toString());
    }
    if (queryParams.filter) {
      params = params.append('filter', JSON.stringify(queryParams.filter));
    }
    if (queryParams.sort) {
      params = params.append('sort', JSON.stringify(queryParams.sort));
    }
    if (queryParams.group) {
      params = params.set('group', JSON.stringify(queryParams.group));
    }
    return params;
  }

  public async getInfoObjectLinkLocal(documentId): Promise<any> {
    const params = new HttpParams().set('id', documentId);
    return await this.http.get<any>(`${api}/estimate/object/link`, {params}).toPromise();
  }

  public async getInfoConsolidatedLinkLocal(documentId): Promise<any> {
    const params = new HttpParams().set('id', documentId);
    return await this.http.get<any>(`${api}/estimate/consolidated/link`, {params}).toPromise();
  }

  public deleteObjectLinkLocal(positionId, estimateId) {
    let params = new HttpParams().set('positionId', positionId);
    params = params.set('estimateId', estimateId);
    return this.http.delete<any>(`${api}/estimate/object/link`, {params});
  }

  public deleteConsolidatedLinkLocal(positionId, estimateId) {
    let params = new HttpParams().set('positionId', positionId);
    params = params.set('estimateId', estimateId);
    return this.http.delete<any>(`${api}/estimate/consolidated/link`, {params});
  }

  public postObjectLinkLocal(positionId, estimateId) {
    return this.http.post<any>(`${api}/estimate/object/link`, {positionId, estimateId});
  }

  public postConsolidatedLinkDoc(positionId, estimateId) {
    return this.http.post<any>(`${api}/estimate/consolidated/link`, {positionId, estimateId});
  }

  public getExtraFields() {
    return this.http.get<any>(`${api}/document/extra-fields/list`);
  }

  public deleteExtraField(id) {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.delete(`${api}/document/extra-fields`, {params});
  }

  public updateExtraField(id, body) {
    let params = new HttpParams();
    params = params.append('id', String(id));
    return this.http.put(`${api}/document/extra-fields`, body, {params});
  }

  public postExtraField(body) {
    return this.http.post(`${api}/document/extra-fields`, body);
  }

  public getDataTypes() {
    return this.http.get<any>(`${api}/document/data-types`);
  }

  public getDictionaries(isActual?) {
    let params = new HttpParams();
    if (isActual) {
      params = params.append('isActual', isActual);
    }
    return this.http.get<any>(`${api}/document/dictionaries`, {params});
  }

  public async convertString(str: string): Promise<any> {
    const params = new HttpParams().set('str', str);
    return await this.http.get<any>(`${api}/document/convert-string`, {params}).toPromise();
  }

  public async getUploadFileById(id: number): Promise<any> {
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.get<any>(`${api}/file/upload`, {params}).toPromise();
  }

  public async checkValidationDocumentDate(value) {
    return await this.http.post<any>(`${api}/document/check-document-date`, value).toPromise();
  }

  public async createFile(body) {
    return await this.http.post<any>(`${api}/document/create/file`, body).toPromise();
  }

  public async createFast(tmpId, typeId): Promise<any> {
    return await this.http.post<any>(`${api}/document/fast/create`, {tmpId, typeId}).toPromise();
  }

  public getUploadHistory(options) {
    let httpParams = new HttpParams();
    httpParams = this.setHttpParams(httpParams, options);
    return this.http.get<any>(`${api}/document/upload/history`, {params: httpParams});
  }

  getDocumentVersions(id) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', id);
    return this.http.get<Array<any>>(`/api/document/versions`, {params: httpParams});
  }

  getContractDefault(id) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', id);
    return this.http.get<any>(`/api/contract/default`, {params: httpParams}).toPromise();
  }

  getDocumentBody(projectNum, contractId): Observable<any> {
    let httpParams = new HttpParams();
    if (projectNum)
      httpParams = httpParams.append('projectNum', projectNum);
    if (contractId)
      httpParams = httpParams.append('contractId', contractId);
    return this.http.get<Array<any>>(`api/document/object-contract-id`, {params: httpParams});
  }

  getTemplate(baseVersionId, replacements): Observable<any> {
    let httpParams = new HttpParams();
    if (baseVersionId)
      httpParams = httpParams.append('baseVersionId', baseVersionId);
    if (replacements)
      httpParams = httpParams.append('replacements', JSON.stringify(replacements));
    return this.http.get<Array<any>>(`api/document/template`, {params: httpParams});
  }

  public getDocumentTypes(form): Observable<any> {
    let params = new HttpParams();
    params = params.append('form', form)
    params = params.append('system', 'true')
    return this.http.get<any>(`${api}/document/type/list`, {params});
  }

  public getDocumentStatuses(): Observable<any> {
    return this.http.get<any>(`${api}/document/status/list`);
  }


  public getMonitoringAct(options) {
    let params = new HttpParams();
    ['filialIds', 'typeIds', 'statusIds'].forEach(key =>
      options[key] && options[key].length && options[key].forEach(x => params = params.append(key, x))
    )
    params = params.set('periodStart', options.periodStart.toLocaleDateString());
    params = params.set('periodEnd', options.periodEnd.toLocaleDateString());
    return this.http.get<any>(`${api}/report/monitoring-act`, {params});
  }

  getAdditionalAgreementsTypes(key, queryParams, choice): Observable<any> {
    let httpParams = new HttpParams();
    if (queryParams)
      httpParams = this.setHttpParams(httpParams, queryParams);
    else httpParams = httpParams.set('id', key);
    if (choice)
      httpParams = httpParams.set('choice', 'true');
    return this.http.get<Array<any>>(`api/document/additional-agreements/types`, {params: httpParams});
  }

  additionalAgreementsTypes(type, key, values) {
    let httpParams = new HttpParams();
    switch (type) {
      case 'post':
        return this.http.post<any>(`api/document/additional-agreements/types`, values);
      case 'put':
        httpParams = httpParams.append('id', key);
        return this.http.put<any>(`api/document/additional-agreements/types`, values, {params: httpParams});
      case 'delete':
        httpParams = httpParams.append('id', key);
        return this.http.delete(`api/document/additional-agreements/types`, {
          params: httpParams,
          responseType: 'text' as 'json'
        });
    }
  }

  getAdditionalAgreementsList(addTypeId, parentIds?, filter?, sort?: string, choice?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('addTypeId', addTypeId);
    if (parentIds) {
      parentIds.forEach(x => httpParams = httpParams.append('parent', x));
    }
    if (choice)
      httpParams = httpParams.set('choice', 'true');
    httpParams = httpParams.append('filter', filter ? JSON.stringify(filter) : '[]');
    if (sort) httpParams = httpParams.append('sort', JSON.stringify(sort));
    return this.http.get<Array<any>>(`api/document/additional-agreements/list`, {params: httpParams});
  }

  additionalAgreementsList(type, key, values) {
    let httpParams = new HttpParams();
    switch (type) {
      case 'post':
        return this.http.post<any>(`api/document/additional-agreements/list`, values);
      case 'put':
        httpParams = httpParams.append('id', key);
        return this.http.put<any>(`api/document/additional-agreements/list`, values, {params: httpParams});
      case 'delete':
        httpParams = httpParams.append('id', key);
        return this.http.delete(`api/document/additional-agreements/list`, {
          params: httpParams,
          responseType: 'text' as 'json'
        });
    }
  }

  getAdditionalAgreementsListFileHistory(id): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('id', id);
    return this.http.get<Array<any>>(`api/document/additional-agreements/file/history`, {params: httpParams});
  }

  public async postNewVersionFileAdditional(body): Promise<any> {
    return await this.http.post<any>(`api/document/additional-agreements/upload/file`, body).toPromise();
  }

  getFileVariables(id): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('id', id);
    return this.http.get<Array<any>>(`api/document/additional-agreements/file/variables`, {params: httpParams});
  }

  getVariables(): Observable<any> {
    return this.http.get<Array<any>>(`api/document/variables`);
  }

  variables(type, key, values) {
    let httpParams = new HttpParams();
    switch (type) {
      case 'post':
        return this.http.post<any>(`api/document/variables`, values);
      case 'put':
        httpParams = httpParams.append('name', key);
        return this.http.put<any>(`api/document/variables`, values, {params: httpParams});
      case 'delete':
        httpParams = httpParams.append('name', key);
        return this.http.delete(`api/document/variables`, {params: httpParams, responseType: 'text' as 'json'});
    }
  }

  public createNewFileVersion(blob, fileName, documentId) {
    let params = new HttpParams();
    params = params.append('documentId', documentId);
    const formData = new FormData();
    formData.append('file', blob, fileName);
    return this.http.post<any>(`${api}/document/main/file`, formData, {params});
  }

  public async getDocumentDate(typeId, ids, additional = null): Promise<any> {
    let params = new HttpParams();
    params = params.append('typeId', typeId);
    if (ids) ids.forEach(x => params = params.append('subobjectId', x));
    if (additional) {
      Object.keys(additional).forEach(key => params = params.append(key, additional[key]));
    }
    return await this.http.get<any>(`/api/document/document-date`, {params}).toPromise();
  }

  public getIsAnyFactCharacteristics(ids) {
    let params = new HttpParams();
    if (Array.isArray(ids)) {
      ids.forEach(x => params = params.append('id', x));
    } else {
      params = params.append('id', ids);
    }
    return this.http.get<any>(`/api/document/fact-characteristics`, {params});
  }
}
