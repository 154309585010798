import {gridDictionary} from '../common-element/list-generator/generator-interfaces';
import {environment} from '../../../environments/environment';

export class Dictionaries {
  public static dictionaries: gridDictionary[] = [
    {
      id: 'groupDocs',
      show: true,
      name: 'Группы документов',
      get: 'api/document/group/list',
      post: 'api/document/group',
      put: 'api/document/group',
      delete: 'api/document/group',
      primaryKey: 'id',
      fieldDelete: 'name',
      remoteOperations: true,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      group: 'documents',
      editRole: 'informationdocuments',
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      columnAutoWidth: false,
      columns: [{
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string',
        width: 300
      }, {
        caption: 'Папка Docsvision',
        dataField: 'docsvisionFolder',
        dataType: 'string',
        width: 300
      }]
    },
    {
      id: 'typeDocs',
      show: true,
      name: 'Типы документов',
      get: 'api/document/type/list',
      delete: 'api/document/type',
      primaryKey: 'id',
      allowAdd: true,
      allowDelete: true,
      group: 'documents',
      editRole: 'informationdocuments',
      fastDelete: true,
      fieldDelete: 'name',
      hideRowStatus: true,
      remoteOperations: true,
      allowCopy: true,
      allowRefresh: true,
      showCountRow: true,
      wordWrap: true,
      queryParam: [['system', true]],
      columns: [{
        caption: 'Группа документа',
        dataField: 'group',
        dataType: 'string',
        width: 200
      },
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string',
          width: 200
        }, {
          caption: 'Полное Наименование',
          dataField: 'fullName',
          dataType: 'string',
          width: 200
        },
        {
          caption: 'Общий',
          dataField: 'isCommon',
          dataType: 'boolean',
          width: 120
        },
        {
          caption: 'Авт. нумерация',
          dataField: 'isAutoNumbered',
          dataType: 'boolean',
          width: 120
        },
        {
          caption: 'Согласование',
          dataField: 'isApprovalRequired',
          dataType: 'boolean',
          width: 120
        }, {
          caption: 'ЭЦП',
          dataField: 'signing',
          dataType: 'boolean',
          width: 120
        },
        {
          caption: 'Роли',
          dataField: 'roles',
          dataType: 'string',
          defaultHeaderFilter: true,
          defaultFilterExpression: true
        }]
    },
    {
      id: 'choiceTypeDocs',
      name: 'Типы документов',
      fileNameExport: 'Типы документов',
      primaryKey: 'id',
      remoteOperations: true,
      allowChoice: true,
      hideRowStatus: true,
      get: 'api/document/type/list?system=true',
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        }]
    },
    {
      id: 'choiceTypeDocsLk',
      name: 'Типы документов',
      fileNameExport: 'Типы документов',
      primaryKey: 'id',
      remoteOperations: true,
      allowChoice: true,
      hideRowStatus: true,
      get: 'api/document/type/list?system=true&isCommon=false',
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        }]
    },
    {
      id: 'choiceOnClickContract',
      name: 'Договоры',
      fileNameExport: 'Договоры',
      primaryKey: 'id',
      remoteOperations: true,
      choiceOnClick: true,
      hideRowStatus: true,
      get: 'api/contract/list',
      queryParam: [['objectIds', -1]],
      columns: [
        {
          caption: '№ договора',
          dataField: 'numContract',
          dataType: 'string',
          width: 150
        },
        {
          caption: 'Дата утверждения договора',
          dataField: 'dateApprove',
          dataType: 'date',
          width: 120
        },
        {
          caption: ' Контрагент',
          dataField: 'contragentShortName',
          dataType: 'string',
          width: undefined,
          cellTemplate: 'longTextTemplate'
        },
        {
          caption: ' Коды объектов КС',
          dataField: 'objectCodes',
          dataType: 'string',
          width: undefined,
          cellTemplate: 'longTextTemplate'
        },
        {
          caption: 'Филиал',
          dataField: 'filialId',
          dataType: 'string',
          width: 150
        }]
    },
    {
      id: 'choiceOnClickAllTypeDocs',
      name: 'Типы документов',
      fileNameExport: 'Типы документов',
      primaryKey: 'id',
      allowChoice: true,
      remoteOperations: true,
      hideRowStatus: true,
      get: 'api/document/type/list?system=true',
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string',
          sort: 'asc'
        },
        {
          caption: 'Признак общего документа',
          dataField: 'isCommon',
          dataType: 'boolean'
        },
        {
          caption: 'Обязательность согласования',
          dataField: 'isApprovalRequired',
          dataType: 'boolean'
        },
        {
          caption: 'Признак "Договорной"',
          dataField: 'isContract',
          dataType: 'boolean'
        }]
    },
    {
      id: 'choiceOnClickTypeDocs',
      name: 'Типы документов',
      fileNameExport: 'Типы документов',
      primaryKey: 'id',
      remoteOperations: true,
      choiceOnClick: true,
      hideRowStatus: true,
      focusedRowEnabled: true,
      wordWrap: true,
      get: 'api/document/type/list?create=true&system=true',
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string',
          sort: 'asc',
          width: '80%'
        },
        {
          caption: 'Признак общего документа',
          dataField: 'isCommon',
          dataType: 'boolean',
          width: 120
        },
        {
          caption: 'Обязательность согласования',
          dataField: 'isApprovalRequired',
          dataType: 'boolean',
          width: 120
        },
        {
          caption: 'Признак "Договорной"',
          dataField: 'isContract',
          dataType: 'boolean',
          width: 120
        }]
    },
    {
      id: 'choiceStou',
      showCaption: true,
      name: 'Необходимо выбрать код СТОУ для редактирования, либо создания новой строки',
      fileNameExport: 'СТОУ',
      primaryKey: 'id',
      remoteOperations: true,
      choiceOnClick: true,
      hideRowStatus: true,
      focusedRowEnabled: true,
      get: 'api/object/sub/stou/list',
      columns: [
        {
          caption: 'Код СТОУ',
          dataField: 'code',
          dataType: 'string',
          width: 150,
          sort: 'asc'
        }, {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string',
          width: 400,
          sort: 'asc'
        }, {
          caption: 'Код ОКОФ',
          dataField: 'okofCode',
          dataType: 'string',
          width: 150
        }, {
          caption: 'ОКОФ',
          dataField: 'okofName',
          dataType: 'string',
          width: 200
        }, {
          caption: 'Код ОКОФ по ОК 013-1994',
          dataField: 'okof013_1994Code',
          dataType: 'string',
          width: 150
        }, {
          caption: 'ОКОФ по ОК 013-1994',
          dataField: 'okof013_1994Name',
          dataType: 'string',
          width: 200
        }, {
          caption: 'Код единицы измерения',
          dataField: 'unitCode',
          dataType: 'string',
          width: 150
        }, {
          caption: 'Единица измерения',
          dataField: 'unit',
          dataType: 'string',
          width: 200
        }]
    },
    {
      id: 'choiceSubobjectType',
      showCaption: true,
      name: 'Необходимо выбрать тип подобъекта для редактирования, либо создания новой строки',
      fileNameExport: 'Тип подобъекта',
      primaryKey: 'id',
      remoteOperations: true,
      choiceOnClick: true,
      hideRowStatus: true,
      focusedRowEnabled: true,
      get: 'api/object/sub/type/list',
      columns: [
        {
          caption: 'Тип',
          dataField: 'name',
          dataType: 'string',
          width: 200,
          sort: 'asc'
        }]
    },
    {
      id: 'choiceInvestType',
      showCaption: true,
      name: 'Необходимо выбрать тип элемента ИП для редактирования, либо создания новой строки',
      fileNameExport: 'Тип элемента ИП',
      primaryKey: 'id',
      remoteOperations: true,
      choiceOnClick: true,
      hideRowStatus: true,
      focusedRowEnabled: true,
      get: 'api/object/sub/type/list',
      columns: [
        {
          caption: 'Тип',
          dataField: 'name',
          dataType: 'string',
          width: 200,
          sort: 'asc'
        }]
    },
    {
      id: 'choiceInventoryNumber',
      showCaption: true,
      name: 'Необходимо выбрать значение из справочника "Основные средства"',
      fileNameExport: 'Значения из справочника "Основные средства"',
      primaryKey: 'id',
      remoteOperations: true,
      choiceOnClick: true,
      hideRowStatus: true,
      focusedRowEnabled: true,
      columnAutoWidth: true,
      get: 'api/character/assets/list',
      queryParam: [['filialId', -1]],
      showCountRow: true,
      defaultFilter: {
        width: '200px',
        alligmentRight: true,
        dataSource: {
          defaultValue: {allFilials: false},
          values: {allFilials: false},
          filterText: ('Филиал объекта'),
          setFilterText: (data) => {
            let text = '';
            text += (!data.allFilials ? 'Филиал объекта ' : 'Все филиалы');
            return text;
          }
        },
        items: [
          {
            dataField: 'allFilials',
            editorType: 'dxCheckBox',
            label: ('Показать все')
          }
        ]
      },
      defaultFilterValue: {
        getFilterValue: (data) => {
          const notFilter = [];
          if (data) {
            if (data.allFilials) {
              notFilter.push({name: 'allFilials', value: true});
            }
          }
          return notFilter;
        }
      },
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string',
          cellTemplate: 'longTextTemplate',
          width: 300
        },
        {
          caption: 'Инвентарный номер',
          dataField: 'inventoryNumber',
          dataType: 'string'
        },
        {
          caption: 'Код СТОУ',
          dataField: 'stouName',
          dataType: 'string'
        },
        {
          caption: 'Аналитика',
          dataField: 'groupFund',
          dataType: 'string'
        },
        {
          caption: 'Филиал',
          dataField: 'branchId',
          width: 100,
          dataType: 'string',
          showClearButton: true,
          dictionary: {
            get: 'api/organization/departments?filter=%5B%22typeId%22,%22=%22,2%5D',
            dataSource: [],
            name: 'shortName',
            id: 'id',
          }
        },
        {
          caption: 'Начало действия',
          dataField: 'startDate',
          dataType: 'date'
        },
        {
          caption: 'Окончание действия',
          dataField: 'terminationDate',
          dataType: 'date'
        }
      ]
    },
    {
      id: 'choiceCharacter',
      name: 'Характеристика',
      fileNameExport: 'Характеристика',
      primaryKey: 'id',
      choiceOnClick: true,
      hideRowStatus: true,
      columns: [
        {
          caption: 'Наименование',
          dataField: 'value',
          dataType: 'string',
          sort: 'asc'
        }]
    },
    {
      id: 'file-history',
      showCaption: true,
      name: 'История основного файла',
      fileNameExport: 'История основного файла',
      showCountRow: true,
      primaryKey: 'fileId',
      hideSelectedColumn: true,
      hideFilterHead: true,
      hideFilterRow: true,
      hideChooser: true,
      columnAutoWidth: true,
      wordWrap: true,
      columns: [
        {
          caption: 'Версия',
          dataField: 'version',
          dataType: 'string',
          sort: 'asc'
        },
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string',
          buttons: [
            {
              icon: 'download',
              hint: 'Скачать',
              id: 'download',
              //show: data => data.documentId ? 'block' : 'none'
            }
          ]
        }]
    },
    {
      id: 'current-nzs',
      showCaption: true,
      name: 'Текущая НЗС',
      fileNameExport: 'Текущая НЗС',
      post: `/api/object/sub/enter/current-nzs`,
      put: `/api/object/sub/enter/current-nzs`,
      editRole: 'informationobjects',
      remoteOperations: true,
      get: `/api/object/sub/enter/current-nzs`,
      allowAdd: true,
      allowUpdate: true,
      allowDelete: false,
      editMode: 'batch',
      hideBatchButton: true,
      allowSave: true,
      showCountRow: true,
      primaryKey: 'id',
      hideSelectedColumn: true,
      hideChooser: true,
      columnAutoWidth: true,
      wordWrap: true,
      columns: [
        {
          caption: 'Год',
          allowEdit: true,
          dataField: 'nzs_date_year',
          dataType: 'number',
          captionTooltipText: 'Год',
          sort: 'desc'
        },
        {
          caption: 'Месяц',
          allowEdit: true,
          dataField: 'nzs_date_month',
          dataType: 'string',
          captionTooltipText: 'Месяц',
          sort: 'desc'
        },
        {
          caption: 'Сумма НЗС',
          allowEdit: true,
          dataField: 'nzs_sum',
          dataType: 'number',
          captionTooltipText: 'Сумма НЗС'
        }]
    },
    {
      id: 'oks-uks-sum',
      showCaption: true,
      name: 'Затраты ОКС/УКС',
      fileNameExport: 'Затраты ОКС/УКС',
      showCountRow: true,
      primaryKey: 'id',
      hideSelectedColumn: true,
      hideChooser: true,
      columnAutoWidth: true,
      wordWrap: true,
      columns: [
        {
          caption: 'Год',
          allowEdit: false,
          dataField: 'nzs_date_year',
          dataType: 'number',
          captionTooltipText: 'Год',
          sort: 'desc'
        },
        {
          caption: 'Месяц',
          allowEdit: false,
          dataField: 'nzs_date_month',
          dataType: 'string',
          captionTooltipText: 'Месяц',
          sort: 'desc'
        },
        {
          caption: 'Затраты ОКС/УКС',
          allowEdit: false,
          dataField: 'oks_uks_sum',
          dataType: 'number',
          captionTooltipText: 'Затраты ОКС/УКС',
          summaryType: 'sum'
        }]
    },
    {
      id: 'akt-sum',
      showCaption: true,
      name: 'Акты выполненных работ',
      fileNameExport: 'Акты выполненных работ',
      showCountRow: true,
      primaryKey: 'id',
      hideSelectedColumn: true,
      hideChooser: true,
      columnAutoWidth: true,
      wordWrap: true,
      columns: [
        {
          caption: 'Наименование',
          allowEdit: false,
          dataField: 'name',
          dataType: 'string',
          captionTooltipText: 'Наименование'
        },
        {
          caption: '№ договора',
          allowEdit: false,
          dataField: '',
          dataType: 'string',
          captionTooltipText: '№ договора'
        },
        {
          caption: '№ документа',
          allowEdit: false,
          dataField: 'number',
          dataType: 'string',
          captionTooltipText: '№ документа'
        },
        {
          caption: 'Дата документа',
          allowEdit: false,
          dataField: 'document_date',
          dataType: 'date',
          captionTooltipText: 'Дата документа',
          sort: 'desc'
        },
        {
          caption: 'Сумма',
          allowEdit: false,
          dataField: 'cost_total',
          dataType: 'number',
          captionTooltipText: 'Сумма',
          summaryType: 'sum'
        }]
    },
    {
      id: 'choiceOnClickDoc',
      name: 'Документы',
      fileNameExport: 'Документы',
      primaryKey: 'id',
      remoteOperations: true,
      choiceOnClick: true,
      hideRowStatus: true,
      get: 'api/document/list',
      columns: [{
        caption: 'Номер документа',
        dataField: 'number',
        dataType: 'string',
        width: 400
      }, {
        caption: 'Дата документа',
        dataField: 'documentDate',
        dataType: 'date',
        sort: 'desc'
      }, {
        caption: 'Объект/Подобъект',
        dataField: 'object',
        dataType: 'string',
        width: 400
      }, {
        caption: 'Тип документа',
        dataField: 'documentType',
        dataType: 'string'
      }, {
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string',
        width: 400
      }, {
        caption: 'Описание',
        dataField: 'description',
        dataType: 'string'
      }, {
        caption: 'Версия',
        dataField: 'version',
        dataType: 'string'
      }, {
        caption: 'Статус',
        dataField: 'status',
        dataType: 'string'
      }, {
        caption: 'А',
        dataField: 'isActive',
        dataType: 'boolean'
      }, {
        caption: 'Дата создания',
        dataField: 'createdAt',
        dataType: 'datetime'
      }]
    },
    {
      id: 'choiceOnClickStage',
      name: 'Этапы',
      fileNameExport: 'Этапы',
      primaryKey: 'id',
      queryParam: [['contractId', -1], ['objectId', -1]],
      remoteOperations: true,
      choiceOnClick: true,
      hideRowStatus: true,
      get: 'api/contract/stage/list',
      columns: [{
        caption: 'Статья затрат',
        dataField: 'costItemBdds',
        dataType: 'string'
      }, {
        caption: 'Статья бюджета',
        dataField: 'expenseName',
        dataType: 'string'
      }, {
        caption: 'Номер этапа',
        allowEdit: false,
        dataField: 'stageNum',
        dataType: 'string'
      }, {
        caption: 'Номер лицевого счёта (Лицевой счет этапа)',
        allowEdit: false,
        dataField: 'personalAccountNum',
        dataType: 'string'
      }, {
        caption: 'Номер доп.соглашения',
        allowEdit: false,
        dataField: 'numberAddcount',
        dataType: 'string'
      }, {
        caption: 'Дата доп.соглашения',
        allowEdit: false,
        dataField: 'dateAddcount',
        dataType: 'date'
      }]
    },
    {
      id: 'choiceOnClickPoint',
      name: 'Точки графика',
      fileNameExport: 'Точки графика',
      primaryKey: 'id',
      queryParam: [['stageId', -1]],
      remoteOperations: true,
      choiceOnClick: true,
      hideRowStatus: true,
      get: 'api/contract/stage/point/list',
      columns: [{
        caption: 'Точка графика',
        allowEdit: false,
        dataField: 'point',
        dataType: 'date',
        format: 'yyyy.MM'
      }]
    },
    {
      id: 'typeDocFields',
      editMode: 'cell',
      dropDownButton: [
        {
          hint: 'Добавить дополнительную колонку',
          icon: 'fa fa-plus-circle',
          dropDownWidth: 200,
          items: [
            {value: 1, name: 'Из справочника', icon: 'plus'},
            {value: 2, name: 'Добавить вручную', icon: 'fa fa-plus-circle'}
          ]
        }
      ],
      allowUpdate: true,
      allowDelete: true,
      name: 'Дополнительные поля',
      fileNameExport: 'Дополнительные поля',
      primaryKey: 'id',
      columns: [
        {
          caption: 'Наименование поля',
          dataField: 'name',
          dataType: 'string',
          width: 200,
          required: true,
          sort: 'asc'
        }, {
          caption: 'Копировать в новую версию',
          dataField: 'needCopy',
          dataType: 'boolean',
          width: 80
        },
        {
          caption: 'Строка',
          dataField: 'row',
          dataType: 'number',
          width: 100
        },
        {
          caption: 'Расположение в строке',
          dataField: 'column',
          dataType: 'string',
          width: 200,
          dictionary: {
            dataSource: [
              {id: 'L', name: 'Слева'},
              {id: 'R', name: 'Справа'}
            ],
            name: 'name',
            id: 'id'
          }
        },
        {
          caption: 'Тип данных',
          dataField: 'dataTypeName',
          dataType: 'string',
          required: true,
          dictionary: {
            dataSource: [
              {id: 'boolean', name: 'Галка'},
              {id: 'number', name: 'Число'},
              {id: 'date', name: 'Дата'},
              {id: 'string', name: 'Текст'},
              {id: 'dictionary', name: 'Справочник'},
              {id: 'money', name: 'Деньги'},
              {id: 'dateYear', name: 'Дата (год)'}
            ],
            name: 'name',
            id: 'id'
          }
        },
        {
          caption: 'Справочник',
          dataField: 'dictionaryId',
          dataType: 'number',
          width: 250,
          dictionary: {
            get: 'api/document/dictionaries',
            dataSource: [],
            name: 'name',
            id: 'id'
          }
        },
        {
          caption: 'Обязательность',
          dataField: 'required',
          dataType: 'boolean'
        }
      ]
    },
    {
      id: 'typeDocRoles',
      editMode: 'cell',
      allowAdd: true,
      allowUpdate: true,
      allowDelete: true,
      addRowCustom: true,
      name: 'Роли',
      fileNameExport: 'Роли',
      primaryKey: 'roleId',
      postDataSource: data => {
        data.forEach(x => {
          x.roleStr = x.role.code + ' ' + x.role.name;
        });
      },
      columns: [{
        caption: 'Роль',
        dataField: 'roleStr',
        dataType: 'string',
        allowEdit: false,
        width: 250
      }, {
        caption: 'Чтение',
        dataField: 'reading',
        dataType: 'boolean'
      }, {
        caption: 'Создание',
        dataField: 'creating',
        dataType: 'boolean'
      }, {
        caption: 'Запись',
        dataField: 'writing',
        dataType: 'boolean'
      }, {
        caption: 'Удаление',
        dataField: 'deleting',
        dataType: 'boolean'
      }, {
        caption: 'Все',
        dataField: 'all',
        dataType: 'boolean',
        cancelUpdate: false,
        allowExporting: false,
        onChangeValue: (dataSource, dataRow) => {
          const row = dataSource.find(x => x.roleId === dataRow.key);
          row.reading = row.creating = row.writing = row.deleting =
            row.agreement = Object.values(dataRow.newData)[0];
        }
      }
      ]
    },
    {
      id: 'typeDocInterfaces',
      allowAdd: true,
      allowDelete: true,
      addRowCustom: true,
      name: 'Интерфесы',
      fileNameExport: 'Интерфейсы',
      primaryKey: 'id',
      columns: [{
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string'
      }
      ]
    },
    {
      id: 'listParentType',
      allowAdd: true,
      allowDelete: true,
      addRowCustom: true,
      name: 'Типы родит. док.',
      fileNameExport: 'Типы родит. док.',
      primaryKey: 'id',
      columns: [{
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string'
      }
      ]
    }, {
      id: 'DocumentExtraFields',
      show: true,
      name: 'Дополнительные колонки',
      group: 'documents',
      editRole: 'informationsubobjects',
      primaryKey: 'id'
    },
    {
      id: 'choiceRoles',
      name: 'Роли',
      fileNameExport: 'Роли',
      primaryKey: 'id',
      allowChoice: true,
      hideRowStatus: true,
      remoteOperations: true,
      get: 'api/admin/role/list',
      columns: [
        {
          caption: 'Код',
          dataField: 'code',
          dataType: 'string',
          sort: 'asc'
        }, {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        }
      ]
    },
    {
      id: 'choiceRolesLk',
      name: 'Роли',
      fileNameExport: 'Роли',
      primaryKey: 'id',
      allowChoice: true,
      hideRowStatus: true,
      remoteOperations: true,
      get: 'api/admin/role/listlk',
      columns: [
        {
          caption: 'Код',
          dataField: 'code',
          dataType: 'string',
          sort: 'asc'
        }, {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        }
      ]
    },
    {
      id: 'choiceInterface',
      name: 'Формы',
      fileNameExport: 'Формы',
      primaryKey: 'id',
      allowChoice: true,
      hideRowStatus: true,
      get: 'api/application/forms',
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        }
      ]
    },
    {
      id: 'valueList',
      show: true,
      name: 'Список действий/этапов',
      get: 'api/agreement/value/list',
      post: 'api/agreement/value',
      put: 'api/agreement/value',
      delete: 'api/agreement/value',
      primaryKey: 'id',
      fieldDelete: 'name',
      group: 'documents',
      editRole: 'informationdocuments',
      remoteOperations: true,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      columnAutoWidth: false,
      columns: [{
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string',
        width: 300
      }, {
        caption: 'Этап',
        dataField: 'stage',
        dataType: 'boolean',
        width: 130
      }, {
        caption: 'Действие',
        dataField: 'action',
        dataType: 'boolean',
        width: 130
      },
        {
          caption: '',
          allowEdit: false,
          allowFiltering: false,
          allowSorting: false,
          hideHeaderFiltering: true,
          width: undefined
        }]
    },
    {
      id: 'simple-object-types',
      name: 'Тип объекта',
      fileNameExport: 'Тип объекта',
      editRole: 'informationobjects',
      get: 'api/object/type/list',
      primaryKey: 'id',
      remoteOperations: true,
      showCountRow: true,
      allowRefresh: true,
      hideSelectedColumn: true,
      hideChooser: true,
      focusedRowEnabled: true,
      columns: [
        {
          caption: 'Тип объекта',
          dataField: 'fullName',
          dataType: 'string',
          sort: 'asc'
        }
      ]
    },
    {
      id: 'document-mandatory',
      name: 'Исполнительная документация',
      fileNameExport: 'Исполнительная документация',
      editRole: 'informationdocuments',
      delete: 'api/document/mandatory',
      primaryKey: 'id',
      showCountRow: false,
      editMode: 'cell',
      allowRefresh: true,
      allowAdd: true,
      addRowCustom: true,
      allowUpdate: true,
      allowDelete: true,
      allowSave: true,
      fastDelete: true,
      fieldDelete: 'documentTypeName',
      hideSelectedColumn: false,
      hideChooser: true,
      columns: [
        {
          caption: 'Тип документа',
          dataField: 'documentTypeId',
          dataType: 'number',
          allowEdit: false,
          dictionary: {
            get: 'api/document/type/list?system=true',
            dataSource: [],
            name: 'name',
            id: 'id'
          },
          sort: 'asc'
        },
        {
          caption: 'Признак обязательности',
          dataField: 'isRequired',
          dataType: 'boolean'
        }
      ]
    },
    {
      id: 'Sets',
      show: false,
      name: 'Наборы характеристик',
      group: 'subobjects',
      editRole: 'informationsubobjects',
      get: 'api/character/set/list',
      post: 'api/character/set',
      put: 'api/character/set',
      delete: 'api/character/set',
      focusedRowEnabled: true,
      primaryKey: 'id',
      // rowPrepared: true,
      fieldDelete: 'name',
      remoteOperations: true,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'row',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      columnAutoWidth: false,
      wordWrap: true,
      columns: [{
        caption: 'Набор характеристик',
        dataField: 'name',
        dataType: 'string',
        width: undefined
      },
        {
          caption: 'Устаревший',
          dataField: 'isOld',
          dataType: 'boolean',
          width: 120
        }/*,
        {
          caption: 'Порядок сортировки',
          dataField: 'order_num',
          dataType: 'number',
          width: 300
        }*/]
    },
    {
      id: 'unit',
      show: true,
      name: 'Единицы измерения',
      get: 'api/character/unit/list',
      post: 'api/character/unit',
      put: 'api/character/unit',
      delete: 'api/character/unit',
      primaryKey: 'id',
      fileNameExport: 'Единицы измерения',
      fieldDelete: 'name',
      remoteOperations: true,
      group: 'subobjects',
      editRole: 'informationsubobjects',
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      columnAutoWidth: false,
      columns: [{
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string',
        width: 300
      }, {
        caption: 'Аббревиатура',
        dataField: 'abbr',
        dataType: 'string',
        width: 300
      }, {
        caption: 'Физизческая величина',
        dataField: 'physParamId',
        dataType: 'number',
        width: 300,
        dictionary: {
          get: 'api/character/physparam/list',
          dataSource: [],
          name: 'name',
          id: 'id'
        }
      }, {
        caption: 'Коэффициент округление',
        dataField: 'koefOkr',
        dataType: 'number',
        width: 300
      }, {
        caption: 'ОКЕИ',
        dataField: 'unitCode',
        dataType: 'number',
        width: 300
      }, {
        caption: 'Базовый',
        dataField: 'isBase',
        dataType: 'boolean',
        width: 300
      }]
    },
    {
      id: 'unitConversion',
      show: true,
      group: 'subobjects',
      editRole: 'informationsubobjects',
      name: 'Коэффициенты перевода',
      primaryKey: 'id'
    },
    {
      id: 'phys_param',
      show: true,
      name: 'Физические величины',
      group: 'subobjects',
      editRole: 'informationsubobjects',
      get: 'api/character/physparam/list',
      post: 'api/character/physparam',
      put: 'api/character/physparam',
      delete: 'api/character/physparam',
      primaryKey: 'id',
      remoteOperations: true,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      columnAutoWidth: false,
      columns: [{
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string',
        width: 300
      }]
    },
    {
      id: 'character',
      show: true,
      name: 'Физические характеристики',
      group: 'subobjects',
      editRole: 'informationsubobjects',
      get: 'api/character/character/list',
      post: 'api/character/character',
      put: 'api/character/character',
      delete: 'api/character/character',
      primaryKey: 'id',
      fieldDelete: 'name',
      remoteOperations: true,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      columnAutoWidth: false,
      columns: [{
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string',
        width: 300
      }, {
        caption: 'Единица измерения',
        dataField: 'unitId',
        dataType: 'number',
        width: 300,
        showClearButton: true,
        dictionary: {
          get: 'api/character/unit/list?combo=true',
          dataSource: [],
          name: 'name',
          id: 'id'
        }
      }, {
        caption: 'Справочник',
        dataField: 'dictionaryId',
        dataType: 'number',
        width: 300,
        showClearButton: true,
        dictionary: {
          get: 'api/document/dictionaries',
          dataSource: [],
          name: 'name',
          id: 'id'
        }
      }, {
        caption: 'Характеристика Минэнерго',
        dataField: 'isMinistry',
        dataType: 'boolean',
        width: 250
      }, {
        caption: 'Укрупненная характеристика',
        dataField: 'isAggregated',
        dataType: 'boolean',
        width: 250
      }]
    },
    {
      id: 'main-set-character',
      show: true,
      name: 'Справочник наборов характеристик',
      group: 'subobjects',
      editRole: 'informationsubobjects',
      primaryKey: 'id',
      fieldDelete: 'name'
    },
    {
      id: 'set-character',
      name: 'Справочник характеристик и наборов',
      group: 'subobjects',
      editRole: 'informationsubobjects',
      get: 'api/character/set-character-unit/list',
      post: 'api/character/set-character',
      put: 'api/character/set-character',
      delete: 'api/character/set-character',
      primaryKey: 'id',
      fieldDelete: 'nameCh',
      remoteOperations: true,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      columnAutoWidth: false,
      queryParam: [['setId', -1]],
      columns: [
        /*{
          caption: 'Набор хар-к',
          dataField: 'setId',
          dataType: 'number',
          width: 300,
          dictionary: {
            get: 'api/character/set/list',
            dataSource: [],
            name: 'name',
            id: 'id'
          }
        },*/
        {
          caption: 'Характеристика',
          dataField: 'nameCh',
          dataType: 'string',
          width: undefined,
          required: true,
          allowEdit: false,
          buttons: [{
            id: '1',
            hint: 'Изменить',
            icon: 'edit'  // dx-icon-download, dx-icon-home  и т.д.
          }
          ]
          // dictionary: {
          //   get: 'api/character/character/list',
          //   dataSource: [],
          //   name: 'name',
          //   id: 'id'
          // }
        },
        {
          caption: 'Единица измерения',
          dataField: 'nameUnit',
          dataType: 'string',
          allowEdit: false,
          width: 200
        },
        {
          caption: 'Действует с',
          dataField: 'fromDate',
          dataType: 'date',
          allowEdit: true,
          width: 200
        },
        {
          caption: 'Действует до',
          dataField: 'untilDate',
          dataType: 'date',
          allowEdit: true,
          width: 200
        },
        {
          caption: 'Выводить в КС-14 и ОС-1/ОС-3',
          dataField: 'prEnter',
          dataType: 'string',
          allowEdit: true,
          width: 200
        }, {
          caption: 'id character',
          dataField: 'characterId',
          dataType: 'number',
          allowEdit: false,
          visible: false
        }]
    },
    {
      id: 'ActionType',
      name: 'Типы действия',
      group: 'tasks',
      editRole: 'informationtasks',
      show: true,
      remoteOperations: true,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      columnAutoWidth: false,
      fileNameExport: 'Типы действия',
      primaryKey: 'id',
      delete: '/api/task/action-type',
      post: 'api/task/action-type',
      put: 'api/task/action-type',
      get: 'api/task/action-type/list',
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        }
      ]
    },
    {
      id: 'TaskGroup',
      name: 'Группы задач',
      group: 'tasks',
      editRole: 'informationtasks',
      post: 'api/task/group',
      delete: 'api/task/group',
      put: 'api/task/group',
      get: 'api/task/group',
      fileNameExport: 'Группы задач',
      primaryKey: 'id',
      show: true,
      remoteOperations: true,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      columnAutoWidth: false,
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string',
          width: '80%'
        },
        {
          caption: 'Действует с',
          dataField: 'activeFrom',
          dataType: 'date',
          width: 175
        },
        {
          caption: 'Действует до',
          dataField: 'activeTill',
          dataType: 'date',
          width: 175
        }
      ]
    },
    {
      id: 'Statuses',
      name: 'Статус задачи',
      group: 'tasks',
      editRole: 'informationtasks',
      get: 'api/task/status/list',
      delete: 'api/task/status',
      post: 'api/task/status',
      put: 'api/task/status',
      fileNameExport: 'Статус задачи',
      primaryKey: 'id',
      show: true,
      remoteOperations: true,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      hideRowStatus: true,
      columnAutoWidth: false,
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        }
      ]
    },
    {
      id: 'TMC',
      name: 'Товарно-материальные ценности',
      group: 'others',
      editRole: 'informationothers',
      get: 'api/document/tmc/list',
      delete: 'api/document/tmc',
      post: 'api/document/tmc',
      put: 'api/document/tmc',
      fileNameExport: 'Товарно-материальные ценности',
      primaryKey: 'id',
      show: false,
      remoteOperations: true,
      showCountRow: true,
      columnAutoWidth: false,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        },
        {
          caption: 'Единица изменения',
          dataField: 'measureUnit',
          dataType: 'string'
        }
      ]
    },
    {
      id: 'Main-assets',
      name: 'Основные средства',
      group: 'subobjects',
      editRole: 'informationsubobjects',
      get: 'api/character/assets/list',
      delete: 'api/character/assets',
      post: 'api/character/assets',
      put: 'api/character/assets',
      fileNameExport: 'Основные средства',
      primaryKey: 'id',
      show: true,
      remoteOperations: true,
      showCountRow: true,
      columnAutoWidth: false,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        },
        {
          caption: 'Код СТОУ',
          dataField: 'stouName',
          dataType: 'string'
        },
        {
          caption: 'Инвентарный номер',
          dataField: 'inventoryNumber',
          dataType: 'string'
        },
        {
          caption: 'Аналитика',
          dataField: 'groupFund',
          dataType: 'string'
        },
        {
          caption: 'Филиал',
          dataField: 'branchId',
          width: 400,
          dataType: 'string',
          showClearButton: true,
          dictionary: {
            get: 'api/organization/departments?filter=%5B%22typeId%22,%22=%22,2%5D',
            dataSource: [],
            name: 'name',
            id: 'id',
          }
        },
        {
          caption: 'Подразделение',
          dataField: 'departmentId',
          dataType: 'string',
          showClearButton: true,
          dictionary: {
            get: 'api/organization/departments?typeId=4&typeId=3',
            dataSource: [],
            name: 'name',
            id: 'id',
          }
        },
        {
          caption: 'МОЛ',
          dataField: 'mol',
          dataType: 'string'
        },
        {
          caption: 'Остаточная стоимость, бухгалтерский учет',
          dataField: 'residualCostAccounting',
          dataType: 'number'
        },
        {
          caption: 'Остаточный СПИ после проведения работ, бухгалтерский',
          dataField: 'residualLifeAccounting',
          dataType: 'number'
        },
        {
          caption: 'Начало действия',
          dataField: 'startDate',
          dataType: 'date'
        },
        {
          caption: 'Окончание действия',
          dataField: 'terminationDate',
          dataType: 'date'
        }
      ]
    },
    {
      id: 'VAT',
      name: 'НДС',
      group: 'others',
      editRole: 'informationothers',
      get: 'api/plan/vat/list',
      delete: 'api/plan/vat',
      post: 'api/plan/vat',
      put: 'api/plan/vat',
      fileNameExport: 'НДС',
      primaryKey: 'id',
      show: true,
      remoteOperations: true,
      showCountRow: true,
      columnAutoWidth: false,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      columns: [{
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string'
      }, {
        caption: 'Ставка НДС',
        dataField: 'vatRate',
        dataType: 'number'
      }, {
        caption: 'Начало действия',
        dataField: 'startDate',
        dataType: 'date'
      }, {
        caption: 'Окончание действия',
        dataField: 'terminationDate',
        dataType: 'date'
      }]
    },
    {
      id: 'Expenses',
      name: 'Укрупненные виды затрат',
      group: 'plan',
      editRole: 'informationplan',
      get: 'api/plan/expenses/list?children=true',
      delete: 'api/plan/expenses',
      post: 'api/plan/expenses',
      put: 'api/plan/expenses',
      fileNameExport: 'Укрупненные виды затрат',
      primaryKey: 'id',
      show: true,
      remoteOperations: true,
      showCountRow: true,
      columnAutoWidth: false,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        },
        {
          caption: 'Полное наименование',
          dataField: 'name',
          dataType: 'string'
        },
        {
          caption: 'Начало действия',
          dataField: 'startDate',
          dataType: 'date'
        },
        {
          caption: 'Окончание действия',
          dataField: 'terminationDate',
          dataType: 'date'
        },
        {
          caption: 'Использовать в закупках',
          dataField: 'isInPurchases',
          dataType: 'boolean'
        }
      ]
    },
    {
      id: 'ObjectTypes',
      show: true,
      name: 'Тип объекта',
      group: 'objects',
      editRole: 'informationobjects',
      primaryKey: 'id'
    },
    {
      id: 'FinSources',
      name: 'Источники финансирования',
      group: 'objects',
      editRole: 'informationobjects',
      get: 'api/object/fin-source/list',
      delete: 'api/object/fin-source',
      post: 'api/object/fin-source',
      put: 'api/object/fin-source',
      fileNameExport: 'Источники финансирования',
      primaryKey: 'id',
      show: true,
      remoteOperations: true,
      showCountRow: true,
      columnAutoWidth: false,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        },
        {
          caption: 'Символьное наименование',
          dataField: 'code',
          dataType: 'string'
        },
        {
          caption: 'Краткое наименование',
          dataField: 'shortName',
          dataType: 'string'
        },
        {
          caption: 'Вид ивестиционного бюджета',
          dataField: 'investmentBudgetKindId',
          dataType: 'number',
          width: 100,
          dictionary: {
            get: '/api/object/budget-kind/list',
            dataSource: [],
            name: 'name',
            id: 'id'
          }
        }
      ]
    }, {
      id: 'voltage-class',
      group: 'objects',
      show: true,
      editRole: 'informationobjects',
      name: 'Класс напряжений',
      primaryKey: 'id'
    }, {
      id: 'VoltageGroup',
      name: 'Группа объектов по уровню напряжения и видам',
      group: 'objects',
      editRole: 'informationobjects',
      get: 'api/object/voltage-group/list',
      delete: 'api/object/voltage-group',
      post: 'api/object/voltage-group',
      put: 'api/object/voltage-group',
      fileNameExport: 'Группа напряжений',
      primaryKey: 'id',
      show: true,
      remoteOperations: true,
      showCountRow: true,
      columnAutoWidth: false,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        }
      ]
    },
    {
      id: 'Customer',
      name: 'Заказчик',
      group: 'objects',
      editRole: 'informationobjects',
      get: 'api/object/customer/list?all=true',
      delete: 'api/object/customer',
      post: 'api/object/customer',
      put: 'api/object/customer',
      fileNameExport: 'Заказчик',
      primaryKey: 'id',
      show: true,
      remoteOperations: true,
      showCountRow: true,
      columnAutoWidth: false,
      allowRefresh: true,
      allowAdd: false,
      editMode: 'batch',
      allowUpdate: false,
      allowDelete: false,
      allowCopy: false,
      fastDelete: false,
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string',
          width: 1500
        },
        {
          caption: 'Признак актуальности',
          dataField: 'isActual',
          dataType: 'boolean'
        }
      ]
    },
    {
      id: 'FixedAssetsGroup',
      name: 'Группа ОС',
      group: 'objects',
      editRole: 'informationobjects',
      get: 'api/object/assets-group/list',
      delete: 'api/object/assets-group',
      post: 'api/object/assets-group',
      put: 'api/object/assets-group',
      fileNameExport: 'Группа ОС',
      primaryKey: 'id',
      show: true,
      remoteOperations: true,
      showCountRow: true,
      columnAutoWidth: false,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        },
        {
          caption: 'Примечания',
          dataField: 'notes',
          dataType: 'string'
        }
      ]
    },
    {
      id: 'ObjectGroup',
      name: 'Группировка объектов капитального строительства',
      group: 'objects',
      editRole: 'informationobjects',
      get: 'api/object/object-group/list',
      delete: 'api/object/object-group',
      post: 'api/object/object-group',
      put: 'api/object/object-group',
      fileNameExport: 'Группировка объектов капитального строительства',
      primaryKey: 'id',
      show: true,
      remoteOperations: true,
      showCountRow: true,
      columnAutoWidth: false,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        }
      ]
    },
    {
      group: 'others',
      editRole: 'informationothers',
      id: 'org-structure',
      name: 'Структура организации',
      show: true,
      fileNameExport: 'Структура организации',
      primaryKey: 'id'
    },
    {
      id: 'ValuesOfChar',
      show: true,
      name: 'Справочники значений характеристик',
      group: 'subobjects',
      editRole: 'informationsubobjects',
      primaryKey: 'id'
    },
    {
      id: 'Dicts',
      name: 'Х-ки',
      fileNameExport: 'Характеристики',
      show: true,
      group: 'subobjects',
      editRole: 'informationsubobjectsdictionaryvalue',
      get: 'api/character/dictionary/list',
      post: 'api/character/dictionary',
      put: 'api/character/dictionary',
      delete: 'api/character/dictionary',
      primaryKey: 'id',
      fieldDelete: 'name',
      focusedRowEnabled: true,
      remoteOperations: true,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'row',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      wordWrap: true,
      rowPrepared: (rowElement, rowInfo, userId, focusedRowKey) => {
        if (rowInfo.id === -1 && !focusedRowKey) {
          rowElement.classList.add('dx-row-focused');
        }
      },
      columns: [{
        caption: 'Название справочника',
        dataField: 'name',
        dataType: 'string',
        validationRule: [{type: 'required', message: 'Это поле необходимо для заполнения'}],
      }, {
        caption: 'Единица измерения',
        dataField: 'unitId',
        dataType: 'string',
        dictionary: {
          get: '/api/character/unit/list',
          dataSource: [],
          name: 'name',
          id: 'id'
        }
      }, {
        caption: 'Признак актуальности',
        dataField: 'isActual',
        dataType: 'boolean'
      }]
    },
    {
      id: 'ValueDicts',
      name: 'Значения характеристик',
      group: 'subobjects',
      editRole: 'informationsubobjectsdictionaryvalue',
      get: 'api/character/dictionary-value/list',
      post: 'api/character/dictionary-value',
      put: 'api/character/dictionary-value',
      delete: 'api/character/dictionary-value',
      primaryKey: 'id',
      fieldDelete: 'value',
      remoteOperations: true,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'cell',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      columnAutoWidth: false,
      defSort: 'asc',
      wordWrap: true,
      columns: [/*
        {
          caption: 'хар-кa',
          dataField: 'DictionaryId',
          dataType: 'number',
          width: 300,
          dictionary: {
            get: 'api/character/dictionary/list?combo=true',
            dataSource: [],
            name: 'name',
            id: 'id'
          }
        },*/
        {
          caption: 'Значение',
          required: true,
          width: undefined,
          dataField: 'value',
          dataType: 'string',
          validationRule: [{type: 'required', message: 'Это поле необходимо для заполнения'}],
          sort: 'asc'
        },
        {
          caption: 'Действует с',
          dataField: 'fromDate',
          dataType: 'date',
          allowEdit: true,
          width: 200
        },
        {
          caption: 'Действует до',
          dataField: 'untilDate',
          dataType: 'date',
          allowEdit: true,
          width: 200
        }

      ]
    },

    {
      id: 'AgregatedChars',
      show: true,
      name: 'Укрупненные характеристики',
      fileNameExport: 'Укрупненные характеристики',
      group: 'subobjects',
      editRole: 'informationsubobjects',
      primaryKey: 'id'
    },
    {
      id: 'Agregatedcharacter',
      show: false,
      name: 'Физические характеристики',
      fileNameExport: 'Физические характеристики',

      focusedRowEnabled: true,
      group: 'subobjects',
      editRole: 'informationsubobjects',
      get: 'api/character/character/list?aggregated=true',
      post: 'api/character/character',
      put: 'api/character/character',
      delete: 'api/character/character',
      primaryKey: 'id',
      fieldDelete: 'name',
      remoteOperations: true,
      allowRefresh: true,
      allowAdd: false,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: false,
      allowCopy: true,
      fastDelete: false,
      showCountRow: true,
      columnAutoWidth: false,
      wordWrap: true,
      columns: [
        {
          caption: 'Укрупненная характеристика',
          dataField: 'name',
          dataType: 'string',
          allowEdit: false,
          width: undefined
        }]
    },
    {
      id: 'characterInclusion',
      show: false,
      name: 'укрупненные х-ки',
      fileNameExport: 'Укрупненные характеристики',
      group: 'subobjects',
      editRole: 'informationsubobjects',
      get: 'api/character/character-inclusion-view/list',
      post: 'api/character/character-inclusion',
      put: 'api/character/character-inclusion',
      delete: 'api/character/character-inclusion',
      primaryKey: 'id',
      remoteOperations: true,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      columnAutoWidth: false,
      queryParam: [['toId', -1]],
      fieldDelete: 'nameFrom',
      wordWrap: true,
      /*getDeleteField: (x) => {
      },*/
      columns: [/*
        {
          caption: 'toId',
          dataField: 'toId',
          allowEdit: false,
          dataType: 'number',
          width: 300,
          dictionary: {
            get: 'api/character/character/list?combo=true',
            dataSource: [],
            name: 'name',
            id: 'id'
          }
        },*/
        {
          caption: 'Включенные характеристики',
          dataField: 'fromId',
          dataType: 'number',
          required: true,
          width: 300,
          dictionary: {
            get: 'api/character/character/list?combo=true',
            dataSource: [],
            name: 'name',
            id: 'id'
          }
        }]
    }, {
      id: 'FinancialPlanSection',
      name: 'ПИ-1Г, БЗиЗ, Смета',
      group: 'plan',
      primaryKey: 'id',
      editRole: 'informationplan',
      show: true
    },
    {
      id: 'ExpensesDirection',
      name: 'Направление расходов',
      group: 'plan',
      editRole: 'informationplan',
      post: 'api/plan/expenses-direction',
      delete: 'api/plan/expenses-direction',
      put: 'api/plan/expenses-direction',
      get: 'api/plan/expenses-direction/list',
      fileNameExport: 'Направление расходов',
      primaryKey: 'id',
      show: true,
      remoteOperations: true,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      columnAutoWidth: false,
      columns:
        [
          {
            caption: 'Код',
            dataField: 'code',
            dataType: 'string'
          },
          {
            caption: 'Наименование',
            dataField: 'name',
            dataType: 'string'
          },
          {
            caption: 'Укрупненный вид затрат',
            dataField: 'costItemId',
            dataType: 'string',
            showClearButton: true,
            dictionary: {
              get: '/api/plan/expenses/list?purchase=true',
              dataSource: [],
              multiple: true,
              name: 'name',
              id: 'id',
            }
          }
        ]
    },
    {
      id: 'Close-period',
      name: 'Закрытый период',
      group: 'others',
      editRole: 'informationothers',
      fileNameExport: 'Закрытый период',
      primaryKey: 'id',
      show: true,
      allowRefresh: true
    },
    {
      id: 'templateGUID',
      show: true,
      name: 'GUID XML-шаблолнов',
      get: 'api/document/template/guid',
      post: 'api/document/template/guid',
      put: 'api/document/template/guid',
      delete: 'api/document/template/guid',
      primaryKey: 'id',
      editMode: 'batch',
      allowUpdate: true,
      allowAdd: true,
      allowDelete: true,
      group: 'documents',
      editRole: 'informationdocuments',
      fastDelete: true,
      fieldDelete: 'guid',
      hideRowStatus: true,
      remoteOperations: true,
      allowCopy: true,
      allowRefresh: true,
      showCountRow: true,
      wordWrap: true,
      columns: [
        {
          caption: 'Шаблон',
          dataField: 'templateId',
          dataType: 'integer',
          allowEdit: true,
          width: 200,
          required: true,
          dictionary: {
            get: '/api/document/type/templates/file',
            dataSource: [],
            name: 'name',
            id: 'id',
          }
        }, {
          caption: 'GUID',
          dataField: 'guid',
          dataType: 'string',
          allowEdit: true,
          required: true
        }]
    },

    {
      id: 'pi1gRowTemplate',
      show: true,
      name: 'Распределение КВЛ по статьям ПИ-1Г',
      get: 'api/plan/pi1g-row-template/list',
      post: 'api/plan/pi1g-row-template',
      put: 'api/plan/pi1g-row-template',
      delete: 'api/plan/pi1g-row-template',
      primaryKey: 'id',
      group: 'plan',
      editRole: 'informationplan',
      remoteOperations: true,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      columnAutoWidth: false,
      columns: [{
        caption: 'Тип объекта',
        dataField: 'objectTypeId',
        dataType: 'string',
        showClearButton: true,
        width: 300,
        dictionary: {
          get: '/api/object/type/list',
          dataSource: [],
          name: 'fullName',
          id: 'id',
        }
      }, {
        caption: 'Статья ПИ-1Г',
        dataField: 'sectionId',
        dataType: 'string',
        showClearButton: true,
        width: 300,
        dictionary: {
          get: '/api/plan/financial-plan-section/list',
          dataSource: [],
          name: 'name',
          id: 'id',
        }
      },
        {
          caption: 'Направление расходов',
          dataField: 'expensesDirectionId',
          dataType: 'string',
          width: 300,
          showClearButton: true,
          dictionary: {
            get: '/api/plan/expenses-direction/list',
            dataSource: [],
            name: 'name',
            id: 'id',
          }
        },
        {
          caption: 'Процент',
          dataField: 'percent',
          dataType: 'string',
          width: 130,
          validationRule: [{
            type: 'custom',
            message: 'Процент для данного типа объекта должен быть равен 100',
            validationCallback: (e) => {
              return e + e.data.percent <= 100;
            }
          }]
        }]
    },
    {
      id: 'type-work-list',
      show: true,
      group: 'others',
      editRole: 'informationothers',
      name: 'Основной справочник видов работ',
      primaryKey: 'id'
    },
    {
      id: 'executive-documentation',
      show: true,
      name: 'Исполнительная документация',
      group: 'others',
      editRole: 'informationothers',
      primaryKey: 'id',
      fieldDelete: 'name'
    },
    {
      id: 'FakeSubobject',
      show: true,
      name: 'Соответствие подобъектов по типам объекта',
      group: 'subobjects',
      editRole: 'informationsubobjects',
      primaryKey: 'id',
      fieldDelete: 'name'
    },

    {
      id: 'FakeSubobject1',
      name: 'Соответствие подобъектов по типам объекта(пред)',
      group: 'subobjects',
      editRole: 'informationsubobjects',
      get: 'api/object/fake-subobject-type/list',
      delete: 'api/object/fake-subobject-type',
      post: 'api/object/fake-subobject-type',
      put: 'api/object/fake-subobject-type',
      fileNameExport: 'Соответствие подобъектов по типам объекта',
      primaryKey: 'id',
      show: false,
      remoteOperations: true,
      showCountRow: true,
      columnAutoWidth: false,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      columns: [
        {
          caption: 'Наименование подобъекта',
          dataField: 'name',
          dataType: 'string'
        },
        {
          caption: 'Объект',
          dataField: 'objectTypeId',
          dataType: 'string',
          showClearButton: true,
          dictionary: {
            get: '/api/object/type/list',
            dataSource: [],
            name: 'fullName',
            id: 'id',
          }
        },
        {
          caption: 'Подобъект',
          dataField: 'subobjectTypeId',
          dataType: 'string',
          showClearButton: true,
          dictionary: {
            get: '/api/object/sub/type/list',
            dataSource: [],
            name: 'name',
            id: 'id',
          }
        },
        {
          caption: 'Процент',
          dataField: 'percent',
          dataType: 'number'
        },
        {
          caption: 'Код СТОУ',
          dataField: 'stouId',
          dataType: 'string',
          showClearButton: true,
          dictionary: {
            get: '/api/object/sub/stou/list',
            dataSource: [],
            name: 'name',
            id: 'id',
          }
        }
      ]
    },

    {
      id: 'customerFinSource',
      show: true,
      name: 'Источник финансирования, заказчик',
      get: 'api/object/customer-fin-source/list',
      post: 'api/object/customer-fin-source',
      put: 'api/object/customer-fin-source',
      delete: 'api/object/customer-fin-source',
      primaryKey: 'id',
      handleCellChanging: true,
      remoteOperations: true,
      group: 'objects',
      editRole: 'informationobjects',
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      columnAutoWidth: false,
      columns: [{
        caption: 'Источник финансирования',
        dataField: 'finSourceId',
        dataType: 'string',
        required: true,
        dictionary: {
          get: 'api/object/fin-source/list',
          dataSource: [],
          name: 'name',
          id: 'id'
        }
      }, {
        caption: 'Заказчик',
        dataField: 'customerId',
        dataType: 'string',
        dictionary: {
          get: 'api/object/customer/list?all=true',
          dataSource: [],
          name: 'name',
          id: 'id'
        }
      }, {
        caption: 'Группа по ур. напряжения и видам',
        dataField: 'voltageGroupId',
        dataType: 'number',
        dictionary: {
          get: 'api/object/voltage-group/list',
          dataSource: [],
          name: 'name',
          id: 'id'
        }
      }, {
        caption: 'Вид инвестиционного бюджета',
        dataField: 'budgetKindId',
        dataType: 'number',
        required: true,
        dictionary: {
          get: 'api/object/budget-kind/list',
          dataSource: [],
          name: 'name',
          id: 'id'
        }
      }, {
        caption: 'По умолчанию',
        dataField: 'isDefault',
        dataType: 'boolean'
      }]
    },
    {
      id: 'objectKind',
      get: 'api/character/object-kind/list',
      post: 'api/character/object-kind',
      put:  'api/character/object-kind',
      delete:  'api/character/object-kind',
      show: true,
      showCaption: true,
      name: 'Вид объекта',
      group: 'objects',
      editRole: 'informationobjects',
      fileNameExport: 'Виды объектов',
      primaryKey: 'id',
      remoteOperations: true,
      choiceOnClick: true,
      showCountRow: true,
      hideRowStatus: true,
      columnAutoWidth: false,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      addCheckBox: true,
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string',
          width: 900
        },
        {
          caption: 'ИНН контрагента',
          dataField: 'contragentInn',
          dataType: 'string',
          allowEdit: false,
          width: 700
        },
        {
          caption: 'Признак актуальности',
          dataField: 'isActual',
          dataType: 'boolean',
          allowEdit: false,
          width: 80
        }
      ]
    },
    {
      id: 'investmentBudgetKind',
      show: true,
      showCaption: true,
      name: 'Вид инвестиционного бюджета',
      fileNameExport: 'Виды инвестиционного бюджета',
      primaryKey: 'id',
      group: 'objects',
      editRole: 'informationobjects',
      remoteOperations: true,
      choiceOnClick: true,
      showCountRow: true,
      hideRowStatus: true,
      allowRefresh: true,
      allowAdd: true,
      editMode: 'batch',
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      get: 'api/object/budget-kind/list',
      post: 'api/object/budget-kind',
      put: 'api/object/budget-kind',
      delete: 'api/object/budget-kind',
      addCheckBox: true,
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string',
        }]
    },
    {
      id: 'workKind',
      showCaption: true,
      show: true,
      name: 'Вид работ',
      fileNameExport: 'Виды работ',
      primaryKey: 'id',
      group: 'objects',
      editRole: 'informationobjects',
      editMode: 'batch',
      remoteOperations: true,
      allowRefresh: true,
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      allowAdd: true,
      choiceOnClick: true,
      showCountRow: true,
      hideRowStatus: true,
      addCheckBox: true,
      get: 'api/object/job-kind/list?all=true',
      put: 'api/object/job-kind',
      post: 'api/object/job-kind',
      delete: 'api/object/job-kind',
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string',
          width: '60%'
        }, {
          caption: 'Вид объекта',
          dataField: 'objectKindId',
          dataType: 'number',
          required: true,
          dictionary: {
            get: 'api/object/kind/list',
            dataSource: [],
            name: 'name',
            id: 'id'
          },
          width: '10%'
        }, {
          caption: 'Дата начала действия',
          dataField: 'activeFrom',
          dataType: 'date',
          width: '15%'
        }, {
          caption: 'Дата окончания действия',
          dataField: 'activeTill',
          dataType: 'date',
          width: '15%'
        }]
    },
    {
      id: 'cableRoutingMethod',
      showCaption: true,
      show: true,
      name: 'Способ прокладки кабеля',
      fileNameExport: 'Способы прокладки кабеля',
      primaryKey: 'id',
      group: 'objects',
      showCountRow: true,
      editRole: 'informationobjects',
      editMode: 'batch',
      remoteOperations: true,
      choiceOnClick: true,
      hideRowStatus: true,
      allowRefresh: true,
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      allowAdd: true,
      addCheckBox: true,
      get: 'api/object/cable-route/list',
      put: 'api/object/cable-route',
      post: 'api/object/cable-route',
      delete: 'api/object/cable-route',
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string',
        }]
    },
    {
      id: 'workState',
      showCaption: true,
      show: true,
      showCountRow: true,
      name: 'Состояние работ',
      fileNameExport: 'Состояния работ',
      primaryKey: 'id',
      group: 'objects',
      editRole: 'informationobjects',
      remoteOperations: true,
      choiceOnClick: true,
      hideRowStatus: true,
      allowAdd: true,
      allowRefresh: true,
      allowUpdate: true,
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      addCheckBox: true,
      editMode: 'batch',
      get: 'api/object/work-state/list',
      post: 'api/object/work-state-dictionary',
      put: 'api/object/work-state-dictionary',
      delete: 'api/object/work-state-dictionary',
      columns: [{
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string',
        width: '50%'
      }, {
        caption: 'Используется в договорах ТП',
        dataField: 'isContract',
        dataType: 'boolean',
        width: '25%'
      }, {
        caption: 'Включать в планы',
        dataField: 'inPlans',
        dataType: 'boolean',
        width: '25%'
      }]
    },
    {
      group: 'others',
      editRole: 'informationothers',
      id: 'prodCalendar',
      name: 'Календарь',
      show: true,
      fileNameExport: '***',
      primaryKey: 'id'
    },
    {
      id: 'bdds',
      name: 'БДДС',
      group: 'plan',
      editRole: 'informationplan',
      show: true
    },
    {
      id: 'additional-agreements',
      name: 'Тип доп. соглашения',
      group: 'plan',
      editRole: 'informationplan',
      show: true
    },
    {
      id: 'documentCounter',
      name: 'Счетчики автонумерации',
      fileNameExport: 'Счетчики автонумерации',
      primaryKey: 'id',
      editRole: 'informationdocuments',
      show: true,
      remoteOperations: true,
      columnAutoWidth: false,
      showCaption: true,
      allowUpdate: true,
      editMode: 'batch',
      allowRefresh: true,
      allowAdd: true,
      selectedMode: 'multiple',
      allowDelete: true,
      allowCopy: true,
      fastDelete: true,
      showCountRow: true,
      group: 'documents',
      get: 'api/document/counter/list',
      post: 'api/document/counter',
      put: 'api/document/counter',
      delete: 'api/document/counter',
      columns: [
        {
          caption: 'Филиал',
          dataField: 'departmentId',
          width: 500,
          dataType: 'string',
          sort: 'asc',
          dictionary: {
            get: 'api/organization/departments?typeId=2',
            dataSource: [],
            name: 'name',
            id: 'id'
          }
        },
        {
          caption: 'Тип документа',
          dataField: 'typeId',
          dataType: 'string',
          width: 300,
          required: true,
          dictionary: {
            get: 'api/document/type/list?counter=true&system=true&invisible=true',
            dataSource: [],
            name: 'name',
            id: 'id'
          }
        },
        {
          caption: 'Год',
          dataField: 'year',
          width: 300,
          dataType: 'number',
          validationRule: [{
            type: 'custom',
            message: 'Превышено максимально допустимое количество симвлов!',
            validationCallback: (e) => {

              if (e.data.year) {
                return (e.data.year.toString().length < 5);
              } else {
                return true;
              }
            }
          }],
        },
        {
          caption: 'Месяц',
          dataField: 'month',
          width: 300,
          dataType: 'number',
          dictionary: {
            dataSource: [{id: 0, name: 'Январь'}, {id: 1, name: 'Февраль'}, {id: 2, name: 'Март'},
              {id: 3, name: 'Апрель'}, {id: 4, name: 'Май'}, {id: 5, name: 'Июнь'},
              {id: 6, name: 'Июль'}, {id: 7, name: 'Август'}, {id: 8, name: 'Сентябрь'},
              {id: 9, name: 'Октябрь'}, {id: 10, name: 'Ноябрь'}, {id: 11, name: 'Декабрь'}],
            name: 'name',
            id: 'id'
          }
        },
        {
          caption: 'Текущее значение',
          dataField: 'value',
          dataType: 'number',
          width: 200,
        },
        {
          caption: 'Максимальное значение',
          dataField: 'maxValue',
          dataType: 'number',
          width: 200,
        }]
    },
    {
      id: 'nomenclature-list',
      show: true,
      group: 'others',
      editRole: 'nomenclaturelist',
      name: 'Справочник МТРиО',
      primaryKey: 'id'
    }, {
      id: 'nomenclature-dismantling',
      show: true,
      group: 'others',
      editRole: 'nomenclaturelist',
      name: 'Стоимость металлов',
      primaryKey: 'id'
    }, {
      id: 'dismantling',
      show: true,
      group: 'others',
      editRole: 'nomenclaturelist',
      name: 'Перечень используемых материалов и единиц оборудования',
      primaryKey: 'id'
    }, {
      id: 'dismantling-type',
      show: true,
      group: 'others',
      editRole: 'nomenclaturelist',
      name: 'Типовые виды оборудования',
      primaryKey: 'id'
    },
    {
      id: 'exception-closed-periods',
      show: true,
      group: 'plan',
      editRole: 'informationplan',
      name: 'Исключения для закрытых периодов планов',
      primaryKey: 'id'
    },
    {
      id: 'yearcode-ipr',
      show: true,
      group: 'investprogram',
      editRole: 'informationinvestprogram',
      name: 'Код года в ИПР',
      primaryKey: 'year'
    },
    {
      id: 'finsource-ipr',
      show: true,
      group: 'investprogram',
      editRole: 'informationinvestprogram',
      name: 'Источники финансирования',
      primaryKey: 'id'
    },
    {
      id: 'projectgroup-ipr',
      show: true,
      group: 'investprogram',
      editRole: 'informationinvestprogram',
      name: 'Группа проектов',
      primaryKey: 'id'
    },
    {
      id: 'worktype-ipr',
      show: true,
      group: 'investprogram',
      editRole: 'informationinvestprogram',
      name: 'Вид работ ИП',
      primaryKey: 'id'
    },
    {
      id: 'implementstage-ipr',
      show: true,
      group: 'investprogram',
      editRole: 'informationinvestprogram',
      name: 'Стадия реализации ИП',
      primaryKey: 'id'
    },
    {
      id: 'variables',
      name: 'Переменные',
      group: 'documents',
      primaryKey: 'name',
      editRole: 'informationdocuments',
      show: true
    },
    {
      id: 'construction-period',
      name: 'Сроки строительства объектов ТП',
      group: 'objects',
      primaryKey: 'id',
      editRole: 'informationobjects',
      show: true
    }, {
      id: 'percent-sum-ipr',
      group: 'investprogram',
      show: true,
      editRole: 'informationinvestprogram',
      name: 'Процент распределения сумм по видам затрат',
      primaryKey: 'id'
    }, {
      id: 'document-signatories',
      group: 'documents',
      show: true,
      editRole: 'informationdocuments',
      name: 'Подписанты документов',
      primaryKey: 'id'
    }, {
      id: 'municipal-areas',
      show: true,
      group: 'investprogram',
      editRole: 'informationinvestprogram',
      name: environment.name === 'infoenergo' ? 'Муниципальные районы' : 'Муниципальные районы РТ',
      primaryKey: 'id'
    }, {
      id: 'subobject-type-work',
      group: 'subobjects',
      show: true,
      editRole: 'informationsubobjects',
      name: 'Вид работ (набор хар-к)',
      primaryKey: 'id'
    }, {
      id: 'object-curator',
      group: 'objects',
      show: true,
      editRole: 'informationobjects',
      name: 'Кураторы объектов',
      primaryKey: 'id'
    }, {
      id: 'type-ipr',
      show: true,
      group: 'investprogram',
      editRole: 'informationinvestprogram',
      name: 'Тип ИП',
      primaryKey: 'id'
    }, {
      id: 'choiceInterfaceDocument',
      name: 'Формы',
      fileNameExport: 'Формы',
      primaryKey: 'id',
      allowChoice: true,
      hideRowStatus: true,
      get: 'api/application/forms?isDocumentTab=true',
      columns: [
        {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string'
        }
      ]
    }

  ];
}

