import {Component, Input, OnInit, ViewChild} from '@angular/core';
import notify from 'devextreme/ui/notify';
import {DxDropDownBoxComponent} from 'devextreme-angular';
import {ManualService} from "../../../services/manual.service";

@Component({
  selector: 'app-copy-link',
  templateUrl: './copy-link.component.html',
  styleUrls: ['./copy-link.component.scss'],
  providers: [ManualService]
})
export class CopyLinkComponent implements OnInit {

  loading = false;
  link = null;
  @Input() disabled;
  @Input() id;
  @Input() url;
  @Input() urlCard;
  @Input() instruction;
  @ViewChild('dropDownDoxLink', {static: false}) dropDownDoxLink: DxDropDownBoxComponent;

  constructor(private manualservice: ManualService) {
  }

  ngOnInit() {
  }

  copyToClipboard() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.getUrl()).then(() => {
          notify('Ссылка успешно скопирована!', 'success', 1500);
        }
      ).catch(err => {
        notify('Что-то пошло не так..', 'error', 1500);
        console.log('Something went wrong', err);
      });
    } else {
      this.dropDownDoxLink.instance.open();
      this.link = this.getUrl();
    }
  }

  getUrl() {
    if (this.id > 0) {
      return window.location.href.split('#')[0] + `#/${this.url}/${this.id}`;
    }
    return '';
  }

  async getInstruction() {
    try {
      this.loading = true;
      const data = await this.manualservice.getInstruction(this.instruction);
      if (data && data.length) {
        if (data.length === 1) {
          window.open(`/api/admin/manual/file?id=${data[0].id}`);
        }
      } else {
        notify({message: 'Для данного окна инструкция не найдена!', width: 800}, 'error', 1500);
      }
      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  }

  showCard() {
    window.open(
      window.location.origin + `/#${this.urlCard}` + this.id,
      '_blank'
    );
  }

}
