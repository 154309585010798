/**
 * Договоры
 * sklubowicz
 */

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

const api = '/api';

@Injectable()
export class ContractService {
  constructor(private http: HttpClient) {
  }

  public getSubcontractors(gencontractorId?: number, take?: number, skip?: number, filter?: string, sort?: string, group?: string): Observable<any> {
    let httpParams = new HttpParams();
    if (take) {
      httpParams = httpParams.append('take', take.toString());
    }
    if (skip) {
      httpParams = httpParams.append('skip', skip.toString());
    }
    if (filter) {
      httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    }
    if (sort) {
      httpParams = httpParams.append('sort', JSON.stringify(sort));
    }
    if (group) {
      httpParams = httpParams.set('group', JSON.stringify(group));
    }
    if (gencontractorId) {
      httpParams = httpParams.set('gencontractorId', String(gencontractorId));
    }
    return this.http.get<any>(`${api}/admin/subcontractors-object`, {params: httpParams});
  }

  public getObjects(subcontractorId: number, connectionId?: number, take?: number, skip?: number, filter?: string, sort?: string, group?: string): Observable<any> {
    let params = new HttpParams()
      .set('subcontractorId', String(subcontractorId))
    if (connectionId)
      params = params.set('connectionId', String(connectionId));
    if (take) {
      params = params.append('take', take.toString());
    }
    if (skip) {
      params = params.append('skip', skip.toString());
    }
    if (filter) {
      params = params.append('filter', JSON.stringify(filter) || '[]');
    }
    if (sort) {
      params = params.append('sort', JSON.stringify(sort));
    }
    if (group) {
      params = params.set('group', JSON.stringify(group));
    }
    return this.http.get<any>(`${api}/admin/object`, {params});
  }

  public getById(id: number): Promise<any> {
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.get<any>(`${api}/contract`, {params}).toPromise();
  }

  public deleteObjects(ids: any[]) {
    let params = new HttpParams();
    ids.forEach(x => params = params.append('id', x));
    return this.http.delete(`${api}/admin/object`, {params});
  }

  public saveObject(id): Observable<any> {
    return this.http.post<any>(`${api}/admin/object`, id);
  }

  public putSubcontractors(values: any): Observable<any> {
    return this.http.put<any>(`${api}/admin/subcontractors`, values);
  }

  /**
   * Возвращает этапы выполнения договора (список)
   * @param contractId
   */
  public getContractStages(contractId: number, objectId?: number, subcontractorId?: number): Observable<any> {
    let params = new HttpParams()
      .set('contractId', String(contractId));
    if (objectId) {
      params = params.set('objectId', String(objectId));
    }
    if (subcontractorId) {
      params = params.set('subcontractorId', String(subcontractorId));
    }
    return this.http.get<any>(`${api}/contract/stage/list`, {params});
  }


  public getContractSupplyPlan(stageId: number, vat: boolean): Observable<any> {
    let params = new HttpParams();
    if (stageId) params = params.append('stageId', String(stageId));
    if (vat) params = params.append('vat', String(vat));
    return this.http.get<any>(`${api}/contract/supply-plan/list`, {params});
  }

  public getContractPaymentPlan(stageId: number, vat: boolean): Observable<any> {
    let params = new HttpParams();
    if (stageId) params = params.append('stageId', String(stageId));
    if (vat) params = params.append('vat', String(vat));
    return this.http.get<any>(`${api}/contract/payment-plan/list`, {params});
  }

  public getContractPayments(stageId: number, vat: boolean): Observable<any> {
    let params = new HttpParams();
    if (stageId) params = params.append('stageId', String(stageId));
    if (vat) params = params.append('vat', String(vat));
    return this.http.get<any>(`${api}/contract/payment/list`, {params});
  }

  public getContragent(id: number): Observable<any> {
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.get<any>(`${api}/contract/contragent`, {params});
  }

  public checkContragent(field: any, value: any): Observable<any> {
    const params = new HttpParams()
      .set('field', field)
      .set('value', value);
    return this.http.get<any>(`${api}/contract/contragent/check`, {params});
  }

  public saveContragent(user, id) {
    if (id) {
      return this.http.put<any>(`${api}/contract/contragent`, user);
    } else {
      return this.http.post<any>(`${api}/contract/contragent`, user);
    }
  }

  public getContragentList(): Observable<any> {
    return this.http.get<any>(`/api/contract/contragent/list`);
  }

  public getConnectionContractList(options) {
    let params = new HttpParams();
    if (options.take) {
      params = params.append('take', options.take.toString());
    }
    if (options.skip) {
      params = params.append('skip', options.skip.toString());
    }
    if (options.filter) {
      params = params.append('filter', JSON.stringify(options.filter));
    }
    if (options.sort) {
      params = params.append('sort', JSON.stringify(options.sort));
    }
    if (options.group) {
      params = params.append('group', JSON.stringify(options.group));
    }
    return this.http.get<any>(`${api}/contract/contract-tp/list`, {params});

  }

  getEventList(id, objectId, take, skip, filter?, sort?, group?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', id);
    httpParams = httpParams.append('objectId', objectId);
    if (take) httpParams = httpParams.append('take', take.toString());
    if (skip) httpParams = httpParams.append('skip', skip.toString());
    httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    if (sort) httpParams = httpParams.append('sort', JSON.stringify(sort));
    if (group) httpParams = httpParams.set('group', JSON.stringify(group));
    return this.http.get<any>(`${api}/contract/connection-work/list`, {params: httpParams});
  }

  getEventCostItemList(id, objectId, take, skip, filter?, sort?, group?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', id);
    httpParams = httpParams.append('objectId', objectId);
    if (take) httpParams = httpParams.append('take', take.toString());
    if (skip) httpParams = httpParams.append('skip', skip.toString());
    httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    if (sort) httpParams = httpParams.append('sort', JSON.stringify(sort));
    if (group) httpParams = httpParams.set('group', JSON.stringify(group));
    return this.http.get<any>(`${api}/contract/connection-cost-item/list`, {params: httpParams});
  }

  setHttpParamsGrid(params, queryParams) {
    if (queryParams.take) params = params.append('take', queryParams.take.toString());
    if (queryParams.skip) params = params.append('skip', queryParams.skip.toString());
    if (queryParams.filter) params = params.append('filter', JSON.stringify(queryParams.filter));
    if (queryParams.sort) params = params.append('sort', JSON.stringify(queryParams.sort));
    if (queryParams.group) params = params.set('group', JSON.stringify(queryParams.group));
    return params;
  }

  getContractAdditionalPi1g(documentId, options, key?, ids?: any[]): any {
    let httpParams = new HttpParams();
    if(options)
      httpParams = this.setHttpParamsGrid(httpParams, options);
    if(documentId)
      httpParams = httpParams.append('documentId', documentId);
    if(key)
      httpParams = httpParams.append('key', key);
    if(ids)
      ids.forEach(x => httpParams = httpParams.append('id', x));
    return this.http.get<Array<any>>(`api/contract/print-contract-additional`, {params: httpParams});
  }

  getContractAdditionalDS(options, isCreate, isEdit, year, key?, ids?: any[]): any {
    let httpParams = new HttpParams();
    if(options)
      httpParams = this.setHttpParamsGrid(httpParams, options);
    if(key)
      httpParams = httpParams.append('key', key);
    if(isCreate)
      httpParams = httpParams.append('isCreate', isCreate);
    if(isEdit)
      httpParams = httpParams.append('isEdit', isEdit);
    if(year)
      httpParams = httpParams.append('year', year);
    if(ids)
      httpParams = httpParams.append('ids', JSON.stringify(ids))
      /*ids.forEach(x => httpParams = httpParams.append('id', x));*/
    return this.http.get<Array<any>>(`api/contract/contract-additional`, {params: httpParams});
  }

  getSections(objectsId, options): Observable<any> {
    let httpParams = new HttpParams();
    if (options) {
      httpParams = this.setHttpParamsGrid(httpParams, options);
    }
    if (objectsId) {
      httpParams = httpParams.append('objectsId', objectsId.toString());
    }
    return this.http.get<any>(`api/contract/sections`, {params: httpParams});
  }

  public getContractTypes() {
    return this.http.get<any>(`${api}/contract/contract-type`);
  }

  getFactPayments(options, status: Array<number>, paySign: number, paymentType: number, startDate, endDate, prepaid, objectId?: number, pointId?: number, contractId?: number, stageId?: number, contragentId?: number): Observable<any> {
    let params = new HttpParams();
    if (options) params = this.setHttpParamsGrid(params, options);
    if (pointId) params = params.append('pointId', String(pointId));
    if (contragentId) params = params.append('contragentId', String(contragentId));
    if (contractId) params = params.append('contractId', String(contractId));
    if (stageId) params = params.append('stageId', String(stageId));
    if (status) params = params.append('status', JSON.stringify(status));
    if (paySign) params = params.append('paySign', String(paySign));
    if (paymentType) params = params.append('paymentType', String(paymentType));
    if (objectId) params = params.append('objectId', String(objectId));
    if (prepaid) params = params.append('prepaid', prepaid);
    if (startDate) params = params.append('startDate', startDate.toDateString());
    if (endDate) params = params.append('endDate', endDate.toDateString());
    return this.http.get<any>(`${api}/contract/payments/fact`, {params});
  }

  public getRemainder(objectId: number, contragentId?: number): Observable<any> {
    return this.http.get<any>(`${api}/contract/remainder`, {params: {'objectId': String(objectId), 'contragentId': String(contragentId)}})
  }

  /*
  public getObject(id: number): Observable<any> {
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.get<any>(`${api}/object`, {params});
  }

  public getCharacters(objectId: number): Observable<any> {
    const params = new HttpParams()
      .set('id', String(objectId));
    return this.http.get<any>(`${api}/object/character/aggregated`, {params});
  }

  public getSubobjects(objectId: number): Observable<any> {
    const params = new HttpParams()
      .set('objectId', String(objectId));
    return this.http.get<any>(`${api}/object/sub/list`, {params});
  }

  public getSubobjectCharacters(setId: number): Observable<any> {
    const params = new HttpParams()
      .set('setId', String(setId));
    return this.http.get<any>(`${api}/object/sub/characters`, {params});
  }

  public getSub(id: number): Observable<any> {
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.get<any>(`${api}/object/sub`, {params});
  }

  public getObjectHierarchy(): Observable<any> {
    return this.http.get<any>(`${api}/object/hierarchy`);
  }

  public getTitleInfo(id: number): Observable<ObjectTitle> {
    const params = new HttpParams()
      .set('id', String(id));
    return this.http.get<ObjectTitle>(`${api}/object/title`, {params});
  }
  */
}
