import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, BehaviorSubject, Subject } from 'rxjs';
import {ObjectTitle} from '../models/object-title.model';

const api = '/api';

@Injectable()
export class ObjectService {

  months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
  objectData;
  disableSubmit = true;
  objectExportUrl = '';

  columnsList = [
    {
      caption: 'id',
      dataField: 'id',
      visible: false,
      allowEditing: false
    }, {
      caption: 'Код объекта КС',
      dataField: 'code',
      width: 200,
      sortOrder: 'asc',
      allowEditing: false,
      base: true
    }, {
      caption: 'Наименование объекта КС',
      dataType: 'string',
      dataField: 'fullName',
      width: 300,
      cellTemplate: 'fullNameTemplate',
      allowEditing: true,
      base: true
    }, {
      caption: 'Версия',
      dataField: 'version',
      width: 80,
      allowEditing: false
    }, {
      caption: 'Статус',
      dataField: 'status',
      width: 150,
      allowEditing: false,
      reload: true,
      base: true
    },
    {
      caption: 'Проверен',
      dataField: 'checked',
      dataType: 'boolean',
      width: 100,
      allowEditing: false
    },
    {
      caption: 'Пакет согласования',
      dataField: 'packageName',
      dataType: 'string',
      cellTemplate: 'packageTemplate',
      minWidth: 130,
      allowEditing: false,
      reload: true
    },
    {
      caption: 'Состояние работ',
      dataField: 'workState',
      width: 120,
      allowEditing: false,
      reload: true,
      base: true
    }, {
      caption: 'Филиал-балансодержатель',
      dataField: 'filial',
      allowEditing: false,
      reload: true,
      base: true
    }, {
      caption: 'Филиал-застройщик',
      dataField: 'builder',
      allowEditing: false,
      reload: true,
      base: true
    }, {
      caption: 'Ответственное подразделение',
      dataField: 'department',
      allowEditing: false,
      reload: true
    }, {
      caption: 'Куратор',
      dataField: 'curator',
      dataType: 'string',
      width: 150,
      allowEditing: false,
      reload: true,
      base: true
    }, {
      caption: 'Укрупненная полная стоимость (тыс.руб)',
      dataField: 'estimatedCost',
      dataType: 'number',
      format: '###,###,###,###,##0.###',
      width: 150,
      allowEditing: false
    }, {
      caption: 'Дата начала строительства',
      dataField: 'planStartDate',
      dataType: 'date',
      width: 100,
      allowEditing: false,
      selectedFilterOperation: 'between',
      base: true
    }, {
      caption: 'Дата окончания строительства',
      dataField: 'planEndDate',
      dataType: 'date',
      width: 100,
      allowEditing: false,
      selectedFilterOperation: 'between',
      base: true
    }, {
      caption: 'Заказчик',
      dataField: 'customer',
      dataType: 'string',
      visible: false,
      allowEditing: false,
      reload: true
    }, {
      caption: 'Вид инвестиционного бюджета',
      dataField: 'budgetKind',
      dataType: 'string',
      visible: false,
      allowEditing: false,
      reload: true
    }, {
      caption: 'Группа объектов по уровням напряжения и видам',
      dataField: 'voltageGroup',
      dataType: 'string',
      visible: false,
      allowEditing: false,
      reload: true
    }, {
      caption: 'Тип объекта КС',
      dataField: 'type',
      dataType: 'string',
      visible: false,
      allowEditing: false,
      reload: true
    }, {
      caption: 'Источник финансирования',
      dataField: 'financingSource',
      dataType: 'string',
      visible: false,
      allowEditing: false,
      reload: true
    }, {
      caption: 'Категория заявителя из МТП',
      dataField: 'specialMark',
      dataType: 'string',
      visible: false,
      allowEditing: false
    }, {
      caption: 'Договор ТП',
      dataField: 'connectionContractNumber',
      dataType: 'string',
      visible: true,
      width: 200,
      allowEditing: false,
      reload: true
    }, {
      caption: 'Документ-основание',
      dataField: 'baseDocumentName',
      dataType: 'string',
      visible: true,
      width: 200,
      allowEditing: false,
      reload: true
    }, {
      caption: 'Дата создания',
      dataField: 'dateCreate',
      dataType: 'date',
      format: 'dd.MM.yyyy',
      width: 100,
      allowEditing: false
    }, {
      caption: 'Идентификатор ИП',
      dataField: 'codeOfTitleIP',
      dataType: 'string',
      visible: true,
      width: 200,
      allowEditing: false,
      reload: true
    },
    // {
    //   caption: 'Анализ выполнения работ',
    //   dataField: 'showSumValues',
    //   headerCellTemplate: 'sumValueHeaderTemplate',
    //   visible: false,
    //   allowEditing: false,
    //   allowFiltering: false,
    //   allowEdit: false,
    //   reload: true,
    //   columns: [
    {
      caption: 'Генподрядчик',
      dataField: 'contractor',
      dataType: 'string',
      width: 250,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    },
    {
      caption: 'Субподрядчик',
      dataField: 'subContractor',
      dataType: 'string',
      width: 250,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    },
    {
      caption: 'Статус ПД',
      cellTemplate: 'ssrStatus',
      dataField: 'pdStatus',
      dataType: 'string',
      width: 130,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    },
    {
      caption: 'Статус РД',
      cellTemplate: 'ssrStatus',
      dataField: 'rdStatus',
      dataType: 'string',
      width: 130,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    },
    {
      caption: 'Статус ССР',
      cellTemplate: 'ssrStatus',
      dataField: 'ssrStatus',
      dataType: 'string',
      width: 130,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    }, {
      caption: 'Согласование ССР',
      dataField: 'ssrExecutor',
      dataType: 'string',
      width: 150,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    }, {
      caption: 'Сметная стоимость ССР',
      dataField: 'ssrTotal',
      dataType: 'number',
      format: '###,###,###,###,##0.###',
      width: 120,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    }, {
      caption: 'Заявка на МТР',
      dataField: 'queryMaterial',
      dataType: 'string',
      cellTemplate: 'queryMaterial',
      width: 200,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    }, {
      caption: '1. Работы выполнены, нет осмотров',
      dataField: 'sumKs6a',
      dataType: 'number',
      format: '###,###,###,###,##0.###',
      width: 120,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    }, {
      caption: '2. Осмотры созданы, но не подтверждены',
      dataField: 'sumPlanned',
      dataType: 'number',
      format: '###,###,###,###,##0.###',
      width: 120,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    }, {
      caption: 'Ответственный по осмотрам',
      dataField: 'inspectionPerson',
      dataType: 'string',
      width: 120,
      allowEditing: false,
      allowSearch: false,
      visible: false
    }, {
      caption: '3. Осмотры подтверждены, нет актов',
      dataField: 'sumInspection',
      dataType: 'number',
      format: '###,###,###,###,##0.###',
      width: 120,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    },
    {
      caption: '4. ∑ по созданным актам',
      dataField: 'statusCreated',
      dataType: 'number',
      format: '###,###,###,###,##0.###',
      width: 120,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    }, {
      caption: '5. ∑ по согласованным актам',
      dataField: 'statusAgr',
      dataType: 'number',
      format: '###,###,###,###,##0.###',
      width: 120,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    }, {
      caption: '6. ∑ по отправленым в СБИС актам ',
      dataField: 'statusSentSbis',
      dataType: 'number',
      format: '###,###,###,###,##0.###',
      width: 120,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    }, {
      caption: '7. ∑ по подписанным в СБИС актам',
      dataField: 'statusSignSbis',
      dataType: 'number',
      format: '###,###,###,###,##0.###',
      width: 120,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    }, {
      caption: '8. ∑ по утвержденным актам',
      dataField: 'statusApproved',
      dataType: 'number',
      format: '###,###,###,###,##0.###',
      width: 120,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    }, {
      caption: '9. Наличие КС-14',
      dataField: 'hasCompleted',
      dataType: 'boolean',
      width: 100,
      allowEditing: false,
      allowSearch: false,
      reload: true,
      visible: false,
      analyse: true
    }, {
      caption: 'Признак "Проблемный"',
      dataField: 'problemDate',
      dataType: 'boolean',
      visible: true,
      allowEditing: false,
      width: 65
    }, {
      caption: 'Причина проблемности объекта',
      dataField: 'problemDescription',
      dataType: 'string',
      visible: true,
      allowEditing: false
    }
    //   ]
    // }
  ];

  methodProps = {
    objectCurator: {
      fieldKey: 'id',
      url: '/api/object/curator',
      methods: ['get', 'put', 'post', 'delete']
    },
    objectCustomer: {
      fieldKey: 'id',
      url: '/api/object/customer/list'
    },
    objectVoltageGroup: {
      fieldKey: 'id',
      url: '/api/object/voltage-group/list'
    }
  };

  private checkBoxValueSubject = new BehaviorSubject<boolean>(false);
  checkBoxValue$ = this.checkBoxValueSubject.asObservable();

  private reloadHeaderSubject = new Subject<string>();
  reloadObject$ = this.reloadHeaderSubject.asObservable();

  constructor(private http: HttpClient) {
  }

  setHttpParams(params, queryParams) {
    if (queryParams.take) params = params.append('take', queryParams.take.toString());
    if (queryParams.skip) params = params.append('skip', queryParams.skip.toString());
    if (queryParams.filter) params = params.append('filter', JSON.stringify(queryParams.filter));
    if (queryParams.sort) params = params.append('sort', JSON.stringify(queryParams.sort));
    if (queryParams.group) params = params.set('group', JSON.stringify(queryParams.group));
    return params;
  }

  public async getAsyncObject(id: number): Promise<any> {
    const params = new HttpParams()
      .set('id', String(id));
    return await this.http.get<any>(`${api}/object`, {params}).toPromise();
  }

  public getObject(id: number): Observable<any> {
    let params = new HttpParams()
      .set('id', String(id));
    return this.http.get<any>(`${api}/object`, {params});
  }

  public getSubobject(id: number, param?: string, investElIp?): Observable<any> {
    let params = new HttpParams()
      .set('id', String(id));
    if (param && param === 'v') {
      params = params.append('v', '1');
    }
    if (investElIp) params = params.append('investElIp', investElIp);
    return this.http.get<any>(`${api}/object/sub`, {params});
  }

  public checkActs(id: number): Observable<any> {
    let params = new HttpParams()
      .set('id', String(id));
    return this.http.get<any>(`${api}/object/sub/check-acts`, {params});
  }

  public getCharacters(objectId: number): Observable<any> {
    const params = new HttpParams()
      .set('id', String(objectId));
    return this.http.get<any>(`${api}/object/character/aggregated`, {params});
  }

  public getSubobjects(objectId: number, take?: number, skip?: number, filter?: string, sort?: string, select?, full?, sum?, subobjectId?, hideCancelled?, defaultSet?, isFact?, investElIp?, isSubobjectCard?): Observable<any> {
    let httpParams = new HttpParams().set('objectId', String(objectId));
    if (subobjectId)
      httpParams = httpParams.append('subobjectId', subobjectId);
    if (take) {
      httpParams = httpParams.append('take', take.toString());
    }
    if (skip) {
      httpParams = httpParams.append('skip', skip.toString());
    }
    httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    if (sort) {
      httpParams = httpParams.append('sort', JSON.stringify(sort));
    }
    if (select) httpParams = httpParams.append('select', select);
    if (full) httpParams = httpParams.append('full', full);
    if (sum) httpParams = httpParams.append('sum', sum);
    if (hideCancelled) httpParams = httpParams.append('hideCancelled', hideCancelled);
    if (defaultSet) httpParams = httpParams.append('defaultSet', defaultSet);
    if (isFact) httpParams = httpParams.append('isFact', isFact);
    if (investElIp) httpParams = httpParams.append('investElIp', investElIp);
    if (isSubobjectCard) httpParams = httpParams.append('isSubobjectCard', isSubobjectCard);
    return this.http.get<any>(`${api}/object/sub/list`, {params: httpParams});
  }

  public async getSubobjectEnterCurrentNzs(subobjectId, objectId?): Promise<any> {
    let params = new HttpParams();
    params = params.append('subobjectId', String(subobjectId));
    if (objectId) {
      params = params.append('objectId', objectId);
    }
    return await this.http.get<any>(`${api}/object/sub/enter/current-nzs`, {params}).toPromise();
  }

  public async getSubobjectEnterOksUksSum(subobjectId: number): Promise<any> {
    const params = new HttpParams()
      .set('subobjectId', String(subobjectId));
    return await this.http.get<any>(`${api}/object/sub/enter/oks-uks-sum`, {params}).toPromise();
  }

  public async getSubobjectEnterAktSum(subobjectId: number): Promise<any> {
    const params = new HttpParams()
      .set('subobjectId', String(subobjectId));
    return await this.http.get<any>(`${api}/object/sub/enter/akt-sum`, {params}).toPromise();
  }

  public checkParusErr(objectId: number): Observable<any> {
    const params = new HttpParams()
      .set('objectId', String(objectId));
    return this.http.get<any>(`${api}/object/check-parus-err`, {params});
  }

  public getObjectsSubobjectList(key?: number, take?: number, skip?: number, filter?: string, sort?: string, group?: string, approved?: boolean): Observable<any> {
    let httpParams = new HttpParams();
    if (key) {
      httpParams = httpParams.append('key', key.toString());
    }
    if (take) {
      httpParams = httpParams.append('take', take.toString());
    }
    if (skip) {
      httpParams = httpParams.append('skip', skip.toString());
    }
    if (filter) {
      httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    }
    if (sort) {
      httpParams = httpParams.append('sort', JSON.stringify(sort));
    }
    if (group) {
      httpParams = httpParams.set('group', JSON.stringify(group));
    }

    httpParams = httpParams.append('approved', approved.toString());

    return this.http.get<any>(`${api}/object/obj-sub/list`, {params: httpParams});
  }

  public deleteSubojects(ids: any[]) {
    let urlParams = new HttpParams();
    ids.forEach(x => urlParams = urlParams.append('id', x));
    return this.http.delete(`${api}/object/sub`, {params: urlParams, responseType: 'text' as 'json'});
  }

  public getSubobjectsList(take?: number, skip?: number, filter?: string, approved?: boolean, sort?: string, group?: string): Observable<any> {
    let httpParams = new HttpParams();
    if (take) {
      httpParams = httpParams.append('take', take.toString());
    }
    if (skip) {
      httpParams = httpParams.append('skip', skip.toString());
    }
    httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    if (sort) {
      httpParams = httpParams.append('sort', JSON.stringify(sort));
    }
    if (group) {
      httpParams = httpParams.set('group', JSON.stringify(group));
    }
    httpParams = httpParams.append('approved', approved.toString());
    return this.http.get<any>(`${api}/object/subobjects/list`, {params: httpParams});
  }

  public putObjectsSubobjectList(body) {
    return this.http.put<any>(`${api}/object/obj-sub/list`, body);
  }

  public saveSubobjects(sub) {
    return this.http.put<any>(`${api}/object/sub`, sub);
  }

  public saveSubobjectsObjects(sub) {
    return this.http.put<any>(`${api}/object/obj-sub/list`, sub);
  }

  public getSubobjectCharacters(subobjectId: number, documentId: number): Observable<any> {
    const params = new HttpParams()
      .set('subobjectId', String(subobjectId))
      .set('documentId', String(documentId));
    return this.http.get<any>(`${api}/object/sub/set`, {params});
  }

  public newSubobjectCharacters(characters) {
    return this.http.post<any>(`${api}/object/sub/set`, characters);
  }

  public saveSubobjectCharacters(characters) {
    return this.http.put<any>(`${api}/object/sub/characters`, characters);
  }

  public getAssetsList(take?: number, skip?: number, filter?: string, sort?: string, onlyInvNum?) {
    let httpParams = new HttpParams();
    if (take) {
      httpParams = httpParams.append('take', take.toString());
    }
    if (skip) {
      httpParams = httpParams.append('skip', skip.toString());
    }
    httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    if (sort) {
      httpParams = httpParams.append('sort', JSON.stringify(sort));
    }
    if (onlyInvNum)
      httpParams = httpParams.append('onlyInvNum', 'true');
    return this.http.get<any>(`${api}/character/assets/list`, {params: httpParams});
  }

  public getInvResList(take?: number, skip?: number, filter?: string, sort?: string,fakeTypeSubId?: number) {
    let httpParams = new HttpParams();
    if (take) {
      httpParams = httpParams.append('take', take.toString());
    }
    if (skip) {
      httpParams = httpParams.append('skip', skip.toString());
    }
    httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    if (sort) {
      httpParams = httpParams.append('sort', JSON.stringify(sort));
    }
    if (fakeTypeSubId)
      httpParams = httpParams.append('fakeTypeSubId', fakeTypeSubId.toString());
    return this.http.get<any>(`${api}/object/inv-res/list`, {params: httpParams});
  }

  public getSubobjectStou(take?: number, skip?: number, filter?: string, sort?: string, lookup?: boolean, key?: number) {
    let httpParams = new HttpParams();
    if (take) {
      httpParams = httpParams.append('take', take.toString());
    }
    if (skip) {
      httpParams = httpParams.append('skip', skip.toString());
    }
    httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    if (sort) {
      httpParams = httpParams.append('sort', JSON.stringify(sort));
    }
    if (lookup) {
      httpParams = httpParams.set('lookup', lookup.toString());
    }
    if (key) {
      httpParams = httpParams.set('id', key.toString());
    }
    httpParams = httpParams.set('requireTotalCount', true.toString());
    return this.http.get<any>(`${api}/object/sub/stou/list`, {params: httpParams});
  }

  public getObjectHierarchy(objectId, contractId, level, take?, skip?, sort?, filter?, typeLink?, linkDocumentId?, typeId?): Observable<any> {
    let params = new HttpParams();
    if (objectId)
      params = params.append('objectId', String(objectId));
    if (contractId)
      params = params.append('contractId', String(contractId));
    if (level)
      params = params.append('level', String(level));
    if (take)
      params = params.append('take', take);
    if (skip)
      params = params.append('skip', skip);
    if (sort) params = params.append('sort', JSON.stringify(sort));
    params = params.append('filter', JSON.stringify(filter) || '[]');
    if (linkDocumentId) params = params.append('linkDocumentId', linkDocumentId);
    if (typeId) params = params.append('typeId', String(typeId));
    return this.http.get<any>(`${api}/${typeLink === 'title' && 'invest' || 'object'}/hierarchy`, {params});
  }

  public getTitleInfo(id: number, investElIp?): Observable<any> {
    let params = new HttpParams()
      .set('id', String(id));
    if (investElIp) {
      params = params.append('investElIp', investElIp);
    }
    return this.http.get<any>(`${api}/object/title`, {params});
  }

  public getSets(options?): Observable<any> {
    let httpParams = new HttpParams();
    if (options)
      httpParams = this.setHttpParams(httpParams, options);
    return this.http.get<any>(`${api}/character/set/list`, {params: httpParams});
  }

  public getCostItems(): Observable<any> {
    return this.http.get<any>(`${api}/plan/expenses/list`);
  }

  public getObjectKinds(typeId: number, kindId = null): Observable<any> {
    let params = new HttpParams();
    params = params.set('typeId', String(typeId));
    if (kindId) {
      params = params.append('kindId', kindId);
    }
    return this.http.get<any>(`${api}/object/kind/list`, {params});
  }

  public getObjectGroupedTypes(objectKindId: number): Observable<any> {
    const params = new HttpParams().set('objectKindId', String(objectKindId));
    return this.http.get<any>(`${api}/object/type/list`, {params});
  }

  public getCompanyList(): Observable<any> {
    return this.http.get<any>(`${api}/object/company/list`);
  }

  public getDocuments(objectId: number, form = 'subobject', typeLink = 'object', titulId?): Observable<any> {
    let params = new HttpParams();
    params = params.append('typeLink', String(typeLink));
    if (objectId) {
      params = params.append('objectId', String(objectId));
    }
    if (titulId) {
      params = params.append('titulId', String(titulId));
    }
    return this.http.get<any>(`${api}/document/list?form=${form}`, {params});
  }

  public getDefaultCharacter(id: number, documentId?): Observable<any> {
    let params = new HttpParams();
    if (id) {
      params = params.append('id', String(id));
    }
    if (documentId) params = params.append('documentId', documentId);
    return this.http.get<any>(`${api}/character/default`, {params});
  }

  public getFixedAssetsGroupList(): Observable<any> {
    return this.http.get<any>(`${api}/object/assets-group/list`);
  }

  public getVoltageClassList(options?): Observable<any> {
    let params = new HttpParams();
    if (options) { params = this.setHttpParams(params, options); }
    return this.http.get<any>(`${api}/object/voltage/list`, {params});
  }

  // 1 Вид инвестиционного бюджета
  public getInvestmentBudgetKindList(): Observable<any> {
    return this.http.get<any>(`${api}/object/budget-kind/list`);
  }

  // 2 Заказчик
  public getCustomerList(budgetKindId?: number, customerId?: number, contractNr?: boolean): Observable<any> {
    let params = new HttpParams();
    if (budgetKindId) {
      params = params.append('budgetKindId', String(budgetKindId));
    }
    if (customerId) {
      params = params.append('customerId', String(customerId));
    }
    if (contractNr) {
      params = params.append('contractNr', contractNr.toString());
    }
    return this.http.get<any>(`${api}/object/customer/list`, {params});
  }

  // 3 Группа объектов по уровням напряжения и видам
  public getVoltageGroupList(budgetKindId?: number, customerId?: number, voltageGroupId?: number, contractNr?: boolean): Observable<any> {
    let params = new HttpParams();
    if (budgetKindId) {
      params = params.append('budgetKindId', String(budgetKindId));
    }
    if (customerId) {
      params = params.append('customerId', String(customerId));
    }
    if (voltageGroupId) {
      params = params.append('voltageGroupId', String(voltageGroupId));
    }
    if (contractNr) {
      params = params.append('contractNr', contractNr.toString());
    }
    return this.http.get<any>(`${api}/object/voltage-group/list`, {params});
  }

  // 4 Источник финансирования
  public getFinSourceList(budgetKindId?: number, customerId?: number, voltageGroupId?: number, financingSourceId?: number): Observable<any> {
    let params = new HttpParams();
    if (budgetKindId) {
      params = params.append('budgetKindId', String(budgetKindId));
    }
    if (customerId) {
      params = params.append('customerId', String(customerId));
    }
    if (voltageGroupId) {
      params = params.append('voltageGroupId', String(voltageGroupId));
    }
    if (financingSourceId) {
      params = params.append('financingSourceId', String(financingSourceId));
    }
    return this.http.get<any>(`${api}/object/fin-source/list`, {params});
  }

  public async isFinSourceValid(budgetKindId?: number, customerId?: number, voltageGroupId?: number, financingSourceId?: number): Promise<any> {
    let params = new HttpParams();
    if (budgetKindId) {
      params = params.append('budgetKindId', String(budgetKindId));
    }
    if (customerId) {
      params = params.append('customerId', String(customerId));
    }
    if (voltageGroupId) {
      params = params.append('voltageGroupId', String(voltageGroupId));
    }
    if (financingSourceId) {
      params = params.append('financingSourceId', String(financingSourceId));
    }
    return await this.http.get<any>(`${api}/object/fin-source/valid-values`, {params}).toPromise();
  }

  public getWorkStatusList(): Observable<any> {
    return this.http.get<any>(`${api}/object/status/list`);
  }

  public getWorkStateList(tp?: boolean): Observable<any> {
    let params = new HttpParams();
    if (tp) {
      params = params.append('tp', String(tp));
    }

    return this.http.get<any>(`${api}/object/work-state/list`, {params});
  }

  public checkUniqueCode(field: string, value: string): Observable<any> {
    const params = new HttpParams()
      .set('field', field)
      .set('value', value);
    return this.http.get<any>(`${api}/object/obj-code/check`, {params});
  }

  public saveWorkState(workState): Observable<any> {
    return this.http.post<any>(`${api}/object/work-state`, {workState});
  }

  public getCableRoutingMethodList(): Observable<any> {
    return this.http.get<any>(`${api}/object/cable-route/list`);
  }

  public getJobKindList(id): Observable<any> {
    let params = new HttpParams();
    if (id) {
      params = params.append('id', id);
    }
    return this.http.get<any>(`${api}/object/job-kind/list`, {params});
  }

  public getHeadObjectTypeList(): Observable<any> {
    return this.http.get<any>(`${api}/object/head-object-type/list`);
  }

  public getHeadObjectList(): Observable<any> {
    return this.http.get<any>(`${api}/object/head/list`);
  }

  public getObjects(parentId?, filter?: string, take?, skip?, sort?: any, group?: any, tpobjects?, totalSubSums?, customFilter?, visibleSum?, sumDateStart?, sumDateEnd?, columns?, showTree?, endDateStart?, endDateEnd?): Observable<any> {
    let httpParams = new HttpParams();
    if (tpobjects) {
      httpParams = httpParams.append('tpobjects', tpobjects);
    }
    if (totalSubSums) {
      httpParams = httpParams.append('totalSubSums', totalSubSums);
    }
    if (customFilter) {
      httpParams = httpParams.append('customFilter', JSON.stringify(customFilter) || '[]');
    }
    httpParams = httpParams.append('filter', filter);
    if (sort) {
      httpParams = httpParams.append('sort', JSON.stringify(sort));
    }

    if (visibleSum) {
      httpParams = httpParams.set('sum', 'true');
    }
    if (sumDateStart) {
      httpParams = httpParams.set('sumDateStart', `01.${('0' + (sumDateStart.getMonth() + 1)).substr(-2)}.${sumDateStart.getFullYear()}`);
    }
    if (sumDateEnd) {
      httpParams = httpParams.set('sumDateEnd', `01.${('0' + (sumDateEnd.getMonth() + 1)).substr(-2)}.${sumDateEnd.getFullYear()}`);
    }
    if (columns) {
      columns.forEach(x => httpParams = httpParams.append('column', x));
    }
    if (showTree) {
      httpParams = httpParams.set('showTree', 'true');
    }
    if (endDateStart) {
      httpParams = httpParams.set('endDateStart', `01.${('0' + (endDateStart.getMonth() + 1)).substr(-2)}.${endDateStart.getFullYear()}`);
    }
    if (endDateEnd) {
      let _lastDate = new Date(endDateEnd.getFullYear(), endDateEnd.getMonth() + 1, 0);
      httpParams = httpParams.set('endDateEnd', `${('0' + _lastDate.getDate()).substr(-2)}.${('0' + (_lastDate.getMonth() + 1)).substr(-2)}.${_lastDate.getFullYear()}`);
    }

    this.objectExportUrl = `api/object/export/create?parentId=null&` + httpParams;

    if (parentId) {
      httpParams = httpParams.append('parentId', parentId);
    }
    if (take) {
      httpParams = httpParams.append('take', take.toString());
    }
    if (skip) {
      httpParams = httpParams.append('skip', skip.toString());
    }
    if (group) {
      httpParams = httpParams.set('group', JSON.stringify(group));
    }

    //return this.http.get<any>(`${api}/object/list?${filter ? filter : ''}`, {params: httpParams});
    return this.http.get<any>(`${api}/object/list`, {params: httpParams});
  }

  public getObjectsByContragent(gencontractorId, filter?, take?, skip?, connectionId?): Observable<any> {
    let httpParams = new HttpParams();
    if (filter) {
      // httpParams = httpParams.append('filter', filter);
      httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    }
    if (take) {
      httpParams = httpParams.append('take', take.toString());
    }
    if (skip) {
      httpParams = httpParams.append('skip', skip.toString());
    }
    httpParams = httpParams.append('gencontractorId', gencontractorId);
    if (connectionId) {
      httpParams = httpParams.append('connectionId', connectionId);
    }
    return this.http.get<any>(`${api}/object/contragent/list`, {params: httpParams});
  }

  public getGenObjects(contragentId: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('contragentId', contragentId);
    return this.http.get<any>('/api/admin/gen-objects', {params: httpParams});
  }

  public saveObject(id, data) {
    if (id) {
      return this.http.put<any>(`${api}/object`, data);
    } else {
      return this.http.post<any>(`${api}/object`, data);
    }
  }

  public deleteObject(id) {
    const params = new HttpParams().set('id', String(id));
    return this.http.delete<any>(`${api}/object`, {params});
  }

  public createNewVersion(id) {
    // const params = new HttpParams().set('id', String(id));
    return this.http.post<any>(`${api}/object/new-version`, {id});
  }

  /**
   * Ищет объекты по данному тексту
   * @param text
   */
  public searchObjects(text: string): Observable<any> {
    const params = new HttpParams()
      .set('text', String(text));
    return this.http.get<any>(`${api}/object/search`, {params});
  }

  public getSubobjectEnter(objectId: number): Observable<any> {
    const params = new HttpParams()
      .set('objectId', String(objectId));
    return this.http.get<any>(`${api}/object/sub/enter/list`, {params});
  }

  public async saveSubobjectEnter(id, data): Promise<any> {
    if (id) {
      return await this.http.put<any>(`${api}/object/sub/enter`, data).toPromise();
    } else {
      return await this.http.post<any>(`${api}/object/sub/enter`, data).toPromise();
    }
  }

  public getEnterCharacters(id: number, year: number, month: number): Observable<any> {
    const httpParams = new HttpParams()
      .set('id', String(id))
      .set('year', String(year))
      .set('month', String(month));
    return this.http.get<any>(`${api}/enter/characters`, {params: httpParams});
  }

  public saveEnterCharacters(ch) {
    return this.http.put<any>(`${api}/enter/characters`, ch);
  }

  public resetFactCharacters(id) {
    return this.http.put<any>(`${api}/enter/characters/reset`, {id});
  }

  public async getDictionaryValueList(dictionaryId: number): Promise<any> {
    const params = new HttpParams()
      .set('dictionaryId', String(dictionaryId));
    return await this.http.get<any>(`${api}/document/dictionary/value/list`, {params}).toPromise();
  }

  public getCharacterUnits() {
    return this.http.get<any>(`${api}/character/unit/list`);
  }

  public putCharacterUnit(id, body) {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.put<any>(`${api}/character/unit`, body, {params});
  }

  public postCharacterUnit(body) {
    return this.http.post<any>(`${api}/character/unit`, body);
  }

  public deleteCharacterUnit(id) {
    let params = new HttpParams();
    id.forEach(x => params = params.append('id', x));
    return this.http.delete(`${api}/character/unit`, {params});
  }

  public getUnitConversionList(params?, mdm?) {
    if (mdm) {
      params = params.append('mdmId', mdm);
      return this.http.get<any>(`${api}/character/unit/conversion/list`, {params});
    } else {
      return this.http.get<any>(`${api}/character/unit/conversion/list`, {params});
    }
  }

  public postUnitConversion(body, mdmId?) {
    if (mdmId) {
      body.nomenclatureId = mdmId;
    }
    return this.http.post<any>(`${api}/character/unit/conversion`, body);
  }

  public putUnitConversion(id, body, mdmId?) {
    let params = new HttpParams();
    if (mdmId) {
      body.nomenclatureId = mdmId;
    }
    params = params.append('id', id);
    return this.http.put<any>(`${api}/character/unit/conversion`, body, {params});
  }

  public deleteUnitConversion(id) {
    let params = new HttpParams();
    id.forEach(x => params = params.append('id', x));
    return this.http.delete(`${api}/character/unit/conversion`, {params});
  }

  public getPhysParamsList() {
    return this.http.get<any>(`${api}/character/physparam/list`);
  }

  public async getWorkType(id: number): Promise<any> {
    const params = new HttpParams().set('id', String(id));
    return await this.http.get<any>(`${api}/object`, {params}).toPromise();
  }

  public getDocumentMandatory(objectTypeId): Observable<any> {
    const params = new HttpParams()
      .set('objectTypeId', String(objectTypeId));
    return this.http.get<any>(`${api}/document/mandatory/list`, {params});
  }

  public saveDocumentMandatory(mandatory) {
    return this.http.put<any>(`${api}/document/mandatory`, mandatory);
  }

  public getCharacterObjectTypes(take?: number, skip?: number, filter?: string, sort?: string, id?: number): Observable<any> {
    let httpParams = new HttpParams();
    if (take) {
      httpParams = httpParams.append('take', take.toString());
    }
    if (skip) {
      httpParams = httpParams.append('skip', skip.toString());
    }
    httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    if (sort) {
      httpParams = httpParams.append('sort', JSON.stringify(sort));
    }
    if (id) {
      httpParams = httpParams.append('id', JSON.stringify(id));
    }
    return this.http.get<any>(`${api}/object/type/list`, {params: httpParams});
  }

  public createCharacterObjectTypes(data) {
    return this.http.post<any>(`${api}/object/type`, data);
  }

  public updateCharacterObjectTypes(data) {
    return this.http.put<any>(`${api}/object/type`, data);
  }

  public updateFakeSubobjectType(id, data) {
    let params = new HttpParams();
    if (id) {
      params = params.append('id', id);
    }
    return this.http.put<any>(`${api}/object/fake-subobject-type`, data, {params});
  }

  public addInvRes(data) {
    return this.http.post<any>(`${api}/object/inv-res`, data);
  }

  public deleteInvRes(data) {
    let params = new HttpParams();
    data.forEach(x => params = params.append('id', x));
    return this.http.delete<any>(`${api}/object/inv-res`, {params});
  }

  public deleteFakeSubobjectType(id) {
    const ids = Array.isArray(id) ? id : [id];
    let params = new HttpParams();
    ids.forEach(x => {
      params = params.append('id', x);
    });
    return this.http.delete<any>(`${api}/object/fake-subobject-type`, {params});
  }

  public createFakeObjectTypes(data) {
    return this.http.post<any>(`${api}/object/fake-subobject-type`, data);
  }

  public deleteCharacterObjectTypes(id: number) {
    const params = new HttpParams().set('id', String(id));
    return this.http.delete(`${api}/object/type`, {params});
  }

  public async getAsyncCharacterObjectTypeGroup(): Promise<any> {
    return await this.http.get<any>(`${api}/character/object-type-group/list`).toPromise();
  }

  public getCharacterObjectTypeGroup() {
    return this.http.get<any>(`${api}/character/object-type-group/list`);
  }

  public async getAsyncCharacterObjectKind(): Promise<any> {
    return await this.http.get<any>(`${api}/character/object-kind/list`).toPromise();
  }

  public getCharacterObjectKind() {
    return this.http.get<any>(`${api}/character/object-kind/list`);
  }

  getCharacterList(parentIds?, filter?: string, take?, skip?, tree?, actual?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    if (parentIds) {
      httpParams = httpParams.append('parentIds', parentIds);
    }
    if (take) {
      httpParams = httpParams.append('take', take);
    }
    if (skip) {
      httpParams = httpParams.append('skip', skip);
    }
    if (tree)
      httpParams = httpParams.append('tree', tree);
    if (actual)
      httpParams = httpParams.append('actual', actual);

    return this.http.get<Array<any>>(`${api}/character/character/list`, {params: httpParams});
  }

  public postCharacter(body) {
    return this.http.post<any>(`${api}/character/character`, body);
  }

  public putCharacter(values) {
    return this.http.put<any>(`${api}/character/character`, values);
  }

  public deleteCharacter(id) {
    let params = new HttpParams();
    id.forEach(x => {
      params = params.append('id', x);
    });
    return this.http.delete<any>(`${api}/character/character`, {params});
  }

  public getContracts(objectId, take, skip, filter, last = false, contractNR = false, filialId?, old = false, skipObject?, simple?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('objectId', objectId);
    httpParams = httpParams.append('filter', JSON.stringify(filter) || '[]');
    if (take) {
      httpParams = httpParams.append('take', take.toString());
    }
    if (skip) {
      httpParams = httpParams.append('skip', skip.toString());
    }
    if (last) {
      httpParams = httpParams.append('last', 'true');
    }
    if (contractNR) {
      httpParams = httpParams.append('contractNR', 'true');
    }
    if (filialId) {
      httpParams = httpParams.append('filialId', filialId);
    }
    if (simple)
      httpParams = httpParams.append('simple', 'true');
    httpParams = httpParams.append('old', old.toString());
    httpParams = httpParams.append('skipObject', '1');
    return this.http.get<any>(`api/contract/list`, {params: httpParams});
  }

  public getConnectionWorksByObjectId(objectId: number): Observable<any> {
    const params = new HttpParams()
      .set('id', String(objectId));
    return this.http.get<any>(`${api}/object/connection-work`, {params});
  }

  public async getAsyncConnectionWorksByObjectId(objectId: number): Promise<any> {
    const params = new HttpParams()
      .set('id', String(objectId));
    return await this.http.get<any>(`${api}/object/connection-work`, {params}).toPromise();
  }

  public postSendSailObject(obj) {
    return this.http.post<any>(`${api}/object/send-sail-object`, obj);
  }

  public postSendSailSubObject(obj) {
    return this.http.post<any>(`${api}/object/send-sail-subobject`, obj);
  }

  public getYear(year: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('year', year);
    return this.http.get<any>('api/plan/fact-close-period/getClosed', {params: httpParams});
  }

  public async getClosedInfo(year: number): Promise<any> {
    const params = new HttpParams()
      .set('year', String(year));
    return await this.http.get<any>(`${api}/plan/fact-close-period/getClosed`, {params}).toPromise();
  }

  getDocumentMandatoryCheck(objectId, check): Observable<any> {
    const params = new HttpParams()
      .set('objectId', String(objectId))
      .set('check', check);
    return this.http.get<Array<any>>(`${api}/document/mandatory/check`, {params});
  }

  postEnterCurrentNzs(body) {
    return this.http.post(`${api}/object/sub/enter/current-nzs`, body);
  }

  putEnterCurrentNzs(key, body) {
    let params = new HttpParams()
      .set('id', key);
    return this.http.put(`${api}/object/sub/enter/current-nzs`, body, {params});
  }

  deleteEnterCurrentNzs(id) {
    const ids = Array.isArray(id) ? id : [id];
    let params = new HttpParams();
    id.forEach(x => params = params.append('id', id));
    return this.http.delete(`${api}/object/sub/enter/current-nzs`, {params});
  }

  getFakeSubobjectObjectList(id?, subList?) {
    let params = new HttpParams();
    if (id) {
      params = params.append('objectTypeId', id);
    }
    if (subList) {
      params = params.append('subList', 'true');
    }
    return this.http.get<any>(`${api}/object/fake-subobject-type/list`, {params});
  }

  getSubobjectTypeList(params?) {
    return this.http.get<any>(`${api}/object/sub/type/list`, {params});
  }

  public async putObjectChecked(id, checked): Promise<any> {
    return await this.http.put<any>(`${api}/object/checked`, {id, checked}).toPromise();
  }

  public async putObjectManual(id, manual): Promise<any> {
    return await this.http.put<any>(`${api}/object/manual`, {id, manual}).toPromise();
  }

  public async putObjectArchiveMaterial(id, checked): Promise<any> {
    return await this.http.put<any>(`${api}/object/archive-material`, {id, checked}).toPromise();
  }

  public async putObjectProblem(id, problem): Promise<any> {
    return await this.http.put<any>(`${api}/object/problem`, {id, problem}).toPromise();
  }

  public getDepartmentList(typeId?, take?: number, skip?: number, filter?: string, sort?: string, onlyResName?, checkFilialRight?) {
    let params = new HttpParams();
    if (typeId) {
      params = params.append('typeId', String(typeId));
    }
    if (take) {
      params = params.append('take', take.toString());
    }
    if (skip) {
      params = params.append('skip', skip.toString());
    }
    params = params.append('filter', JSON.stringify(filter) || '[]');
    if (sort) {
      params = params.append('sort', JSON.stringify(sort));
    }
    params = params.append('grid', String(true));
    if(onlyResName) params = params.append('onlyResName', 'true');
    if (checkFilialRight) {
      params = params.append('checkFilialRight', 'true');
    }
    return this.http.get<any>(`${api}/organization/departments`, {params});
  }

  public getClearTimeReport(filter) {
    let params = new HttpParams();
    params = params.append('filialId', String(filter.filialId))
      .append('contractId', String(filter.contractId));
    if (filter.startDate) {
      params = params.append('startDate', String(filter.startDate));
    }
    if (filter.endDate) {
      params = params.append('endDate', String(filter.endDate));
    }
    return this.http.get<any>(`${api}/report/tp-contract-execution`, {params});
  }

  public getConnectionContractList(options) {
    let params = new HttpParams();
    if (options.take) {
      params = params.append('take', options.take.toString());
    }
    if (options.skip) {
      params = params.append('skip', options.skip.toString());
    }
    if (options.filter) {
      params = params.append('filter', JSON.stringify(options.filter));
    }
    if (options.sort) {
      params = params.append('sort', JSON.stringify(options.sort));
    }
    return this.http.get<any>(`${api}/contract/connection-contract/list`, {params});

  }

  public getWorkWayList() {
    return this.http.get<any>(`${api}/character/work-way/list`);
  }


  public async getObjectData(url): Promise<any> {
    return await this.http.get<any>(url).toPromise();
  }

  public async copySubobjectCharacters(id): Promise<any> {
    return await this.http.post<any>('api/enter/characters/copy', {id}).toPromise();
  }

  public async getAsyncObjectDates(id: number): Promise<any> {
    const params = new HttpParams().set('id', String(id));
    return await this.http.get<any>(`/api/object/plan/date`, {params}).toPromise();
  }

  public async getSubobjectWorkType(ids): Promise<any> {
    let params = new HttpParams();
    ids.forEach(x => params = params.append('id', x));
    return this.http.get<any>(`/api/object/sub/type/work`, {params}).toPromise();
  }

  public getCoef(options, year, month, comparison, subObjCh?) {
    let params = new HttpParams();
    if (options)
      params = this.setHttpParams(params, options);
    if (year) {
      params = params.append('year', String(year));
    };
    if (month != undefined) {
      params = params.append('month', String(month));
    }
    if (comparison) {
      params = params.append('comparison', String(comparison));
    };
    if (subObjCh) {
      params = params.append('subObjCh', String(subObjCh));
    };
    return this.http.get<any>(`${api}/object/coef-calc/list`, {params});
  }

  public getSubObjList(invNumber, stouId, options, year, month, comparison, subObjCh) {
    let params = new HttpParams();
    if (options)
      params = this.setHttpParams(params, options);
    params = params.append('invNumber', String(invNumber));
    params = params.append('stouId', String(stouId));
    if (year) {
      params = params.append('year', String(year));
    };
    if (month != undefined) {
      params = params.append('month', String(month));
    };
    if (comparison) {
      params = params.append('comparison', String(comparison));
    };
    if (subObjCh) {
      params = params.append('subObjCh', String(subObjCh));
    };
    return this.http.get<any>(`${api}/object/coef-calc/subobj`, {params});
  }

  public putCoefCalculation(data) {
    return this.http.put<any>(`${api}/object/coef-calc/formula`, data);
  }

  public updateSubobjCoefs(year, month) {
    return this.http.put<any>(`${api}/object/coef-calc/subobj/formula`, {year, month});
  }

  public getContractsTP(options, objId) {
    let params = new HttpParams();
    if (options)
      params = this.setHttpParams(params, options);
    if (objId) {
      params = params.append('objId', String(objId));
    }
    return this.http.get<any>(`${api}/object/contracts-tp`, {params});
  }

  public getLinkedInvNumbers(options, subObjId) {
    let params = new HttpParams();
    if (options)
      params = this.setHttpParams(params, options);
    if (subObjId) {
      params = params.append('subObjId', String(subObjId));
    }
    return this.http.get<any>(`${api}/object/linked-inv-numbers`, {params});
  }

  public updateKO(data) {
    return this.http.put<any>(`${api}/object/coef-calc/update`, data);
  }

  getSubobjectWorkTypes(options): Observable<any> {
    let params = new HttpParams();
    params = this.setHttpParams(params, options);
    if (options.short) {
      params = params.append('short', 'true');
    }
    return this.http.get<Array<any>>(`/api/object/subobject/work-type`, {params});
  }

  updateSubobjectWorkTypes(body): Observable<any> {
    return this.http.put<any>(`/api/object/subobject/work-type`, body);
  }

  insertSubobjectWorkTypes(body): Observable<any> {
    return this.http.post<any>(`/api/object/subobject/work-type`, body);
  }

  deleteSubobjectWorkTypes(id): Observable<any> {
    let params = new HttpParams();
    if (Array.isArray(id))
      id.forEach(x => params = params.append('id', x));
    else params = params.append('id', id)
    return this.http.delete<any>(`/api/object/subobject/work-type`, {params});
  }

  public getPeriods(filter?: string) {
    let params = new HttpParams();
    params = params.append('filter', filter ? JSON.stringify(filter) : '[]');
    return this.http.get<any>(`${api}/object/construction-period`, {params});
  }

  periods(type, key, values) {
    let httpParams = new HttpParams();
    switch (type) {
      case 'post':
        return this.http.post<any>(`${api}/object/construction-period`, values);
      case 'put':
        httpParams = httpParams.append('id', key);
        return this.http.put<any>(`${api}/object/construction-period`, values, {params: httpParams});
      case 'delete':
        httpParams = httpParams.append('id', key);
        return this.http.delete(`${api}/object/construction-period`, {params: httpParams, responseType: 'text' as 'json'});
    }
  }

  putVoltageClass(body): Observable<any> {
    return this.http.put<any>(`/api/object/voltage-class`, body);
  }

  postVoltageClass(body): Observable<any> {
    return this.http.post<any>(`/api/object/voltage-class`, body);
  }

  deleteVoltageClass(id): Observable<any> {
    let params = new HttpParams();
    if (Array.isArray(id))
      id.forEach(x => params = params.append('id', x));
    else params = params.append('id', id)
    return this.http.delete<any>(`/api/object/voltage-class`, {params});
  }

  excelExport() {
    window.open(`/api/object/voltage/list/export`);
  }

  getObjectCurator(objectId, filialId, customerId, voltageGroupId): Observable<any> {
    let params = new HttpParams().set('objectId', objectId);
    params = params.append('filialId', filialId);
    params = params.append('customerId', customerId);
    params = params.append('voltageGroupId', voltageGroupId);
    return this.http.get<Array<any>>(`/api/object/curator-value`, {params});
  }

  async getObjectCuratorValue(objectId, curatorId, filialId, customerId, voltageGroupId): Promise<any> {
    return new Promise((resolve) => {
      if (objectId && filialId && customerId && voltageGroupId) {
        this.getObjectCurator(objectId, filialId, customerId, voltageGroupId).toPromise().then(data => {
          if (data && data.length &&
            !data.find(x => x.id === curatorId) && confirm(`Выбрать куратором объекта сотрудника ${data[0].name}?`)) {
            resolve(data[0]);
          } else { resolve(); }
        });
      } else { resolve(); }
    });
  }

  public getCharacterInclusion(queryParams, toId?) {
    let params = new HttpParams();
    if (queryParams)
      params = this.setHttpParams(params, queryParams);
    if (toId)
      params = params.append('toId', toId);
    return this.http.get<any>(`${api}/character/character-inclusion-view/list`, {params});
  }

  characterInclusion(type, key, values) {
    let httpParams = new HttpParams();
    switch (type) {
      case 'post':
        return this.http.post<any>(`${api}/character/character-inclusion`, values);
      case 'put':
        httpParams = httpParams.append('id', key);
        return this.http.put<any>(`${api}/character/character-inclusion`, values, {params: httpParams});
      case 'delete':
        httpParams = httpParams.append('id', key);
        return this.http.delete(`${api}/character/character-inclusion`, {params: httpParams, responseType: 'text' as 'json'});
    }
  }

  public getCoefsYears() {
    return this.http.get<any>(`${api}/object/coef-calc/list/years`);
  }

  setCheckboxValue(value: boolean) {
    this.checkBoxValueSubject.next(value);
  }

  public async saveProblemDescription(id, description): Promise<any> {
    return await this.http.put<any>(`${api}/object/problem/description`, {id, description}).toPromise();
  }

  reloadObject(value?: string) {
    this.reloadHeaderSubject.next(value);
  }

  public getProblemHistory(options, objId) {
    let params = new HttpParams();
    if (options)
      params = this.setHttpParams(params, options);
    if (objId)
      params = params.append('objId', String(objId));
    return this.http.get<any>(`${api}/object/problem/history`, {params});
  }
}
