import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {
  faCopy,
  faHome,
  faLaptopHouse,
  faFolder,
  faSitemap
} from '@fortawesome/free-solid-svg-icons';
import {Subject} from 'rxjs';

const api = '/api/invest';
const urls = {
  yearCode: `${api}/yearcodeipr`,
  finSource: `${api}/finsourceipr`,
  projectGroup: `${api}/projectgroupipr`,
  workType: `${api}/worktypeipr`,
  implementStage: `${api}/implementstageipr`,
  percentSumIpr: `${api}/percentsumipr`,
  municipalAreas: `${api}/municipalareas`,
  type: `${api}/type`,
  limits: `${api}/limits`
};

@Injectable({
  providedIn: 'root'
})
export class InvestService {

  navPageList = [
    {
      id: '',
      parentId: null,
      text: 'ИнвестПроект',
      visible: true,
      disabled: false,
      icon: faHome,
      right: 'investcard',
      url: ``
    }, {
      id: 'elements',
      parentId: null,
      text: 'Элементы ИП',
      visible: true,
      disabled: false,
      icon: faLaptopHouse,
      right: 'investcardelements',
      url: 'elements'
    },
    // {
    //   id: 'graph',
    //   parentId: null,
    //   text: 'График работ',
    //   visible: true,
    //   disabled: false,
    //   icon: faChartBar,
    //   right: 'investcardgraph',
    //   url: `graph`
    // },
    {
      id: 'docs',
      parentId: null,
      text: 'Документы',
      visible: true,
      disabled: false,
      icon: faCopy,
      right: 'investcarddocs',
      child: [{
        id: 'investdoc',
        parentId: null,
        text: 'Документы ИП',
        visible: true,
        disabled: false,
        icon: faCopy,
        right: 'investcardinvestdoc',
        url: `investdoc`
      }, {
        id: 'investcommondoc',
        parentId: null,
        text: 'Общие документы ИП',
        visible: true,
        disabled: false,
        icon: faFolder,
        right: 'investcardinvestcommondoc',
        url: `investcommondoc`
      }, {
        id: 'objectdoc',
        parentId: null,
        text: 'Документы объекта',
        visible: true,
        disabled: false,
        icon: faCopy,
        right: 'investcardobjectdoc',
        url: `objectdoc`
      }, {
        id: 'objectcommondoc',
        parentId: null,
        text: 'Общие документы объекта',
        visible: true,
        disabled: false,
        icon: faFolder,
        right: 'investcardobjectcommondoc',
        url: `objectcommondoc`
      }]
    }, {
      id: 'childs',
      parentId: null,
      text: 'Дочерние ИП',
      visible: false,
      disabled: false,
      icon: faSitemap,
      right: 'investcardchilds',
      url: `childs`
    }
  ];

  columnsList = [
    {
      caption: 'id',
      dataField: 'id',
      visible: false,
      fixed: true,
      fixedPosition: 'left'
    }, {
      caption: 'Идентификатор ИП',
      dataField: 'code',
      width: 220,
      sortOrder: 'asc',
      fixed: true,
      fixedPosition: 'left'
    }, {
      caption: 'Наименование ИП',
      dataField: 'name',
      cellTemplate: 'longText',
      width: 300,
      fixed: true,
      fixedPosition: 'left'
    }, {
      caption: 'Статус',
      dataField: 'status',
      width: 140
    }, {
      caption: 'Версия',
      dataField: 'version',
      width: 100
    }, {
      caption: 'Группа ИП',
      dataField: 'projectGroup',
      cellTemplate: 'longText',
      width: 200
    }, {
      caption: 'Тип ИП',
      dataField: 'type',
      cellTemplate: 'longText',
      width: 200
    }, {
      caption: 'Уровень напряжения (кВ)',
      dataField: 'voltageClass',
      width: 150
    }, {
      caption: 'Филиал',
      dataField: 'filial',
      width: 100
    }, {
      caption: 'Начало реализации ИП',
      dataField: 'planStartDate',
      dataType: 'date',
      format: 'MM.yyyy',
      width: 150
    }, {
      caption: 'Окончание реализации ИП',
      dataField: 'planEndDate',
      dataType: 'date',
      format: 'MM.yyyy',
      width: 150
    }, {
      caption: 'Стадия реализации ИП',
      dataField: 'implementStage',
      width: 150
    }, {
      caption: 'Объем КВЛ по ИП, тыс.руб.',
      dataField: 'totalVolume',
      width: 150,
      format: '###,##0.###'
    }, {
      caption: 'Ввод фондов (План), тыс.руб.',
      dataField: 'enterOf',
      width: 150,
      format: '###,##0.###'
    }, {
      caption: 'Признак ИП',
      dataField: 'signIp',
      dataType: 'string',
      width: 105,
      cellTemplate: 'signIp'
    }, {
      caption: 'Признак планового ИП',
      dataField: 'outPlan',
      dataType: 'boolean',
      width: 150
    }, {
      caption: 'Код объекта КС',
      dataField: 'objectCode',
      cellTemplate: 'longText',
      width: 170,
      visible: false
    }, {
      caption: 'Наименование объекта КС',
      dataField: 'objectName',
      cellTemplate: 'longText',
      width: 200,
      visible: false
    }, {
      caption: 'Вид работ',
      dataField: 'workType',
      width: 150,
      visible: false
    }, {
      caption: 'Источники финансирования',
      dataField: 'finSource',
      cellTemplate: 'longText',
      width: 250,
      visible: false
    }, {
      caption: 'Наименование ИПР/КИПР',
      dataField: 'iprName',
      cellTemplate: 'longText',
      width: 200,
      visible: false
    }, {
      caption: 'Версия ИПР/КИПР',
      dataField: 'iprVersion',
      cellTemplate: 'longText',
      width: 200,
      visible: false
    }
  ];

  investData: any = {};
  investDataMain: any = {};
  isDirty: false;
  canEdit = false;
  tooltipTarget;
  tooltipText;
  public reloadClickTrack = new Subject();
  investExportUrl;

  constructor(private http: HttpClient) {
  }

  setDirty(value) {
    this.isDirty = value;
    const btnSave = document.getElementById('dx-button-save-invest');
    if (btnSave) {
      btnSave.classList[!value ? 'add' : 'remove']('dx-state-disabled');
    }
  }

  setInvestData(data) {
    this.investData = data;
  }

  setTooltipTarget(targetId = null, text = null) {
    if (text) {
      this.tooltipTarget = targetId ? '#' + targetId : null;
      this.tooltipText = String(text);
    } else {
      this.tooltipTarget = this.tooltipText = null;
    }
  }

  getPprList() {
    return this.http.get<any>(`${api}/ppr/list`);
  }

  process(pprId, code) {
    return this.http.post<any>(`${api}/process`, {pprId, code});
  }

  public arrayFill(start, diff) {
    return new Array(diff).fill(start).map((x, i) => x + i);
  }

  public getIPR(queryParams, urlName: string, parentIds?, isActual?, year?, documentId?) {
    let httpParams = new HttpParams();
    if (queryParams) {
      httpParams = this.setHttpParams(httpParams, queryParams);
    }
    if (parentIds) {
      parentIds.forEach(x => httpParams = httpParams.append('parent', x));
    }
    if (isActual) {
      httpParams = httpParams.append('isActual', isActual);
    }
    if (year) {
      httpParams = httpParams.append('year', year);
    }
    if (documentId) {
      httpParams = httpParams.append('documentId', documentId);
    }
    return this.http.get<any>(urls[urlName], {params: httpParams});
  }

  public postIPR(body, urlName: string, year?, documentId?) {
    let params = new HttpParams();
    if (year) {
      params = params.append('year', year);
    }
    if (documentId) {
      params = params.append('documentId', documentId);
    }
    return this.http.post<any>(urls[urlName], body, {params});
  }

  public putIPR(key, values, param: string = 'id', urlName: string, year?, documentId?) {
    let params = new HttpParams();
    params = params.append(param, key);
    if (year) {
      params = params.append('year', year);
    }
    if (documentId) {
      params = params.append('documentId', documentId);
    }
    return this.http.put<any>(urls[urlName], values, {params});
  }

  public deleteIPR(ids: any[], param: string = 'id', urlName: string, column?, year?, documentId?) {
    let params = new HttpParams();
    ids.forEach(x => params = params.append(param, x));
    if (column) {
      params = params.append('column', column);
    }
    if (year) {
      params = params.append('year', year);
    }
    if (documentId) {
      params = params.append('documentId', documentId);
    }
    return this.http.delete<any>(urls[urlName], {params});
  }

  setHttpParams(params, queryParams) {
    if (queryParams.take) {
      params = params.append('take', queryParams.take.toString());
    }
    if (queryParams.skip) {
      params = params.append('skip', queryParams.skip.toString());
    }
    if (queryParams.filter) {
      params = params.append('filter', JSON.stringify(queryParams.filter));
    }
    if (queryParams.sort) {
      params = params.append('sort', JSON.stringify(queryParams.sort));
    }
    if (queryParams.group) {
      params = params.set('group', JSON.stringify(queryParams.group));
    }
    return params;
  }

  excelExport(urlName) {
    window.open(`${urls[urlName]}/export`);
  }

  public getInvest(id, details = false): Promise<any> {
    let params = new HttpParams().append('id', String(id));
    if (details) {
      params = params.append('details', 'true');
    }
    return this.http.get<any>(`${api}/investcard`, {params}).toPromise().then( data => {
      if (!details) { this.investDataMain = data; }
      return data;
    });
  }

  public createNewVersion(id: number) {
    return this.http.post<any>(`${api}/investcard/create-version`, {id});
  }

  public saveInvest(id, data, autoCode?, childs?) {
    let params = new HttpParams();
    if (childs) {
      params = params.append('childs', String(childs));
    }
    if (id === -1) {
      if (autoCode) {
        params = params.append('autoCode', String(autoCode));
      }
      return this.http.post<any>(`${api}/investcard`, {...this.investData, ...data}, {params});
    } else {
      params = params.append('id', String(id));
      return this.http.put<any>(`${api}/investcard`, {...this.investData, ...data}, {params});
    }
  }

  public deleteVersion(id: number) {
    const params = new HttpParams().append('id', String(id));
    return this.http.delete<any>(`${api}/investcard`, {params});
  }

  public putInvestChecked(id, checked) {
    return this.http.put<any>(`${api}/checked`, {id, checked});
  }

  public getObjects(queryParams, allObjects?) {
    let params = new HttpParams();
    if (queryParams) {
      params = this.setHttpParams(params, queryParams);
    }
    if (allObjects) {
      params = params.append('allObjects', String(allObjects));
    }
    return this.http.get<any>(`${api}/investcard/objects`, {params});
  }

  public getAllInvest(filter?, take?, skip?, sort?, group?, investId?: number, parentId?, showTree?, columns?, inCard?) {
    let params = new HttpParams();
    params = params.append('filter', filter);
    if (sort) {
      params = params.append('sort', JSON.stringify(sort));
    }
    if (showTree) {
      params = params.set('showTree', 'true');
    }
    this.investExportUrl = `api/object/export/create?invest=true&parentId=null&` + params;

    if (investId) {
      params = params.append('investId', String(investId));
    }
    if (parentId) {
      params = params.append('parentId', parentId);
    }
    if (take) {
      params = params.append('take', take.toString());
    }
    if (skip) {
      params = params.append('skip', skip.toString());
    }
    if (group) {
      params = params.set('group', JSON.stringify(group));
    }
    if (columns) {
      columns.forEach(x => params = params.append('column', x));
    }
    if (inCard) {
      params = params.set('inCard', JSON.stringify(inCard));
    }
    return this.http.get<any>(`${api}/all-invest`, {params});
  }

  public getCostItems(ipr) {
    const params = new HttpParams().append('ipr', String(ipr));
    return this.http.get<any>(`api/plan/expenses/list`, {params});
  }

  public getPercents(typeId) {
    const params = new HttpParams().append('typeId', String(typeId));
    return this.http.get<any>(`${api}/percent-sum`, {params});
  }

  public createNewElementVersion(id: number, titleId: number) {
    return this.http.post<any>(`${api}/investcard/create-version/element`, {id, titleId});
  }

  public deleteElementVersion(id: number) {
    const params = new HttpParams().append('id', String(id));
    return this.http.delete<any>(`${api}/investcard/delete-version/element`, {params});
  }
}
