import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  ViewChild
} from '@angular/core';
import {ObjectService} from '../../../services/object.service';
import {ListGeneratorComponent} from '../list-generator/list-generator.component';
import notify from 'devextreme/ui/notify';
import {CommonChoiceComponent} from '../../reference-information/components/common-choice/common-choice.component';
import {MatDialog} from '@angular/material/dialog';
import {SubobjectComponent} from "../../object/subobject/subobject.component";
import {SubscriptionLike} from "rxjs";
import {AuthenticationService} from "../../../services/authentication.service";
import {faFilter, faCaretDown, faCalendarDay, faLock} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-enter-characters',
  templateUrl: './enter-characters.component.html',
  styleUrls: ['./enter-characters.component.scss'],
  providers: [ObjectService]
})

export class EnterCharactersComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  faLock = faLock;
  closePeriodIcon = false;
  fakeId = -1;
  @Input() showPlanCharacters = false;
  @Input() subobjectId = 0;
  @Input() objectId = 0;
  @Input() form;
  @Input() typeId = 2;
  loading = true;
  enabledSubmit = false;
  subscriptions: SubscriptionLike[] = [];

  @ViewChild('tableEnterCharacters', {static: false}) tableEnterCharacters: ListGeneratorComponent;
  ch: any = [];

  @Input() date = new Date();
  @Input() startPeriod = new Date();
  @Input() endPeriod = new Date();
  _startPeriod;
  _endPeriod;
  _isClosed = false;
  needRefreshDate = true;
  editMode = false;
  editRight = false;
  dictionary =
    {
      id: 'building-control-enter-of-ph-ch',
      editRole: 'subobject',
      showCaption: false,
      name: 'Характеристики подобъекта (факт)',
      fileNameExport: 'Характеристики подобъекта (факт)',
      primaryKey: 'id',
      editMode: 'cell',
      allowRefresh: true,
      allowUpdate: true,
      allowSave: true,
      hideSelectedColumn: true,
      hideFilterHead: true,
      hideFilterRow: true,
      hideChooser: true,
      columnAutoWidth: true,
      wordWrap: true,
      columns: [
        {
          caption: 'Описание характеристики',
          dataField: 'name',
          dataType: 'string',
          allowEdit: false,
          sort: 'asc',
          visible: true
        }, {
          caption: 'Плановый объем: Ввод',
          dataField: 'planInputValue',
          dataType: 'string',
          allowEdit: false,
          visible: true
        }, {
          caption: 'Плановый объем: Вывод',
          dataField: 'planOutputValue',
          dataType: 'string',
          allowEdit: false,
          visible: true
        }, {
          caption: 'Фактический объем за предыдущие периоды: Ввод',
          dataField: 'previousFactInputValue',
          dataType: 'string',
          allowEdit: false,
          visible: true
        }, {
          caption: 'Фактический объем за предыдущие периоды: Вывод',
          dataField: 'previousFactOutputValue',
          dataType: 'string',
          allowEdit: false,
          visible: true
        }, {
          caption: 'Фактический объем за текущий месяц: Ввод',
          dataField: 'inputValue',
          dataType: 'string',
          allowEdit: false,
          visible: true
        }, {
          caption: 'Фактический объем за текущий месяц: Вывод',
          dataField: 'outputValue',
          dataType: 'string',
          allowEdit: false,
          visible: true
        }, {
          caption: 'Единица измерения',
          dataField: 'unit',
          dataType: 'string',
          allowEdit: false,
          visible: true
        }, {
          caption: 'Выводить в КС-14 и ОС-1/ОС-3',
          dataField: 'prEnter',
          dataType: 'string',
          allowEdit: false,
          visible: true
        }]
    };

  constructor(private objectService: ObjectService,
              public dialog: MatDialog,
              private ref: ChangeDetectorRef,
              private auth: AuthenticationService,) {
    this.editRight = this.auth.USER.forms.find(x => x.form === 'subobject' && x.permission === 'W');
  }

  async ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    /*console.log(changes);
    for (const propName in changes) {
      const changedProp = changes[propName];
      if (!changedProp.firstChange && !['typeId', 'date'].includes(propName)) {
        this.needRefreshDate = false;
        this.refresh();
      }
    }*/
  }

  ngOnInit() {
    this.refresh();
    if (this.showPlanCharacters) {
      this.dictionary['dropDownButton'] = [
        {
          hint: 'Доп. функционал',
          icon: 'fa fa-cog',
          dropDownWidth: 250,
          items: [
            {value: 'bolt', name: 'Плановые характеристики', icon: 'fa fa-bolt'},
            {value: 'copy', name: 'Копировать характеристики', icon: 'copy'}]
        }]
    }
  }

  changeMode(e) {
    this.refresh();
  }

  async refresh(e = null) {
    this.loading = true;
    const customButtons = [];

    /*if (this.form && this.form === 'corr' && this.typeId === 2) {
      customButtons.push({
        text: 'Копировать плановые значений в корректировку',
        hint: 'Копировать плановые значений в корректировку',
        value: 'copy',
        icon: 'copy'
      });
    }*/

    customButtons.push({
      text: 'Удалить фактические характеристики',
      hint: 'Удалить фактические характеристики',
      icon: 'trash',
      value: 'delete'
    })

    if (customButtons.length)
      this.dictionary['customButton'] = customButtons;

    this._isClosed = await this.isClosed();
    this.dictionary.columns.map(x => {
      if (x.dataField === 'corrInputValue' || x.dataField === 'corrOutputValue') {
        x.allowEdit = this.form && this.form === 'corr';
      }
      if (x.dataField === 'inputValue' || x.dataField === 'outputValue') {
        x.allowEdit = this.form && this.form === 'work' && this._isClosed === false;
      }
      if (x.dataField.toLowerCase().includes('output')) {
        x.visible = this.typeId === 2;
      }
    });
    this.ch = [];
    this.enabledSubmit = false;
    if (this.subobjectId > 0) {
      this.objectService.getEnterCharacters(this.subobjectId, this.date.getFullYear(), this.date.getMonth() + 1).subscribe(data => {
        console.log(data);
        if (data.length > 0) {
          if (this.editMode === true) {
            this.ch = data.filter(x => x.prEnter !== undefined && x.prEnter !== null && x.prEnter.length > 0);
          } else {
            this.ch = data;
          }
        } else {
          notify('Отсутствуют данные для отображения!', 'error', 2500);
        }
        this.loading = false;
      });
    } else {
      notify('Некорректный код подобъекта!', 'error', 2500);
      this.loading = false;
    }
  }

  async isClosed() {
    const closedInfo = await this.objectService.getClosedInfo(this.date.getFullYear());
    if (!closedInfo || !closedInfo.items || closedInfo.items.length === 0) {
      return false;
    }

    const period = closedInfo.items.find(x => x.month === this.date.getMonth() + 1);
    return period.factIsClosed;
  }

  async cellClick(e) {
    if (!this.editRight || this._isClosed) return;
    if (e.rowType === 'data' && (
      (this.form === 'corr' && ['previousFactInputValue', 'previousFactOutputValue', 'inputValue', 'outputValue'].includes(e.column.dataField))
      ||
      (this.form === 'work' && ['previousFactInputValue', 'previousFactOutputValue', 'corrInputValue', 'corrOutputValue'].includes(e.column.dataField))))
      return;

    if (e.rowType === 'data' && e.row.data.dictionaryId
      && e.row.data.dictionaryId !== undefined && e.row.data.dictionaryId !== null
      && e.row.data.dictionaryId > 0
      && (e.column.dataField === 'inputValue'
        || e.column.dataField === 'outputValue'
        || e.column.dataField === 'corrInputValue'
        || e.column.dataField === 'corrOutputValue')) {
      const l = await this.objectService.getDictionaryValueList(e.row.data.dictionaryId);
      if (!l.items.map(c => c.id).includes(-1, null)) {
        l.items.push({id: -1, value: 'Сбросить значение'});
      }

      const dxOverlayWrapper = document.getElementsByClassName('dx-overlay-wrapper') as HTMLCollectionOf<HTMLElement>;
      const zIndex = {};
      if (dxOverlayWrapper)
        Array.from(dxOverlayWrapper).forEach((x, index) => {
          zIndex[index] = x.style.zIndex;
          x.style.zIndex = '500'
        });

      const dialogRef = this.dialog.open(CommonChoiceComponent, {
        minWidth: '30%',
        maxWidth: '30%',
        maxHeight: '100vh - 100px',
        data: {
          dataSource: l.items.filter(x =>
            e.column.dataField === 'inputValue' ?
              (e.row.data.inputValueId != null ? x.id !== Number(e.row.data.inputValueId) : x.id === x.id) :
              (e.column.dataField === 'outputValue' ?
                (e.row.data.outputValueId != null ? x.id !== Number(e.row.data.outputValueId) : x.id === x.id) :
                e.column.dataField === 'corrInputValue' ?
                  (e.row.data.corrInputValueId != null ? x.id !== Number(e.row.data.corrInputValueId) : x.id === x.id) :
                  e.column.dataField === 'corrOutputValue' ?
                    (e.row.data.corrOutputValueId != null ? x.id !== Number(e.row.data.corrOutputValueId) : x.id === x.id) : x.id === x.id)
          ),
          dictionary: 'choiceCharacter'
        },
        disableClose: false
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.id === -1) {
            result.id = null;
          }
          if (result.id === null) {
            result.value = null;
          }
          if (e.column.dataField === 'inputValue') {
            e.row.data.inputValueId = result.id;
            e.row.data.inputValue = result.value;
          }
          if (e.column.dataField === 'outputValue') {
            e.row.data.outputValueId = result.id;
            e.row.data.outputValue = result.value;
          }
          if (e.column.dataField === 'corrInputValue') {
            e.row.data.corrInputValueId = result.id;
            e.row.data.corrInputValue = result.value;
          }
          if (e.column.dataField === 'corrOutputValue') {
            e.row.data.corrOutputValueId = result.id;
            e.row.data.corrOutputValue = result.value;
          }
          e.row.data.statusRow = 'u';
          this.onValueChanged();
          if (dxOverlayWrapper)
            Array.from(dxOverlayWrapper).forEach((x, index) => x.style.zIndex = zIndex[index])
        }
      });
    }
  }

  onValueChanged(e = null) {
    if (this.loading) {
      return;
    } else if (e && e.value === undefined) {
      return;
    }

    console.log('Changed');
    if (this.ch) {
      this.enabledSubmit = true;
    }
    this.ref.detectChanges();
  }

  save() {
    this.loading = true;
    if (this.tableEnterCharacters) {
      this.tableEnterCharacters.closeEditor();
      if (this.tableEnterCharacters.getModifedRow().length > 0) {
        const saveObject = {
          objectId: this.subobjectId > 0 ? this.subobjectId : undefined,
          month: this.date.getMonth() + 1,
          year: this.date.getFullYear(),
          items: this.tableEnterCharacters ? this.tableEnterCharacters.getModifedRow() : [],
        };
        this.objectService.saveEnterCharacters(saveObject).subscribe(data => {
          this.ch.forEach(x => x.statusRow = 's');
          this.tableEnterCharacters.dataGrid.instance.repaint();
          this.enabledSubmit = false;
          this.loading = false;
          notify('Данные успешно сохранены!', 'success', 1500);
        }, error => {
          this.loading = false;
          // notify(error.message, 'error', 2500);
        });
      } else {
        this.loading = false;
        notify('Данные для сохранения отсутствуют!', 'warning', 2500);
      }
    }
  }

  clickCustomButton(e) {
    this.deleteCharacteristics();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  EditingStart(e) {
    if (e.data && e.data.dictionaryId)
      e.cancel = true;
  }

  dropDownClick(data) {
    if (data.value === 'bolt') {
      this.openPlanCharacters();
    } else if (data.value === 'copy') {
      this.copyCharacters(this.subobjectId);
    }
  }

  openPlanCharacters() {
    this.loading = true;
    this.subscriptions.push(this.objectService.getSubobjects(this.objectId, null, null, null, null, true, true, null, this.subobjectId).subscribe(data => {
      this.loading = false;
      if (data.items.length) {
        const dxOverlayWrapper = document.getElementsByClassName('dx-overlay-wrapper') as HTMLCollectionOf<HTMLElement>;
        const zIndex = {};
        if (dxOverlayWrapper)
          Array.from(dxOverlayWrapper).forEach((x, index) => {
            zIndex[index] = x.style.zIndex;
            x.style.zIndex = '500'
          });
        const dialogRef = this.dialog.open(SubobjectComponent, {
          maxHeight: '90vh - 100px',
          maxWidth: '80vw',
          data: {subObject: data.items[0]},
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(() => {
          if (dxOverlayWrapper)
            Array.from(dxOverlayWrapper).forEach((x, index) => x.style.zIndex = zIndex[index])
          this.refresh();
        });
      } else {
        notify('Не удалось получить данные по физ. характеристикам подобъекта.', 'warning', 2500);
      }
    }, () => {
      this.loading = false;
    }));
  }

  async copyCharacters(id) {
    this.loading = true;
    try {
      const data = await this.objectService.copySubobjectCharacters(id);
      this.refresh()
      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  }

  ngAfterViewInit(): void {
    this._startPeriod = new Date(this.startPeriod.getFullYear(), this.startPeriod.getMonth(), 1);
    this._endPeriod = new Date(this.endPeriod.getFullYear(), this.endPeriod.getMonth(), 1);
    this.date = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
  }

  deleteCharacteristics() {
    this.loading = true;
    this.objectService.resetFactCharacters(this.subobjectId).subscribe(data => {
      this.refresh();
      this.loading = false;
      notify('Фактические характеристики успешно удалены!', 'success', 1500);
    }, error => {
      this.loading = false;
      // notify(error.message, 'error', 2500);
    });
  }

}
