import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ReferenceInformationService} from '../../../services/reference-information.service';
import {faExclamationTriangle, faPen} from '@fortawesome/free-solid-svg-icons';
import {DocInfo} from '../../../models/document.model';
import {DocumentService} from '../../../services/document.service';
import {ListGeneratorComponent} from '../../common-element/list-generator/list-generator.component';
import {Dictionaries} from '../dictionaries';
import {DxDiagramComponent, DxDropDownBoxComponent, DxFormComponent} from 'devextreme-angular';
import {ConfirmService} from '../../common-element/components/confirm/confirm.service';
import {TreeOrgUnitComponent} from '../../diagram-agreement/tree-org-unit/tree-org-unit.component';
import notify from 'devextreme/ui/notify';
import {AuthenticationService} from '../../../services/authentication.service';
import {Observable, SubscriptionLike} from 'rxjs';
import {UploadFileComponent} from '../../common-element/upload-file/upload-file.component';
import {TreeObjectHierarchyComponent} from '../../common-element/tree-object-hierarchy/tree-object-hierarchy.component';
import {CommonChoiceComponent} from '../../reference-information/components/common-choice/common-choice.component';
import {ChooseExecutorComponent} from '../../common-element/choose-executor/choose-executor.component';
import {UserService} from '../../../services/user.service';
import {FileHandle} from './dragDrop.directive';
import {formatDate} from '@angular/common';
import {AgreementButtonComponent} from '../../diagram-agreement/agreement-button/agreement-button.component';
import {DocumentsChooserComponent} from '../documents-chooser/documents-chooser.component';
import * as XLSX from 'xlsx';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import {Title} from '@angular/platform-browser';
import {faDove, faVectorSquare, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {ReportViewComponent} from '../../reports/report-view/report-view.component';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  providers: [DocumentService, UserService],
  encapsulation: ViewEncapsulation.None
})
export class DocumentComponent implements OnInit, AfterViewChecked, OnDestroy {
  newRow: any;
  doc: DocInfo;
  fakeId = -1;
  enabledSubmit = false;
  loading = true;
  tmpUploadFiles = [];
  estimateInfo;
  canEditHeadline = false;
  faPen = faPen;
  faExclamationTriangle = faExclamationTriangle;
  indicatorVisible = false;
  uploadFile: File;
  files: FileHandle[] = [];
  modified = false;
  agreementDisabled = true;
  dataSourceReference;
  subscriptions: SubscriptionLike[] = [];
  faDove = faDove;
  faInfoCircle = faInfoCircle;
  dictionaryDocObjects: any;
  dictionaryDocDocuments: any;
  @ViewChild('tableDocDocuments', {static: false}) tableDocDocuments: ListGeneratorComponent;
  @ViewChild('tableDocObjects', {static: false}) tableDocObjects: ListGeneratorComponent;
  @ViewChild('formComponent1', {static: false}) formComponent1: DxFormComponent;
  @ViewChild('formComponent2', {static: false}) formComponent2: DxFormComponent;
  @ViewChild('formFieldsComponent', {static: false}) formFieldsComponent: DxFormComponent;
  @ViewChild('uploadFileComponent', {static: false}) uploadFileComponent: UploadFileComponent;
  @ViewChild('agreementButton', {static: false}) agreementButton: AgreementButtonComponent;
  @ViewChild('diagramReferences', {static: false}) diagram: DxDiagramComponent;
  @ViewChild('reportView', {static: false}) reportView: ReportViewComponent;

  @ViewChild('versionsList', {static: false}) versionsList: DxDropDownBoxComponent;
  selectedVersion: any;
  visibleDopPanel = false;

  scrollHeight = 210;
  initial = false;
  isFiltered = false;
  isFilteredReal = true;
  AllDocDocuments = false;
  popupVisibleReference = false;
  visibleLinksDocs = false;
  dataSourceLinkDoc;
  selectedLinkDocId;

  titleBefore: string;
  docsvisionLink: string;

  warningMessage: any;
  messageInfo;
  defaultVisible = false;

  visibleButton = false;
  readOnlyButton = true;
  readOnlyDate = true;
  validationMessageDate;
  dateIsValid = true;
  visibleInvoiceField = false;
  tmpValues = {number: null, date: null, sync: true, paymentNumber: null, paymentDate: null};
  faVectorSquare = faVectorSquare;
  labelTypeLink = {
    object: 'Объект/подобъект',
    title: 'ИП/Элемент ИП'
  };
  typeLink = 'object';

  constructor(private documentService: DocumentService,
              private userService: UserService,
              public authService: AuthenticationService,
              public dialogRef: MatDialogRef<DocumentComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private refService: ReferenceInformationService,
              private ref: ChangeDetectorRef,
              private confirmDialog: ConfirmService,
              private titleService: Title) {
    this.documentDateValidation = this.documentDateValidation.bind(this);
    if (this.data.initialId !== undefined) {
      if (this.data.initialId !== this.data.id) {
        const id = this.data.id;
        this.data.id = this.data.initialId;
        this.data.initialId = id;
        this.initial = true;
      }
    }
    if (this.data.estimateInfo) {
      this.estimateInfo = this.data.estimateInfo;
    }
    if (this.data.copy === undefined) {
      this.data.copy = false;
    }
    if (this.data.virtual) {
      const dateNow = this.data.virtual.date;
      this.data.doc = {
        id: this.fakeId--,
        number: null,
        isNumberFromFile: false,
        order_number: null,
        documentDate: `${dateNow.getFullYear()}-${dateNow.getMonth() + 1}-${dateNow.getDate()}`,
        version: '1.0',
        name: this.data.virtual.name,
        description: null,
        isActive: false,
        approvalDate: null,
        type: this.data.virtual.type,
        status: {
          id: 1,
          name: 'Создан'
        },
        parent: this.data.virtual.parent,
        department: {
          id: this.authService.USER.official ?
            (this.authService.USER.official.department ? this.authService.USER.official.department.id : null) : null,
          name: this.authService.USER.official ?
            (this.authService.USER.official.department ? this.authService.USER.official.department.name : null) : null
        },
        contragent: {
          id: this.authService.USER && this.authService.USER.contragentId ?
            this.authService.USER.contragentId : null,
          shortName: this.authService.USER && this.authService.USER.contragentId ?
            this.authService.USER.contragent.shortName : null
        },
        official: this.authService.USER.official,
        documentType: null,
        documentYear: dateNow.toString(),
        year: dateNow.getFullYear(),
        fields: this.data.virtual.fields,
        versions: [],
        objects: this.data.virtual.objects,
        objectsName: null,
        fileName: null,
        files: [],
        attachments: [],
        contract: this.data.virtual.contract,
        readonlyFields: this.data.virtual.readonlyFields
      };
      this.data.doc.versions.push({
        id: this.fakeId--,
        version: '1.0',
        branchVersion: 1,
        subversion: 0,
        parentId: null,
        status: {
          id: 1,
          name: 'Создан'
        },
        isActive: false,
        createdAt: new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 0, 0, 0, 0),
        approvalDate: null
      });
      if (this.data.doc.objects) {
        this.data.doc.objects.forEach(x => {
          x.statusRow = 'i';
        });
        this.data.doc.objectsName = this.data.doc.objects.map(x => (x.code ? x.code : '') + ' ' + (x.name ? x.name : '')).join('; ');
      }
    }
    this.version_displayExpr = this.version_displayExpr.bind(this);

    this.dataSourceLinkDoc = new DataSource({
      paginate: true,
      pageSize: 15,
      store: new CustomStore({
        key: 'id',
        load: (options) => {
          if (!this.selectedLinkDocId) {
            return new Promise((resolve) => {
              resolve({type: 'array', key: 'id', data: [], totalCount: 0});
            });
          }
          return this.documentService.getLinkDocs(this.selectedLinkDocId, options).toPromise().then(r => {
              return {
                type: 'array',
                key: 'id',
                data: r.items,
                totalCount: r.count
              };
            }
          ).catch(error => {
            this.warningMessage = error.error;
            throw error.error;
          });
        }
      })
    });
  }

  setTypeLink() {
    this.typeLink = this.doc && this.doc.type && this.doc.type.typeLink || this.typeLink;
    if (this.tableDocObjects) {
      this.tableDocObjects.inputData = {typeLink: this.typeLink}
    }
  }

  createNewVersion() {
    if (!this.enabledSubmit) {
      this.addNewVersion();
    } else {
      this.confirmDialog.confirm('Подтверждение',
        'Имеются не сохраненные данные! Сохранить перед созданием новой версии?', false).subscribe(
        result => {
          if (result) {
            this.onDocumentFormSubmit(true, true);
          } else {
            this.addNewVersion();
          }
        });
    }
  }

  async addNewVersion() {
    const last = await this.documentService.getLastId(this.doc.id);
    if (this.doc.id !== last.id) {
      notify('Выберите последнюю версию документа', 'warning', 2500);
      return;
    }

    this.documentService.version(this.doc.id, this.doc.objects.map(o => o.id), true).subscribe(data => {
      console.log(data);
      this.doc = data;
      if (this.doc.id === undefined) {
        this.doc.id = this.fakeId--;
      }
      //Кс-3
      this.dictionaryDocDocuments.dropDownButton[0].items[2].visible = this.doc.id > 0 && [-11, -36, -37].includes(this.doc.type.id) && !this.doc.parent;
      this.updateDocument();
      this.enabledSubmit = true;
      if (this.doc.versions) {
        this.selectedVersion = this.doc.versions.find(x => x.version === this.doc.version);
        if (!this.selectedVersion) {
          this.selectedVersion = this.doc.versions.find(x => x.id < 0);
        }
      }
      this.agreementButton.refreshData(this.doc.id, {status: {id: 1, name: 'Создан'}});
      notify('Сформирована новая версия. Для завершения создания необходимо нажать на кнопку \'Сохранить\'! ', 'info', 1500);
    });
  }

  version_displayExpr() {
    const dateNow = new Date();
    let createdAt = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 0, 0, 0, 0);
    if (this.selectedVersion.createdAt !== undefined) {
      createdAt = new Date(this.selectedVersion.createdAt);
    }
    return this.selectedVersion.version + ' от ' + formatDate(createdAt, 'dd.MM.yyyy', 'en_US');
  }

  selectedRow(e = null) {
    if (e.selectedRowsData['0'] !== undefined) {
      this.selectedVersion = e.selectedRowsData['0'];
      this.versionsList.instance.close();
    }

    if (this.selectedVersion && this.selectedVersion.id && this.selectedVersion.id > 0) {
      this.documentService.getDocument(this.selectedVersion.id).subscribe(data => {
        console.log(data);
        this.doc = data;
        this.setTypeLink();
        this.titleService.setTitle(this.doc.number === null ? 'Новый документ' : this.doc.number);
        if (this.doc.id === undefined) {
          this.doc.id = this.fakeId--;
        }
        this.updateDocument();
        this.enabledSubmit = false;
        if (this.doc.versions) {
          this.selectedVersion = this.doc.versions.find(x => x.version === this.doc.version);
          if (!this.selectedVersion) {
            this.selectedVersion = this.doc.versions.find(x => x.id < 0);
          }
        }
        this.agreementButton.refreshData(this.doc.id);
      });
    }
  }

  filesDropped(files: FileHandle[]): void {
    if (files.length > 0) {
      if ([1, 4].includes(this.doc.status.id))
        this.doUploadFile(files[0].file);
      else notify({
        message: 'Основной файл разрешено изменять только в статусах "Создан" и "На доработке"!',
        width: 700
      }, 'error', 2500);
    }
  }

  doUploadFile(file: File) {
    if (file && file.size > 0) {
      this.uploadFile = file;
      this.doc.fileName = file.name;
      if (this.doc.type && this.doc.type.numberFromFile === true) {
        if (file.name.lastIndexOf('.') > 0) {
          if (file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase() === 'xml') {
            this.readFile(this.uploadFile).subscribe(async data => {
              let str = window.atob(data.split(',')[1]);
              let b = str.indexOf([-23, -30, -44].includes(this.doc.type.id) ? '<Номер>' : 'Properties LocNum="');
              let endRTNNumber = '<';
              let enc = false;
              if (b <= 0 && [-23, -30, -44].includes(this.doc.type.id)) {
                // XML из excel
                b = str.indexOf('Ð ÐµÐµÑÑÑ ÑÐ¾Ð²Ð°ÑÐ½ÑÑ Ð½Ð°ÐºÐ»Ð°Ð´Ð½ÑÑ (Ð¢ÐÐ Ð-12) â');
                if (b <= 0)
                  b = str.indexOf('ÐÐ¾Ð¼ÐµÑ');
                else endRTNNumber = ' Ð¾Ñ';
                enc = true;
              }
              if (b > 0) {
                str = str.substr([-23, -30, -44].includes(this.doc.type.id) ? (b + (enc === false ? 7 : 11)) : (b + 19));
                const e = str.indexOf([-23, -30, -44].includes(this.doc.type.id) ? endRTNNumber : '"');
                if (e > 0) {
                  let v = str.substr(0, e);
                  if (endRTNNumber === ' Ð¾Ñ') {
                    const _index = v.indexOf('â');
                    v = v.substr(_index + 3);
                  }
                  const convertString = await this.documentService.convertString(v);
                  this.doc.number = convertString.output;
                  this.doc.isNumberFromFile = true;
                  notify('В файле обнаружен номер документа!', 'success', 2500);
                  //  this.ref.detectChanges();
                } else {
                  notify('В файле номер документа не обнаружен! Необходимо ввести данное поле вручную!', 'warning', 2500);
                }
              } else {
                notify('В файле номер документа не обнаружен! Необходимо ввести данное поле вручную!', 'warning', 2500);
              }
            });
          } else if (file.name.substr(file.name.lastIndexOf('.') + 1).includes('xls') && [-30].includes(this.doc.type.id)) {
            this.readXLSXNumber(this.uploadFile, this.doc.type.id).subscribe(num => {
              if (num) {
                this.doc.number = num;
                this.doc.isNumberFromFile = true;
              } else {
                notify('В файле номер документа не обнаружен! Необходимо ввести данное поле вручную!', 'warning', 2500);
              }
            }, error => {
              this.warningMessage = error.error;
              this.enabledSubmit = true;
              this.indicatorVisible = false;
            });
          } else {
            notify('В файле номер документа не обнаружен! Необходимо ввести данное поле вручную!', 'warning', 2500);
          }
        } else {
          notify('В файле номер документа не обнаружен! Необходимо ввести данное поле вручную!', 'warning', 2500);
        }
      }
      console.log('Changed');
      this.isEnabledSubmit();
    }
  }

  modifiedData() {
    this.isEnabledSubmit();
    this.ref.detectChanges();
  }

  dropDownClick(data) {
    if (this.AllDocDocuments || (this.doc.status && ![1, 4].includes(this.doc.status.id))) {
      notify('Включен режим только на чтение для таблицы дополнительных файлов', 'warning', 2500);
    } else {
      if (data.value === 1) {
        this.uploadFileComponent.simulateOpen();
      } else if (data.value === 2) {
        this.chooseDocument(true);
      } else if (data.value === 3) {
        this.createInvoice();
      }
    }
  }

  chooseDocument(multiple = false) {
    const dialogRef = this.dialog.open(
      DocumentsChooserComponent, {
        minWidth: '640px',
        maxWidth: '1280px',
        maxHeight: '90vh',
        disableClose: false,
        data: {
          multi: multiple,
          typeLink: this.typeLink || 'object',
          journal: true,
          filteringOwnObject: true,
          mainDocumentId: this.doc && this.doc.type  && !this.doc.type.isCommon
            && this.doc.objects && this.doc.objects.length
            && (this.doc.objects[0].id || this.doc.objects[0].objectId) || undefined
        }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (multiple) {
          if (!this.tableDocDocuments.dataSource) {
            this.tableDocDocuments.dataSource = [];
          }
          const curKeys = this.tableDocDocuments.getKeysDataSource();
          result.forEach(x => {
              if (!curKeys.includes(x.id)) {
                this.tableDocDocuments.addNewRow(
                  {
                    id: x.id,
                    name: x.name,
                    extension: null,
                    typeId: x.typeId,
                    documentTypeName: x.documentType,
                    createdAt: x.createdAt,
                    attachmentType: 'from'
                  }, true
                );
              }
            }
          );
        } else {
          if (result.document) {
            this.tableDocDocuments.addNewRow({
              id: result.document.id,
              name: result.document.name,
              extension: null,
              typeId: result.document.typeId,
              documentTypeName: result.document.documentType,
              createdAt: result.document.createdAt,
              attachmentType: 'from'
            }, true);
          }
        }
        this.isEnabledSubmit();
        this.ref.detectChanges();
      }
    });
  }

  cellClick(e) {
    if (this.AllDocDocuments || (this.doc.status && ![1, 4].includes(this.doc.status.id))) {
      this.tableDocDocuments.btnDelete.option({
        disabled: true
      });
      notify('Включен режим только на чтение для таблицы дополнительных файлов', 'warning', 2500);
    } else {
      // if (this.authService.checkRights('', newRow ? 'W' : 'R')) {
      if ((e.rowType === 'data' && e.column.dataField === 'documentTypeName' && !this.isEmpty(e.row.data.extension))) {
        const dialogRef = this.dialog.open(CommonChoiceComponent, {
          width: '35%',
          maxHeight: '100vh - 100px',
          data: {
            selected: e.row.data.typeId != null ? [e.row.data.typeId] : [],
            dictionary: 'choiceOnClickTypeDocs'
          },
          disableClose: false
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            e.row.data.typeId = result.id;
            e.row.data.documentTypeName = result.name;
            if (e.row.data.statusRow !== 'i')
              e.row.data.statusRow = 'u';
            this.isEnabledSubmit();
            this.ref.detectChanges();
          }
        });
      }
      // }
    }
  }

  buttonIconClick(e) {
    console.log(e);
    if (e.id === 'download' && e.data) {
      if (!this.isEmpty(e.data.id)) {
        const type = e.data.attachmentType === 'tmp' ? 'tmp' : 'attachment';
        const field = e.data.attachmentType === 'tmp' ? 'tmpId' : 'fileId';
        window.open(`/api/document/file?id=${this.doc.id}&type=${type}&${field}=${e.data.id}`);
      }
    } else if (e.id === 'link' && e.data) {
      if (!this.isEmpty(e.data.id)) {
        window.open(window.location.href.split('#')[0] + `#/documents/${e.data.id}`);
        return;
      }
    } else if (e.id === 'report' && e.data) {
      this.showReport(e.data);
    }
  }

  doSaveFileName(archive = false): void {
    if(this.doc.id < 0 && this.doc.file && this.doc.file['id']) {
      window.open(`/api/document/file?id=${this.doc.id}&type=tmp&tmpId=${this.doc.file['id']}`);
    } else if (archive) {
      if (this.doc.id && this.doc.id > 0) {
        window.open(`/api/document/archive?id=${this.doc.id}`);
      }
    } else {
      if (this.doc && this.doc.file) {
        window.open(`/api/document/file?id=${this.doc.id}&type=main`);
      }
    }
  }

  docsVisionOpen(link): void {
    window.open(`${link}`);
  }

  SbisOpen(link): void {
    window.open(`${link}`);
  }

  deleteParent(): void {
    this.doc.parent = null;
    this.isEnabledSubmit();
    this.ref.detectChanges();
  }

  chooseParent(): void {
    const dialogRef = this.dialog.open(CommonChoiceComponent, {
      maxWidth: '80%',
      maxHeight: '100vh - 100px',
      data: {
        selected: this.doc.parent ? [this.doc.id, this.doc.parent.id] : [this.doc.id],
        dictionary: 'choiceOnClickDoc',
        typeId: [-9, -6, -5, -7, -8].includes(this.doc.type.id) ? this.doc.type.id : null,
        //this.doc.type.groupId === 3? this.doc.type.id : null,  //для планов выбирать только определенного типа документы
        objectId: (this.typeLink || 'object') === 'object' && this.doc.type.groupId < 0 ? this.doc.objects.map(x => x.objectId || x.id) : null,
        titleId: this.typeLink === 'title' && this.doc.type && !this.doc.type.isCommon && this.doc.objects && this.doc.objects.length && this.doc.objects.map(x => x.titleId || x.id)[0] || null,
        hideCommon: (this.typeLink || 'object') === 'object' && this.doc.type.groupId < 0 || this.typeLink === 'title' && !this.doc.type.isCommon || null,
        checkParentType: this.doc.type.id,
        typeLink: this.typeLink || 'object',
        journal: true
      },
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (!this.doc.parent) {
          this.doc.parent = {
            id: result.id,
            name: result.name + ' №' + result.number + ' версии ' + result.version
          };
        } else {
          this.doc.parent.id = result.id;
          this.doc.parent.name = result.name + ' №' + result.number + ' версии ' + result.version;
        }
        this.isEnabledSubmit();
        this.ref.detectChanges();
      }
    });
  }

  addContract() {
    let isQueryParam = false;
    const queryParam = [];
    if (this.doc.objects) {
      this.doc.objects.forEach(x => {
        if (x.isObject === true) {
          isQueryParam = true;
          queryParam.push(['objectIds', x.id]);
        }
      });
    }

    const data = {
      selected: this.doc.contract ? [this.doc.contract.id] : [],
      queryParam: isQueryParam ? queryParam : null,
      dictionary: 'choiceOnClickContract'
    }

    if (this.authService.USER.contragentId)
      data['queryParam'] = (data['queryParam'] || []).concat([['subcontractorId', this.authService.USER.contragentId]])
    if (this.doc.objects && this.doc.objects.length === 1 && this.doc.objects[0].isObject)
      data['queryParam'] = (data['queryParam'] || []).concat([['needObject', true], ['simple', true], ['objectId', this.doc.objects[0].id]])

    const dialogRef = this.dialog.open(CommonChoiceComponent, {
      width: '80%',
      maxHeight: '100vh-100px',
      data,
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (!this.doc.contract) {
          this.doc.contract = {
            id: result.id,
            name: result.numContract + ' от ' + formatDate(result.dateApprove, 'dd.MM.yyyy', 'en_US')
          };
        } else {
          this.doc.contract.id = result.id;
          this.doc.contract.name = result.numContract + ' от ' + formatDate(result.dateApprove, 'dd.MM.yyyy', 'en_US');
        }
        if (this.doc.type && this.doc.type.isContract && this.doc.objects && this.doc.objects.length > 0) {
          notify('Перечень объектов/подобъетов обнулен. Необходимо перевыбрать с учетов выбранного договора!', 'info', 1500);
          this.doc.objects = [];
          this.doc.objectsName = null;
        }
      }
      this.isEnabledSubmit();
    });
  }

  deleteContract(): void {
    this.doc.contract = null;
    this.isEnabledSubmit();
    this.ref.detectChanges();
  }

  addObjects() {
    const dialogRef = this.dialog.open(TreeObjectHierarchyComponent, {
      width: '80wh',
      height: '90vh',
      data: {
        typeLink: this.typeLink || 'object',
        level: this.doc && this.doc.type && this.doc.type.level,
        selectedRowKeys: this.doc.objects ? this.doc.objects.map(x => x.id) : [],
        selectedRows: this.doc.objects && this.doc.objects || [],
        mode: this.doc.type.isCommon ? 'multiple' : 'single',
        contractId: this.doc.contract && this.doc.type && this.doc.type.isContract ? this.doc.contract.id : undefined,
        linkDocumentId: this.doc.objects && this.doc.objects.length && (this.doc.objects[0].id || this.doc.objects[0].objectId) || null
      },
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const docObjects = Object.create(this.doc.objects);
        this.doc.objects = [];
        for (const r of result) {
          this.doc.objects.push({
            id: r.id,
            code: r.code,
            name: r.name,
            statusRow: !docObjects.map(x => x.id).includes(r.id) && r.id > 0 ? 'i' : 's',
            isObject: r.parentId == null,
            baseVersionId: r.baseVersionId
          });
        }
      }
      if (this.doc.objects) {
        this.doc.objectsName = this.doc.objects.map(x => (x.code ? x.code : '') + ' ' + (x.name ? x.name : '')).join('; ');
      }
      this.isEnabledSubmit();
      this.ref.detectChanges();
    });
  }

  deleteObjects(): void {
    this.doc.objects = [];
    this.doc.objectsName = null;
    this.isEnabledSubmit();
    this.ref.detectChanges();
  }

  doEditDepartment(): void {
    const dialogRef = this.dialog.open(TreeOrgUnitComponent, {
      maxWidth: '50%',
      height: '80vh',
      data: {
        mode: 'single',
        selectedRowKeys: this.doc.department ? [this.doc.department.id] : [],
        filial: this.doc.type.filial
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (!this.doc.department) {
          this.doc.department = {
            id: result[0].id,
            name: result[0].name
          };
        } else {
          this.doc.department.id = result[0].id;
          this.doc.department.name = result[0].name;
        }
        this.isEnabledSubmit();
        this.ref.detectChanges();
      }
    });
  }

  showFileHistory() {
    this.dialog.open(CommonChoiceComponent, {
      maxWidth: '640px',
      maxHeight: '100vh - 100px',
      data: {
        selected: [],
        dictionary: 'file-history',
        dataSource: this.doc.files
      },
      disableClose: false
    });
  }

  chooseExecutor() {
    const dialogRef = this.dialog.open(
      ChooseExecutorComponent, {
        maxWidth: '40%',
        maxHeight: '90vh',
        data: {
          selectedRowKeys: this.doc.official ? [this.doc.official.id] : [],
          executors: null,
          singleSelect: true,
          canSelectDepartment: false
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.getOfficial(result.id).subscribe(data => {
          console.log(data);
          this.doc.official = data;
          this.isEnabledSubmit();
          this.ref.detectChanges();
        });
      }
    });
  }

  readFile(file: File): Observable<string> {
    return new Observable(obs => {
      const reader = new FileReader();
      reader.onload = () => {
        obs.next(reader.result as string);
        obs.complete();
      };
      reader.readAsDataURL(file);
    });
  }

  readXLSXNumber(file: File, typeId): Observable<any> {
    return new Observable(obs => {
      const readerXLSX = new FileReader();
      readerXLSX.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, {type: 'binary'});

        let numDoc;
        /* selected the first sheet */
        if (wb.SheetNames.length > 0) {
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}

          switch (typeId) {
            case -30:
              numDoc = data.find(x => x['__EMPTY_1'] === 'Реестр товарных накладных (ТОРГ-12) №' && !!x['__EMPTY_6']);
              if (numDoc) {
                numDoc = numDoc['__EMPTY_6'];
              }
              break;
          }
        } else {
          notify('Файл не соответствует шаблону, выберите другой файл!', 'error', 2500);
        }

        obs.next(numDoc);
        obs.complete();
      };
      readerXLSX.readAsBinaryString(file);
    });
  }

  readXLSX(file: File, typeId): Observable<any[]> {
    return new Observable(obs => {
      const readerXLSX = new FileReader();
      readerXLSX.onload = (e: any) => {
        let data;
        /* create workbook */
        if (file.name.substr(file.name.lastIndexOf('.') + 1).includes('xls')) {
          const binarystr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(binarystr, {type: 'binary'});

          /* selected the first sheet */
          if (wb.SheetNames.length > 0) {
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            let needMoreColumns = false;
            if (typeId == -19) {
              const aoa: any[] = XLSX.utils.sheet_to_json(ws, {header: 1});
              needMoreColumns = Math.max.apply(Math, aoa.map(x => Array.from(x).length)) === 8;
            }

            /* save data */
            let header = [];
            switch (typeId) {
              case -19:
                header = ['pp', 'name', 'unit', 'count', 'note'];
                if (needMoreColumns)
                  header = header.concat('filePhys', 'fileValue', 'fileUnit');
                break;
              case -20:
                header = ['pp', 'name', 'type', 'code', 'manufacturer', 'mnemonicCode', 'unit', 'count', 'unitWeight', 'note'];
                break;
              case -22:
                header = ['number', 'caption', 'description', 'formula', 'total'];
                break;
              case -30:
                header = ['pp', 'contract_number', 'tn_number', 'tn_date', 'nomenclature', 'name', 'count', 'unit', 'price', 'cost', 'tax', 'total_cost', 'nomenclature_price'];
                break;
            }

            data = XLSX.utils.sheet_to_json(ws, {header}); // to get 2d array pass 2nd parameter as object {header: 1}

            let headerNames = [];
            switch (typeId) {
              case -19:
                headerNames = ['№', 'Наим', 'Ед', 'Кол', 'Прим'];
                if (needMoreColumns)
                  headerNames = headerNames.concat('Физ', 'Знач', 'Ед');
                break;
              case -20:
                headerNames = ['Поз', 'Наим', 'Тип', 'Код', 'Зав', 'Мне', 'Ед', 'Коли', 'Мас', 'Прим'];
                break;
              case -22:
                headerNames = ['№', 'Хар', 'Ном', 'Рас', 'Стоим'];
                break;
              case -30:
                headerNames = ['№ п/п', 'Номер договора поставки', 'Номер това', 'Дата  това', 'Мнемокод', 'Наимен', 'Кол', 'Ед', 'Цена', 'Сумм', 'Ставка', 'Сумма с НДС', 'Цена без НДС в справочнике МТРиО 1С МДМ'];
                break;
            }

            let cnt = 0;
            for (let r = 0; r < header.length; r++) {
              const headerCell = data.filter(row => row[header[r]] !== undefined && !this.isEmpty(row[header[r]].toString().trim())
                && row[header[r]].toString().trim().startsWith(headerNames[r]));
              if (headerCell !== undefined && headerCell !== null && headerCell.length > 0) {
                cnt = cnt + 1;
              }
            }
            if (cnt !== header.length) {
              notify('Файл не соответствует шаблону, выберите другой файл!', 'error', 2500);
              this.enabledSubmit = true;
              return;
            }

            if (typeId === -30) {
              const indexStart = data.indexOf(data.find(x => x['pp'] && x['pp'].includes('№ п/п')));
              let indexEnd = data.length;
              for (let i = data.length - 1; i >= 0; --i) {
                if (data[i]['contract_number'] === 'ВСЕГО по реестру') {
                  indexEnd = data.indexOf(data[i]);
                  break;
                }
              }
              data = data.slice(indexStart + 2, indexEnd);
              data = data.filter(x => x['pp']);
            } else if (typeId === -22) {
              const indexStart = data.indexOf(data.find(x => x['number'] && Number(x['number'])));
              const thousands = data[indexStart - 1]['total'].includes('тыс.');
              let indexEnd = data.length;
              for (let i = data.length - 1; i >= 0; --i) {
                if (data[i]['total']) {
                  indexEnd = data.indexOf(data[i]);
                  break;
                }
              }
              data = data.slice(indexStart + 1, indexEnd + 1);
              data[0]['thousands'] = thousands;
            } else {
              data = data.filter(row => !isNaN(row['pp']) && !this.isEmpty(row['pp'].toString().trim())
                && isNaN(row['name']) && !this.isEmpty(row['name'].toString().trim())
                && !isNaN(row['count']) && !this.isEmpty(row['count'].toString().trim()));
            }
          } else {
            notify('Файл не соответствует шаблону, выберите другой файл!', 'error', 2500);
          }
        }
        // tslint:disable-next-line:no-string-literal
        obs.next(data);
        obs.complete();
      };
      readerXLSX.readAsBinaryString(file);
    });
  }

  /*getFile(file): Observable<any> {
    this.documentService.getFileData(file.id)
  }*/

  ngOnInit(): void {
    this.titleBefore = this.titleService.getTitle();
    this.canEditHeadline = !(this.data.id && this.data.id > 0);
    this.dictionaryDocObjects = Dictionaries.dictionaries.find(x => x.id === 'doc-objects');
    this.dictionaryDocDocuments = Dictionaries.dictionaries.find(x => x.id === 'doc-documents');
    this.dictionaryDocDocuments.dropDownButton[0].items[2].visible = false;
    const dateNow = new Date();
    if (this.data.doc) {
      this.doc = this.data.doc;
      console.log(this.doc);
      this.getNewDocInfo();
      this.titleService.setTitle(this.doc.number === null ? 'Новый документ' : this.doc.number);
    } else {
      if (this.data.id > 0) {
        this.documentService.getDocument(this.data.id).subscribe(data => {
          this.doc = data;
          this.setTypeLink();
          this.dictionaryDocDocuments.dropDownButton[0].items[2].visible = this.doc.id > 0 && [-11, -36, -37].includes(this.doc.type.id) && !this.doc.parent;
          console.log(this.doc);
          if (data.contragent) {
            this.titleService.setTitle(this.doc.number === null ? 'Новый документ' : this.doc.number);
          }
          this.updateDocument();
          this.doc.attachments.forEach(x => x.statusRow = this.data.copy ? 'i' : 's');
          if (this.data.copy) {
            this.doc.id = this.fakeId--;
            this.doc.version = '1.0';
            this.doc.versions = [];
            this.doc.versions.push({
              id: this.fakeId--,
              version: '1.0',
              branchVersion: 1,
              subversion: 0,
              parentId: null,
              status: {
                id: 1,
                name: 'Создан'
              },
              isActive: false,
              createdAt: new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 0, 0, 0, 0),
              approvalDate: null
            });
            if (this.doc.versions) {
              this.selectedVersion = this.doc.versions.find(x => x.id === this.doc.id);
              if (!this.selectedVersion) {
                this.selectedVersion = this.doc.versions.find(x => x.id < 0);
              }
            }
            this.doc.isActive = false;
            this.doc.status = {
              id: 1,
              name: 'Создан'
            };
            this.doc.department = {
              id: this.authService.USER.official ?
                (this.authService.USER.official.department ? this.authService.USER.official.department.id : null) : null,
              name: this.authService.USER.official ?
                (this.authService.USER.official.department ? this.authService.USER.official.department.name : null) : null
            };
            if (!this.doc.department.id) {
              this.doc.contragent = {
                id: this.authService.USER && this.authService.USER.contragentId ?
                  this.authService.USER.contragentId : null,
                shortName: this.authService.USER && this.authService.USER.contragentId ?
                  this.authService.USER.contragent.shortName : null
              };
            }
            this.doc.official = this.authService.USER.official;
            this.doc.baseVersionId = null;
            this.doc.previousVersionId = null;
            this.doc.nextVersionId = null;
            this.doc.fileName = null;
            this.doc.files = [];
            this.doc.attachments = [];
            this.canEditHeadline = true;
            this.enabledSubmit = true;
            if (this.doc.objects) {
              this.doc.objects.forEach(x => {
                x.statusRow = 'i';
              });
            }
          }
          this.loading = false;
          this.isAgreementDisabled();
          if (this.initial === true) {
            this.confirmDialog.confirm('Внимание!',
              'Вы перешли на неактуальную версию документа. Открыть последнюю версию?', false).subscribe(
              result => {
                if (result) {
                  if (this.doc.versions) {
                    this.selectedVersion = this.doc.versions.find(x => x.id === this.data.initialId);
                    if (!this.selectedVersion) {
                      this.selectedVersion = this.doc.versions.find(x => x.id < 0);
                    }
                  }
                  this.documentService.getDocument(this.selectedVersion.id).subscribe(r => {
                    console.log(r);
                    this.doc = r;
                    this.titleService.setTitle(this.doc.number === null ? 'Новый документ' : this.doc.number);
                    this.updateDocument();
                    this.enabledSubmit = false;
                  });
                }
              });
            this.initial = false;
          }
        });
      } else {
        this.doc = {
          id: this.fakeId--,
          number: null,
          docsvisionId: null,
          linkSbis: null,
          isNumberFromFile: false,
          isInPackage: false,
          order_number: null,
          documentDate: new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 0, 0, 0, 0),
          version: '1.0',
          name: null,
          description: null,
          isActive: false,
          approvalDate: null,
          type: {
            id: null,
            name: null,
            fullName: null,
            isApprovalRequired: false,
            isVisible: true,
            isAutoNumbered: false,
            numberFromFile: false,
            section: null,
            isCommon: false,
            isContract: false,
            groupId: null,
            level: null,
            url: null
          },
          status: {
            id: 1,
            name: 'Создан'
          },
          parent: null,
          department: {
            id: this.authService.USER.official ?
              (this.authService.USER.official.department ? this.authService.USER.official.department.id : null) : null,
            name: this.authService.USER.official ?
              (this.authService.USER.official.department ? this.authService.USER.official.department.name : null) : null
          },
          contragent: {
            id: this.authService.USER && this.authService.USER.contragentId ?
              this.authService.USER.contragentId : null,
            shortName: this.authService.USER && this.authService.USER.contragentId ?
              this.authService.USER.contragent.shortName : null
          },
          official: this.authService.USER.official,
          documentType: null,
          documentYear: dateNow.toString(),
          year: dateNow.getFullYear(),
          fields: [],
          evalFields: [],
          versions: [],
          objects: [],
          objectsName: null,
          fileName: null,
          files: [],
          attachments: [],
          contract: {
            id: null,
            name: null
          }
        };
        console.log(this.doc);
        this.titleService.setTitle(this.doc.number === null ? 'Новый документ' : this.doc.number);
        this.doc.versions.push({
          id: this.fakeId--,
          version: '1.0',
          branchVersion: 1,
          subversion: 0,
          parentId: null,
          status: {
            id: 1,
            name: 'Создан'
          },
          isActive: false,
          createdAt: new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 0, 0, 0, 0),
          approvalDate: null
        });
        if (this.doc.versions) {
          this.selectedVersion = this.doc.versions.find(x => x.id === this.doc.id);
          if (!this.selectedVersion) {
            this.selectedVersion = this.doc.versions.find(x => x.id < 0);
          }
        }
        this.canEditHeadline = true;
        this.loading = false;
        this.editFields();
        this.isAgreementDisabled();
      }
    }
    this.setTypeLink();
  }

  editFields() {
    this.scrollHeight = 0;

    if (this.doc.fields && this.doc.fields.length > 0) {
      this.scrollHeight = 210;
      const fakeRow = 0;
      this.doc.fields.forEach(x => {
        if (!x.row) {
          x.row = fakeRow + 1;
        }
        if (!x.column) {
          x.column = 'L';
        }
        if (!x.required) {
          x.required = false;
        }
        // @ts-ignore
        x.realFieldId = x.fieldId;
        if (this.doc.type.id < 0) {
          if (x.fieldId < 0) {
            x.fieldId = -x.fieldId;
          }
        }
      });

      const maxRow = this.doc.fields.sort((a, b) => b.row - a.row)[0].row;

      for (let i = 1; i <= maxRow; i++) {
        const cnt = this.doc.fields.filter(x => x.row === i).length;
        if (cnt === 1) {
          this.doc.fields.filter(x => x.row === i).forEach(x => x.colSpan = '2');
        } else {
          this.doc.fields.filter(x => x.row === i).forEach(x => x.colSpan = '1');
        }
      }
      this.doc.fields = this.doc.fields.sort((a, b) => a.row === b.row ? (
            (a.column < b.column) ? -1 : (a.column > b.column) ? 1 : 0
          ) :
          (a.row < b.row) ? -1 : 1
      );
    }

    if (this.doc.evalFields && this.doc.evalFields.length > 0) {
      const fakeRow = 0;
      this.scrollHeight = 210;
      this.doc.evalFields.forEach(x => {
        if (!x.row) {
          x.row = fakeRow + 1;
        }
        if (!x.column) {
          x.column = 'L';
        }
      });

      const maxRow = this.doc.evalFields.sort((a, b) => b.row - a.row)[0].row;

      for (let i = 1; i <= maxRow; i++) {
        const cnt = this.doc.evalFields.filter(x => x.row === i).length;
        if (cnt === 1) {
          this.doc.evalFields.filter(x => x.row === i).forEach(x => x.colSpan = '2');
        } else {
          this.doc.evalFields.filter(x => x.row === i).forEach(x => x.colSpan = '1');
        }
      }
      this.doc.evalFields = this.doc.evalFields.sort((a, b) => a.row === b.row ? (
            (a.column < b.column) ? -1 : (a.column > b.column) ? 1 : 0
          ) :
          (a.row < b.row) ? -1 : 1
      );
    }
  }

  isEnabledSubmit(): void {
    if (this.doc) {
      this.visibleButton = !this.doc.status || (this.doc.status && ![2, 3, 5, 6, 7, 8, 9, 10].includes(this.doc.status.id) && !this.doc.schemaId);
      this.readOnlyDate = this.doc.status && [2, 3, 5, 6, 7, 8, 9, 10].includes(this.doc.status.id);
      this.readOnlyButton = this.readOnlyDate || !!this.doc.schemaId;
      this.enabledSubmit = true;
      this.isAgreementDisabled();
    }
  }

  isAgreementDisabled(): void {
    this.agreementDisabled = (!this.doc.id || this.doc.id < 0) && !this.enabledSubmit;
  }

  stringToNumber(val: string) {
    if (this.loading) {
      return;
    } else if (val === undefined || val === null) {
      return null;
    }
    return Number(val.toString().replace(',', '.'));
  }

  onDocumentFormSubmit(isAgreement = false, isNewVersion = false) {
    if (this.formComponent1) {
      const res = this.formComponent1.instance.validate();
      if (!res.isValid) {
        return;
      }
    }
    if (this.formComponent2) {
      const res = this.formComponent2.instance.validate();
      if (!res.isValid) {
        return;
      }
    }
    if (this.formFieldsComponent) {
      const res = this.formFieldsComponent.instance.validate();
      if (!res.isValid) {
        return;
      }
    }

    if (this.tableDocDocuments.validData()) {
      this.saveCard(isAgreement, isNewVersion);
    }
  }

  onSelectionChanged(e = null, id = null) {
    if (this.loading) {
      return;
    } else if (!e.component._valueChangeEventInstance || e && e.selectedItem && !e.selectedItem.id) {
      return;
    }

    if (id && id < 0) {
      this.doc.fields[id] = e.selectedItem.id;
    }

    console.log('Changed');
    this.isEnabledSubmit();
    this.ref.detectChanges();
  }

  onValueChanged(e = null) {
    if (this.loading) {
      return;
    } else if (e && e.value === undefined) {
      return;
    } else if (!e.component._isReady) {
      return;
    }
    if (e.dataField === 'documentDate') {
      this.changed = true;
    }
    console.log('Changed');
    this.isEnabledSubmit();
    this.ref.detectChanges();
  }

  getNewDocInfo() {
    if (this.doc.id === undefined) {
      this.doc.id = this.fakeId--;
      if (!this.doc.versions || this.doc.versions.length === 0) {
        const dateNow = new Date();
        this.doc.versions.push({
          id: this.fakeId--,
          version: '1.0',
          branchVersion: 1,
          subversion: 0,
          parentId: null,
          status: {
            id: 1,
            name: 'Создан'
          },
          isActive: false,
          createdAt: new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 0, 0, 0, 0),
          approvalDate: null
        });
      }
    }
    if (this.doc.objects) {
      this.doc.objectsName = this.doc.objects.map(x => (x.code ? x.code : '') + ' ' + (x.name ? x.name : '')).join('; ');
    }
    if (this.doc.versions) {
      for (const field of this.doc.versions) {
        if (field.subversion > 0) {
          field.parentId = this.doc.versions.find(x => x.branchVersion === field.branchVersion && x.subversion === 0).id;
        }
      }
    }
    this.doc.contragent = {
      id: this.authService.USER && this.authService.USER.contragentId ?
        this.authService.USER.contragentId : null,
      shortName: this.authService.USER && this.authService.USER.contragentId ?
        this.authService.USER.contragent.shortName : null
    };
    if (this.doc.versions) {
      this.selectedVersion = this.doc.versions.find(x => x.id === this.doc.id);
      if (!this.selectedVersion) {
        this.selectedVersion = this.doc.versions.find(x => x.id < 0);
      }
    }
    this.canEditHeadline = true;
    this.loading = false;
    this.editFields();
    this.isEnabledSubmit();
  }

  updateDocument() {
    this.dictionaryDocDocuments.hideSelectedColumn = this.doc.status && ![1, 4].includes(this.doc.status.id);
    this.dictionaryDocDocuments.disableDropDownButtons = this.doc.status && (![1, 4].includes(this.doc.status.id) || !!this.doc.schemaId);
    if (this.doc.files && this.doc.files.length > 0) {
      const sortedFiles = this.doc.files.sort((a, b) => b.version - a.version);
      this.doc.fileName = sortedFiles[0].name;
      this.doc.file = sortedFiles[0];
    }
    if (!this.doc.contragent) {
      this.doc.contragent = {
        id: null,
        shortName: null
      };
    }
    if (this.doc.attachments) {
      this.doc.attachments.forEach(x => x.statusRow = 's');
      for (const x of this.doc.attachments) {
        if (x.extension !== undefined && x.extension !== null && x.extension.length !== 0
          && x.extension !== 'tmp' && x.name.lastIndexOf(x.extension) <= 0) {
          x.name = x.name + x.extension;
        }
      }
      if (this.tableDocDocuments) {
        this.tableDocDocuments.dataGrid.instance.repaint();
      }
    }
    if (this.doc.objects) {
      this.doc.objects.filter(x => !x.statusRow).forEach(x => {
        x.statusRow = 'i';
      });
      this.doc.objectsName = this.doc.objects.map(x => (x.code ? x.code : '') + ' ' + (x.name ? x.name : '')).join('; ');
    }
    if (this.doc.versions) {
      if (this.doc.versions.length > 0) {
        for (const field of this.doc.versions) {
          if (field.subversion > 0) {
            field.parentId = this.doc.versions.find(x => x.branchVersion === field.branchVersion && x.subversion === 0).id;
          }
        }
      }
      this.selectedVersion = this.doc.versions.find(x => x.id === this.doc.id);
      if (!this.selectedVersion) {
        this.selectedVersion = this.doc.versions.find(x => x.id < 0);
      }
    }
    this.editFields();
    this.visibleButton = !this.doc.status || (this.doc.status && ![2, 3, 5, 6, 7, 8, 9, 10].includes(this.doc.status.id) && !this.doc.schemaId);
    this.readOnlyDate = this.doc.status && [2, 3, 5, 6, 7, 8, 9, 10].includes(this.doc.status.id);
    this.readOnlyButton = this.readOnlyDate || !!this.doc.schemaId;
  }

  ngAfterViewChecked(): void {
    this.addFilter();
    this.setTypeLink();
  }

  addFilter() {
    if (this.tableDocDocuments) {
      if (!this.isFiltered) {
        this.tableDocDocuments.dataGrid.instance.columnOption('attachmentType', 'selectedFilterOperation', '<>');
        this.tableDocDocuments.dataGrid.instance.columnOption('attachmentType', 'filterValue', 'to');
        this.tableDocDocuments.dataGrid.filterPanel.visible = false;
        this.isFiltered = true;
      }
    }
  }

  // Отображать документы, в которых привязан данный документ
  clearFilter() {
    if (this.tableDocDocuments) {
      if (this.isFilteredReal) {
        this.tableDocDocuments.dataGrid.instance.clearFilter();
        this.tableDocDocuments.btnDelete.option({
          disabled: true
        });
        this.doc.attachments.forEach(x => {
          if (x.statusRow === 'd') {
            x.statusRow = 's';
          }
        });
        this.dictionaryDocDocuments.hideSelectedColumn = true;
        this.AllDocDocuments = true;
        notify('Включен режим только на чтение для таблицы дополнительных файлов', 'warning', 2500);
      } else {
        this.tableDocDocuments.dataGrid.filterPanel.visible = true;
        this.tableDocDocuments.dataGrid.instance.columnOption('attachmentType', 'selectedFilterOperation', '<>');
        this.tableDocDocuments.dataGrid.instance.columnOption('attachmentType', 'filterValue', 'to');
        this.tableDocDocuments.dataGrid.filterPanel.visible = false;
        this.tableDocDocuments.dataGrid.instance.repaint();
        this.dictionaryDocDocuments.hideSelectedColumn = false;
        this.AllDocDocuments = false;
        notify('Включен режим редактирования для таблицы дополнительных файлов', 'warning', 2500);
      }
      this.isFilteredReal = !this.isFilteredReal;
    }
  }

  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  editHeadLine() {
    this.canEditHeadline = !this.canEditHeadline;
  }

  async saveCard(isAgreement = false, isNewVersion = false) {
    this.enabledSubmit = false;

    if ((this.doc.type && (this.doc.type.isAutoNumbered === false || this.doc.type.numberFromFile === true))
      && this.isEmpty(this.doc.number)) {
      notify('Номер документа обязателен к заполнению', 'warning', 2500);
      return;
    }
    if (!this.dateIsValid && this.changed) {
      notify('Дата документа находится в закрытом периоде', 'warning', 2500);
      return;

    }

    if (this.tableDocDocuments) {
      this.tableDocDocuments.closeEditor();
    }

    const fields = [];

    for (const field of this.doc.fields.filter(x => !['fakeField'].includes(x.fieldId))) {
      if (this.doc.fields[-field.fieldId]) {
        field.statusRow = field.id ? 'u' : 'i';
        if (field.dataType.name === 'date') {
          const _date = new Date(this.doc.fields[-field.fieldId]);
          const month = ('0' + (_date.getMonth() + 1)).slice(-2);
          const date = ('0' + _date.getDate()).slice(-2);
          field.value = `${_date.getFullYear()}/${month}/${date} 00:00:00`;
        } else {
          field.value = this.doc.fields[-field.fieldId].toString();
        }
      }
      if (field.name !== undefined) {
        fields.push(Object.assign({}, field));
      }
    }

    const type = await this.documentService.getTypeInfo(this.doc.type.id);
    fields.forEach(x => {
      const field = type.fields.find(f => Math.abs(x.fieldId) === Math.abs(f.fieldId) && x.name === f.name);
      if ((field.fieldId < 0 && x.fieldId > 0) || (field.fieldId > 0 && x.fieldId < 0)) {
        x.fieldId = -x.fieldId;
      }
    });
     if (this.uploadFile && this.uploadFile.size > 0) {
      this.readFile(this.uploadFile).subscribe(data => {
        this.documentService.uploadFile(data.split(',')[1], this.doc.fileName).subscribe(result => {
          if (this.doc.type !== undefined && this.doc.type !== null &&
            this.doc.type.id !== undefined && this.doc.type.id !== null && [-19, -20, -22, -30].includes(this.doc.type.id)) {
            this.readXLSX(this.uploadFile, this.doc.type.id).subscribe(dataXLSX => {
              const saveObject: any = {
                id: this.doc.id > 0 ? this.doc.id : undefined,
                number: this.doc.number,
                isNumberFromFile: this.doc.isNumberFromFile,
                isInPackage: this.doc.isInPackage,
                documentDate: this.doc.documentDate,
                name: this.doc.name,
                version: this.doc.version,
                description: this.doc.description,
                approvalDate: this.doc.approvalDate,
                typeId: this.doc.type ? this.doc.type.id : null,
                parentId: this.doc.parent ? this.doc.parent.id : null,
                departmentId: this.doc.contragent && this.doc.contragent.shortName ? -1 : (this.doc.department ? this.doc.department.id : null),
                contragentId: this.doc.contragent ? this.doc.contragent.id : null,
                officialId: this.doc.contragent && this.doc.contragent.shortName ? -1 : this.doc.official ? this.doc.official.id : null,
                year: this.doc.year,
                fields,
                objects: ![-5, -6, -7, -8, -9, -18].includes(this.doc.type.id) ? this.doc.objects : [],
                contractId: this.doc.contract ? this.doc.contract.id : null,
                baseVersionId: this.doc.baseVersionId,
                previousVersionId: this.doc.previousVersionId,
                nextVersionId: this.doc.nextVersionId,
                file: {
                  name: this.doc.fileName,
                  content: result,
                  lastModified: this.uploadFile.lastModified,
                  type: this.uploadFile.type,
                  size: this.uploadFile.size,
                  dataXLSX
                },
                files: this.doc.files,
                attachments: this.tableDocDocuments ? this.tableDocDocuments.getModifedRow().filter(x => x.attachmentType !== 'tmp' || x.typeId) : []
              };
              this.indicatorVisible = true;
              if (this.tmpUploadFiles.length > 0) {
                saveObject.uploadFile = this.tmpUploadFiles;
              }
              if (this.estimateInfo) {
                saveObject.estimateInfo = this.estimateInfo;
              }
              this.documentService.saveDocument(saveObject, saveObject.id).subscribe(obj => {
                this.afterSave(obj, isAgreement, isNewVersion, !saveObject.id);
                this.modified = true;
                this.uploadFile = null;
              }, error => {
                this.warningMessage = error.error;
                this.enabledSubmit = true;
                this.indicatorVisible = false;
              });
            }, error => {
              this.warningMessage = error.error;
              this.enabledSubmit = true;
              this.indicatorVisible = false;
            });
          } else {
            const saveObject: any = {
              id: this.doc.id > 0 ? this.doc.id : undefined,
              number: this.doc.number,
              isNumberFromFile: this.doc.isNumberFromFile,
              isInPackage: this.doc.isInPackage,
              documentDate: this.doc.documentDate,
              name: this.doc.name,
              version: this.doc.version,
              description: this.doc.description,
              approvalDate: this.doc.approvalDate,
              typeId: this.doc.type ? this.doc.type.id : null,
              parentId: this.doc.parent ? this.doc.parent.id : null,
              departmentId: this.doc.contragent && this.doc.contragent.shortName ? -1 : this.doc.department ? this.doc.department.id : null,
              contragentId: this.doc.contragent ? this.doc.contragent.id : null,
              officialId: this.doc.contragent && this.doc.contragent.shortName ? -1 : this.doc.official ? this.doc.official.id : null,
              year: this.doc.year,
              fields,
              objects: ![-5, -6, -7, -8, -9, -18].includes(this.doc.type.id) ? this.doc.objects : [],
              contractId: this.doc.contract ? this.doc.contract.id : null,
              baseVersionId: this.doc.baseVersionId,
              previousVersionId: this.doc.previousVersionId,
              nextVersionId: this.doc.nextVersionId,
              file: {
                name: this.doc.fileName,
                content: result,
                lastModified: this.uploadFile.lastModified,
                type: this.uploadFile.type,
                size: this.uploadFile.size
              },
              files: this.doc.files,
              attachments: this.tableDocDocuments ? this.tableDocDocuments.getModifedRow().filter(x => x.attachmentType !== 'tmp' || x.typeId) : []
            };
            this.indicatorVisible = true;
            if (this.tmpUploadFiles.length > 0) {
              saveObject.uploadFile = this.tmpUploadFiles;
            }
            if (this.estimateInfo) {
              saveObject.estimateInfo = this.estimateInfo;
            }
            this.documentService.saveDocument(saveObject, saveObject.id).subscribe(obj => {
              this.afterSave(obj, isAgreement, isNewVersion, !saveObject.id);
              this.modified = true;
              this.uploadFile = null;
            }, error => {
              this.warningMessage = error.error;
              this.enabledSubmit = true;
              this.indicatorVisible = false;
            });
          }
        }, error => {
          this.warningMessage = error.error;
          this.enabledSubmit = true;
          this.indicatorVisible = false;
        });
      }, error => {
        this.warningMessage = error.error;
        this.enabledSubmit = true;
        this.indicatorVisible = false;
      });
    } else {
      const saveObject: any = {
        id: this.doc.id > 0 ? this.doc.id : undefined,
        number: this.doc.number,
        isNumberFromFile: this.doc.isNumberFromFile,
        isInPackage: this.doc.isInPackage,
        documentDate: this.doc.documentDate,
        name: this.doc.name,
        version: this.doc.version,
        description: this.doc.description,
        approvalDate: this.doc.approvalDate,
        typeId: this.doc.type ? this.doc.type.id : null,
        parentId: this.doc.parent ? this.doc.parent.id : null,
        departmentId: this.doc.contragent && this.doc.contragent.shortName ? -1 : this.doc.department.id ? this.doc.department.id : null,
        contragentId: this.doc.contragent ? this.doc.contragent.id : null,
        officialId: this.doc.contragent && this.doc.contragent.shortName ? -1 : this.doc.official ? this.doc.official.id : null,
        year: this.doc.year,
        fields,
        objects: ![-5, -6, -7, -8, -9, -18].includes(this.doc.type.id) ? this.doc.objects : [],
        contractId: this.doc.contract ? this.doc.contract.id : null,
        baseVersionId: this.doc.baseVersionId,
        previousVersionId: this.doc.previousVersionId,
        nextVersionId: this.doc.nextVersionId,
        files: this.doc.files,
        attachments: this.tableDocDocuments ? this.tableDocDocuments.getModifedRow().filter(x => x.attachmentType !== 'tmp' || x.typeId) : []
      };
      this.indicatorVisible = true;
      if (this.tmpUploadFiles.length > 0) {
        saveObject.uploadFile = this.tmpUploadFiles;
      }
      if (this.estimateInfo) {
        saveObject.estimateInfo = this.estimateInfo;
      }
      this.documentService.saveDocument(saveObject, saveObject.id).subscribe(obj => {
        this.afterSave(obj, isAgreement, isNewVersion, !saveObject.id);
        this.modified = true;
      }, error => {
        this.warningMessage = error.error;
        this.enabledSubmit = true;
        this.indicatorVisible = false;
      });
    }
  }

  changed: boolean = false;

  afterSave(obj, isAgreement, isNewVersion, newRow) {
    this.messageInfo = null;
    this.warningMessage = null;
    console.log(obj);
    this.tmpUploadFiles = [];
    this.doc.objects = obj.objects;
    this.uploadFileComponent.clearFile();
    this.doc.id = obj.id;
    this.doc.baseVersionId = obj.baseVersionId;
    this.doc.files = [];
    this.dateIsValid = true;
    this.doc.fields = obj.fields;
    if (obj.files && obj.files.length > 0) {
      this.doc.files = obj.files;
    }
    this.doc.attachments = [];
    if (obj.attachments && obj.attachments.length > 0) {
      this.doc.attachments = obj.attachments;
    }
    this.updateDocument();
    this.indicatorVisible = false;
    this.canEditHeadline = false;
    this.agreementButton.refreshData(this.doc.id);
    if (!isAgreement) {
      this.newRow = {newRow, data: obj};
    } else {
      if (isNewVersion) {
        this.addNewVersion();
      } else {
        this.agreementButton.documentId = obj.id;
        this.getNewDocInfo();
        this.agreementButton.openAgreementInfo();
      }
    }
    this.messageInfo = !this.isEmpty(obj.messageInfo) ? ' ' + obj.messageInfo : '';
    this.dictionaryDocDocuments.dropDownButton[0].items[2].visible = this.doc.id > 0 && [-11, -36, -37].includes(this.doc.type.id) && (!this.doc.parent || !this.doc.parent.id);
    this.data.virtual = null;
    notify('Данные успешно сохранены!' + this.messageInfo, 'success', this.messageInfo ? 5000 : 1500);
  }

  toggleDefault() {
    this.defaultVisible = !this.defaultVisible;
  }

  closeDialog() {
    if (!this.enabledSubmit) {
      this.titleService.setTitle(this.titleBefore);
      if (this.newRow) {
        this.dialogRef.close({newRow: this.newRow, data: this.doc});
      } else {
        this.dialogRef.close(this.modified ? {data: this.doc} : null);
      }
    } else {
      this.confirmDialog.confirm('Подтверждение', 'Имеются не сохраненные данные! Сохранить перед выходом?', true).subscribe(
        result => {
          if (result) {
            this.onDocumentFormSubmit();
          } else if (result !== undefined) {
            this.titleService.setTitle(this.titleBefore);
            if (this.newRow) {
              this.dialogRef.close(this.newRow);
            } else {
              this.dialogRef.close(this.modified ? {data: this.doc} : null);
            }
          }
        });
    }
  }

  async onUploaded() {
    this.enabledSubmit = true;
    if (this.tmpUploadFiles.length > 0) {
      for (const x of this.tmpUploadFiles) {
        const uploadFile = await this.documentService.getUploadFileById(x);
        const curKeys = this.tableDocDocuments.getKeysDataSource();
        if (!curKeys.includes(uploadFile.id)) {
          this.tableDocDocuments.addNewRow(
            {
              id: uploadFile.id,
              name: uploadFile.name,
              extension: 'tmp',
              typeId: uploadFile.typeId,
              documentTypeName: uploadFile.documentTypeName,
              createdAt: uploadFile.createdAt,
              attachmentType: 'tmp'
            }, true
          );
        }
      }
    }
  }

  openParentCard() {
    window.open(window.location.origin + '#/documents/' + this.doc.parent.id,
      '_blank'
    );
  }

  openContractList() {
    window.open(
      window.location.origin + '#/contracts/' + this.doc.contract.id,
      '_blank'
    );
  }

  showReferences() {
    if (!this.dataSourceReference) {
      this.subscriptions.push(this.documentService.getHierarchy(this.doc.id).subscribe(data => {
        this.dataSourceReference = data.map(x => {
          x.widthExpr = 2;
          x.heightExpr = 1;
          if (x.id === this.doc.id) {
            x.style = {fill: '#93CCFD'};
          }
          return x;
        });
        this.loading = false;
        this.popupVisibleReference = true;
      }, error => {
        this.warningMessage = error.error;
        this.loading = false;
      }));
    } else {
      this.popupVisibleReference = true;
    }
  }

  allowDrop(e) {
    if (e.dataTransfer.items && e.dataTransfer.items.length === 1 && e.dataTransfer.items[0].kind === 'file' && [1, 4].includes(this.doc.status.id)) {
      const mainDiv = document.getElementById('mainDivElementDocument');
      if (mainDiv) {
        this.visibleDopPanel = true;
        const dropPanel = document.getElementById('dropPanel');
        if (dropPanel as HTMLElement) {
          (dropPanel as HTMLElement).style.width = mainDiv.offsetWidth + 30 + 'px';
          (dropPanel as HTMLElement).style.height = mainDiv.offsetHeight + 'px';
        }
      }
    }

  }

  drop() {
    this.visibleDopPanel = false;
  }

  dragleave() {
    console.log('dragleave');
    this.visibleDopPanel = false;
  }

  changeStatus(e) {
    this.modified = true;
    if (e) {
      this.doc.status = e.status;
      this.updateDocument();
      this.enabledSubmit = false;
      this.ref.detectChanges();
    } else {
      this.documentService.getDocument(this.doc.id).subscribe(data => {
        console.log(data);
        this.doc = data;
        this.titleService.setTitle(this.doc.number === null ? 'Новый документ' : this.doc.number);
        this.updateDocument();
        this.enabledSubmit = false;
        this.ref.detectChanges();
      });
    }
  }

  beforeAgreement() {
    if (!this.doc.id || this.doc.id < 0) {
      this.confirmDialog.confirm('Подтверждение', 'Имеются не сохраненные данные! Сохранить и отправить по маршруту?', false).subscribe(
        result => {
          if (result) {
            this.onDocumentFormSubmit(true);
          }
        });
    } else {
      this.agreementButton.openAgreementInfo();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
    this.subscriptions = [];
    if (this.diagram) this.diagram.instance.dispose();
    if (this.formComponent1) this.formComponent1.instance.dispose();
    if (this.formComponent2) this.formComponent2.instance.dispose();
    if (this.formFieldsComponent) this.formFieldsComponent.instance.dispose();
    if (this.versionsList) this.versionsList.instance.dispose();
    this.diagram = this.tableDocDocuments = this.formComponent1 = this.formComponent2 =
      this.formFieldsComponent = this.uploadFileComponent = this.agreementButton = this.versionsList = null;
  }

  itemTypeExpr(doc) {
    return 'doc_' + doc.id;
  }

  showInfoLinksDoc(e) {
    this.selectedLinkDocId = e.id;
    this.visibleLinksDocs = true;
    this.dataSourceLinkDoc.reload();
  }

  deleteDocumentClick(e) {
    const newFileIds = e.filter(x => x.attachmentType === 'tmp').map(x => x.id);
    if (newFileIds && newFileIds.length) {
      this.tmpUploadFiles = this.tmpUploadFiles.filter(x => !newFileIds.includes(x));
      this.uploadFileComponent.remove(newFileIds);
      this.tableDocDocuments.removeRows(newFileIds);
    }
  }

  documentDateValidation(e) {
    return new Promise((resolve, reject) => {
        this.documentService.checkValidationDocumentDate({
          id: this.doc.id ? this.doc.id : null,
          value: e.value,
          typeId: this.doc.type.id,
          month: this.estimateInfo ? this.estimateInfo.month : null,
          year: this.estimateInfo ? this.estimateInfo.year : null
        }).then((res: any) => {
            res.res ? resolve() : reject(res.message);
            this.validationMessageDate = res && res.actDate ? `Дата документа не может быть раньше даты выполнения работ (${res.first_date})` : "Дата документа находится в закрытом периоде"
            resolve(res.res);
            this.dateIsValid = (!!res.res);
          }
        ).catch(error => {
          console.log(error);
          reject();
        });
      }
    );
  }

  async createFile(typeFile) {
    try {
      this.indicatorVisible = true;
      const data = await this.documentService.createFile({
        id: this.doc.id,
        typeFile,
        ...this.tmpValues
      });
      if (this.tmpValues.sync)
        this.doc.number = this.tmpValues.number;
      data.forEach(x => {
        this.tmpUploadFiles.push(x.id);
        this.tableDocDocuments.addNewRow(
          {
            id: x.id,
            name: x.name,
            extension: 'tmp',
            typeId: typeFile,
            documentTypeName: null,
            createdAt: x.lastModified,
            attachmentType: 'tmp'
          }, true
        );
      });
      this.indicatorVisible = false;
      this.visibleInvoiceField = false;
    } catch (e) {
      this.indicatorVisible = false;
    }
  }

  createInvoice() {
    this.tmpValues['number'] = this.doc.number;
    this.tmpValues['date'] = this.doc.documentDate;
    this.tmpValues.sync = true;
    this.tmpValues.paymentNumber = this.tmpValues.paymentDate = null;
    this.visibleInvoiceField = true;
  }

  changedMainFile(data) {
    this.doc.fileName = data.name;
    this.doc.files.push(data);
  }

  showReport(data) {
    let reportType;
    switch (data.typeId) {
      case -38: reportType = 'invoice'; break;
    }
    if (!reportType) { return; }
    this.reportView.showReport({
      reportType,
      fileId: data.attachmentType !== 'tmp' && data.id || null,
      tmpId: data.attachmentType === 'tmp' && data.id || null,
    });
  }

}
