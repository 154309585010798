import {AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../../../../services/user.service';
import {UserInfo} from '../../../../../models/user.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ReferenceInformationService} from '../../../../../services/reference-information.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {faPen, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {DatePipe} from '@angular/common';
import {Dictionaries} from '../../dictionaries';
import {CommonChoiceComponent} from '../../../../reference-information/components/common-choice/common-choice.component';
import {ListGeneratorComponent} from '../../../../common-element/list-generator/list-generator.component';
import notify from 'devextreme/ui/notify';
import {TreeOrgUnitComponent} from '../../../../diagram-agreement/tree-org-unit/tree-org-unit.component';
import {ResetPasswordComponent} from '../reset-password.component';
import {DxFormComponent} from 'devextreme-angular';
import {ConfirmService} from '../../../../common-element/components/confirm/confirm.service';
import {ChooseExecutorComponent} from '../../../../common-element/choose-executor/choose-executor.component';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {ContragentComponent} from "../../../../object/contragent/contragent.component";
import {AuthenticationService} from "../../../../../services/authentication.service";


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [UserService]
})
export class UserComponent implements OnInit, AfterViewInit {
  user: UserInfo;

  fakeId = -1;
  enabledSubmit = false;
  loading = true;
  tabIndex = 0;

  canEditHeadline = false;
  faPen = faPen;
  faExclamationTriangle = faExclamationTriangle;
  indicatorVisible = false;

  dictionaryUserRoles: any;
  dictionaryUserRights: any;
  dictionaryUserLkContractStage: any;
  //@ViewChild('tableContractStages', {static: false}) tableContractStages: ListGeneratorComponent;
  @ViewChild('tableRoles', {static: false}) tableRoles: ListGeneratorComponent;
  @ViewChild('tableRights', {static: false}) tableRights: ListGeneratorComponent;
  @ViewChild('bFormComponent', {static: false}) bFormComponent: DxFormComponent;
  @ViewChild('formComponent', {static: false}) formComponent: DxFormComponent;
  @ViewChild('formComponent2', {static: false}) formComponent2: DxFormComponent;
  @ViewChild('formComponent3', {static: false}) formComponent3: DxFormComponent;
  @ViewChild('formComponent4', {static: false}) formComponent4: DxFormComponent;
  @ViewChild('delegation', {static: false}) delegation: any;
  /*//Этапы
  @Input() contractId = 0;
  dictionaryContractStages: any;
  contractStages: any;

   */

  isDeleted: boolean;
  profile: boolean;
  admin: any;
  lkk: false;
  userlk: any;
  scrollHeight = '30vh';
  scrollWidth = '55vw - 20px';

  constructor(private router: Router, private userService: UserService,
              public dialogRef: MatDialogRef<UserComponent>,
              public authService: AuthenticationService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private refService: ReferenceInformationService,
              public dialog: MatDialog,
              private ref: ChangeDetectorRef,
              private confirmDialog: ConfirmService, private activatedRoute: ActivatedRoute) {
    console.log(this.data);
    this.isDeleted = this.authService.USER.isDeleted;

    if (this.data.copy === undefined) {
      this.data.copy = false;
    }
    this.asyncValidationLogin = this.asyncValidationLogin.bind(this);
    this.asyncValidationEmail = this.asyncValidationEmail.bind(this);
  }

  chooseExecutor(): void {
    const dialogRef = this.dialog.open(
      ChooseExecutorComponent, {
        maxWidth: '40%',
        maxHeight: '90vh',
        data: {
          selectedRowKeys: this.user.official ? [this.user.official.id] : [],
          executors: null,
          singleSelect: true,
          canSelectDepartment: false,
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.user.fullDepartment = result.name.substr(result.name.indexOf('(') + 1).slice(0, -1);
        this.userService.getOfficial(result.id).subscribe(data => {
          console.log(data);
          if (!this.user.official) {
            this.user.official = {
              id: this.fakeId--,
              fullName: null,
              lastName: null,
              firstName: null,
              middleName: null,
              phone: null,
              email: null,
              position: null,
              department: null,
              departmentId: null
            };
          }
          if (!this.user.official.department) {
            this.user.official.department = {
              id: this.fakeId--,
              name: null
            };
          }

          this.user.lastName = this.user.official.lastName = data.lastName;
          this.user.firstName = this.user.official.firstName = data.firstName;
          this.user.middleName = this.user.official.middleName = data.middleName;
          this.user.fullName = data.fullName;
          this.user.official.id = data.id;
          this.user.official.position = data.position;
          this.user.fullOfficial = this.user.official.position;
          this.user.official.department.id = data.departmentId;
          this.user.official.department.name = this.user.fullDepartment;

          this.user.workPhone = data.phone;
          if (this.isEmpty(this.user.email)) {
            this.user.email = data.email;
          }

          this.isEnabledSubmit();
          this.ref.detectChanges();
        });
      }
    });
  }

  //chooseContragent(e): void {console.log(e);};
  chooseContragent(e): void {
    console.log(e);
    if (this.userlk.contragentId) {
      const dialogRef = this.dialog.open(ContragentComponent, {
        minWidth: '640px',
        maxWidth: '1280px',
        maxHeight: '90vh',
        data: {id: this.userlk.contragentId},
        disableClose: false
      });
      dialogRef.afterClosed().subscribe(() => {
      });
    } else {
      notify('Карточка контрагента отсутствует!', 'warning', 2000);
    }
  };

  ngOnInit(): void {
    this.canEditHeadline = !(this.data.id && this.data.id > 0);
    if (this.data.id > 0) {
        if (this.data.profile) {
          this.profile = this.data.profile;
        }


        this.userService.getUser(this.data.id, this.profile).subscribe(data => {
          this.user = data;
          if (!this.profile) {
            this.user.rights.forEach(x => x.statusRow = this.data.copy ? 'i' : 's');
            this.user.roles.forEach(x => x.statusRow = this.data.copy ? 'i' : 's');
          }

          const dateNow = new Date();
          if (data.notificationTime) {
            const splitted = data.notificationTime.split(':', 3);
            if (splitted.length === 3) {
              this.user.notificationTime = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), Number(splitted[0]), Number(splitted[1]), Number(splitted[2]), 0).toString();
            }
          }
          this.user.fullName = [this.user.lastName, this.user.firstName, this.user.middleName].join(' ').trim();
          const datePipe: DatePipe = new DatePipe('en-US');
          switch (this.user.status) {
            case 'locked':
              this.user.strStatus = 'Заблокирован';
              break;
            case 'active':
              this.user.strStatus = 'Активный';
              break;
            case 'deleted':
              this.user.strStatus = 'Удален ' + datePipe.transform(this.user.deletedAt, 'dd.MM.yyyy');
              break;
          }
          if (this.user.official) {
            this.user.fullOfficial = this.user.official.position;
            if (this.user.official.department) {
              this.user.fullDepartment = this.user.official.department.name;
            }
          }
          if (this.data.copy) {
            this.user.id = this.fakeId--;
            this.user.login = null;
            this.user.email = null;
            this.canEditHeadline = true;
            this.enabledSubmit = true;
          }
          if (this.user.status === 'deleted' && this.user.id > 0) {
            this.isEnabledSubmit();
          }
          console.log(data);
          this.loading = false;
        });
    } else {
      this.user = {
        id: this.fakeId--,
        status: null,
        strStatus: null,
        login: this.data && this.data.login,
        lastName: this.data && this.data.lastName,
        firstName: this.data && this.data.firstName,
        middleName: this.data && this.data.middleName,
        fullName: null,
        requestNumber: null,
        requestDate: null,
        notificationDays: null,
        notificationTime: null,
        notificationEmail: false,
        notificationPush: false,
        mobilePhone: null,
        workPhone: null,
        email: this.data && this.data.mail,
        isLocked: false,
        deletedAt: null,
        position: null,
        roles: [],
        official: null,
        fullOfficial: null,
        fullDepartment: null,
        rights: [],
        fromAd: this.data ? this.data.login != null : false,
        lockedSince: null,
        lockedUntil: null
      };
      this.resetSettings();
      this.canEditHeadline = true;
      this.loading = false;
    }
    this.dictionaryUserRoles = Dictionaries.dictionaries.find(x => x.id === 'user-roles');
    this.dictionaryUserRights = Dictionaries.dictionaries.find(x => x.id === 'user-rights');
  }

  ngAfterViewInit(): void {
    if (this.user) {
      this.enabledSubmit = this.user.id > 0;
    }
  }

  asyncValidationLogin(params) {
    if (this.user.id > 0 && params.value === this.user.login) {
      return new Promise((resolve) => {
          resolve(true);
        }
      );
    }
    return new Promise((resolve) => {
      this.userService.checkUser('login', params.value).subscribe(data => {
          resolve(data.result);
        }, () => {
          resolve(false);
        }
      );
    });
  }

  asyncValidationEmail(params) {
    if (this.user.id > 0 && params.value === this.user.email) {
      return new Promise((resolve) => {
          resolve(true);
        }
      );
    }
    return new Promise((resolve) => {
      this.userService.checkUser('email', params.value).subscribe(data => {
          resolve(data.result);
        }, () => {
          resolve(false);
        }
      );
    });
  }

  onFormSubmit(e) {
    if (!this.profile) {
      if (this.user.lockedUntil && this.user.lockedSince && this.user.lockedUntil < this.user.lockedSince) {
        notify('Дата блокировки "ДО" должна быть больше даты "C"!', 'error', 1500);
        e.preventDefault();
        return;
      }
      if (this.bFormComponent) {
        const res = this.bFormComponent.instance.validate();
        if (!res.isValid) {
          e.preventDefault();
          return;
        }
      }
      if (this.formComponent) {
        const res = this.formComponent.instance.validate();
        if (!res.isValid) {
          e.preventDefault();
          return;
        }
      }
      if (this.formComponent2) {
        const res = this.formComponent2.instance.validate();
        if (!res.isValid) {
          e.preventDefault();
          return;
        }
      }

      if (this.tableRoles) {
        if (!this.tableRoles.validData()) {
          e.preventDefault();
          return;
        }
      }
      if (this.tableRights) {
        if (!this.tableRights.validData()) {
          e.preventDefault();
          return;
        }
      }
    } else {
      if (this.formComponent3) {
        const res = this.formComponent3.instance.validate();
        if (!res.isValid) {
          e.preventDefault();
          return;
        }
      }
      if (this.formComponent4) {
        const res = this.formComponent4.instance.validate();
        if (!res.isValid) {
          e.preventDefault();
          return;
        }
      }
    }
    this.saveCard();
    e.preventDefault();
  }

  onValueChanged(e = null) {
    if (this.loading) {
      return;
    } else if (e && e.value === undefined) {
      return;
    }

    console.log('Changed');
    this.isEnabledSubmit();
    this.ref.detectChanges();
  }

  isEnabledSubmit(): void {
    if (this.user) {
       if (!this.profile) {
        this.enabledSubmit = (!this.isEmpty(this.user.firstName)
          && !this.isEmpty(this.user.lastName)
          && !this.isEmpty(this.user.login)
          && !this.isEmpty(this.user.email)
          && !this.isEmpty(this.user.requestNumber)
          && !this.isEmpty(this.user.requestDate)
          && !this.isEmpty(this.user.fullOfficial));
      } else {
        this.enabledSubmit = !this.isEmpty(this.user.firstName)
          && !this.isEmpty(this.user.lastName)
          && !this.isEmpty(this.user.login)
          && !this.isEmpty(this.user.email);
      }
    }
  }

  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  addRight() {
    const dialogRef = this.dialog.open(TreeOrgUnitComponent, {
      maxWidth: '50%',
      height: '80vh',
      data: {
        selectedRowKeys: this.user.rights.map(x => x.department.id)
      },
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        for (const r of result) {
          if (!this.user.rights.map(x => x.department.id).includes(r.id) && r.id > 0) {
            this.tableRights.addNewRow({
              id: this.fakeId--,
              departmentPermission: 'R',
              mask: null,
              maskRight: null,
              departmentId: r.id,
              department: {
                id: r.id,
                name: r.name,
                code: r.code
              }
            });
          }
        }
      }
    });
  }

  addRole() {
    if (this.data.lkk == true) {
      const dialogRef = this.dialog.open(CommonChoiceComponent, {
        width: '600px',
        maxHeight: '90vh',
        data: {
          lkk: true,
          selected: this.user.roles.map(x => x.id),
          dictionary: 'choiceRolesLk'
        },
        disableClose: false
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          for (const r of result) {
            this.tableRoles.addNewRow({
              id: r.id,
              name: r.name,
              code: r.code,
              status: null,
              description: null,
              deletedAt: null,
              areas: [],
              roleAreas: [],
              roleDocuments: []
            });
          }
        }
      });
    } else {
      const dialogRef = this.dialog.open(CommonChoiceComponent, {
        width: '600px',
        maxHeight: '90vh',
        data: {
          selected: this.user.roles.map(x => x.id),
          dictionary: 'choiceRoles'
        },
        disableClose: false
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          for (const r of result) {
            this.tableRoles.addNewRow({
              id: r.id,
              name: r.name,
              code: r.code,
              status: null,
              description: null,
              deletedAt: null,
              areas: [],
              roleAreas: [],
              roleDocuments: []
            });
          }
        }
      });
    }
  }

  editHeadLine() {
    this.canEditHeadline = !this.canEditHeadline;
  }

  saveCard() {
    if (this.tableRoles) {
      this.tableRoles.closeEditor();
    }
    if (this.tableRights) {
      this.tableRights.closeEditor();
    }

    if (this.delegation && !this.delegation.checkValidation()) {
      notify('Проверьте корректость данных в таблице делегирования прав!', 'error', 2500);
      return;
    }

    const saveObject = {
      id: this.user.id > 0 ? this.user.id : undefined,
      status: this.user.status,
      login: this.user.login,
      email: this.user.email,
      mobilePhone: this.user.mobilePhone,
      workPhone: this.user.workPhone,
      lastName: this.user.lastName,
      firstName: this.user.firstName,
      middleName: this.user.middleName,
      requestNumber: this.user.requestNumber,
      requestDate: this.user.requestDate,
      notificationDays: this.user.notificationDays,
      notificationTime: this.isEmpty(this.user.notificationTime) ?
        null :
        (this.user.notificationTime.toString().length > 8 ?
          new Date(this.user.notificationTime.toString()).toLocaleTimeString() :
          this.user.notificationTime),
      notificationEmail: this.user.notificationEmail,
      notificationPush: this.user.notificationPush,
      officialId: this.user.official && this.user.official.id,
      roles: this.tableRoles ? this.tableRoles.getModifedRow() : [],
      rights: this.tableRights ? this.tableRights.getModifedRow() : [],
      fromAd: this.user.fromAd,
      lockedSince: this.user.lockedSince,
      lockedUntil: this.user.lockedUntil
    };
    this.indicatorVisible = true;
    if (this.data.lkk == true) {
      this.userService.saveUserLk(saveObject, saveObject.id).subscribe(data => {
        if (this.delegation) {
          this.delegation.saveData();
        }
        this.indicatorVisible = false;
        this.dialogRef.close({newRow: !saveObject.id, data});
        notify('Данные успешно сохранены!', 'success', 1500);
      }, error => {
        this.indicatorVisible = false;
        // notify(error.message, 'error', 2500);
      });
    } else {
      this.userService.saveUser(saveObject, saveObject.id, this.profile).subscribe(data => {
        if (this.delegation) {
          this.delegation.saveData();
        }
        this.indicatorVisible = false;
        this.dialogRef.close({newRow: !saveObject.id, data});
        notify('Данные успешно сохранены!', 'success', 1500);
      }, error => {
        this.indicatorVisible = false;
        // notify(error.message, 'error', 2500);
      });
    }
  }

  resetSettings() {
    this.user.notificationDays = 3;
    const dateNow = new Date();
    this.user.notificationTime = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 9, 0, 0, 0).toString();
    this.user.notificationEmail = true;
    this.user.notificationPush = true;
  }

  resetPassword() {
    this.dialog.open(ResetPasswordComponent, {
      maxWidth: '1280px',
      maxHeight: '90vh',
      disableClose: false
    });
  }

  closeDialog() {
    if (!this.enabledSubmit) {
      this.dialogRef.close();
    } else {
      this.confirmDialog.confirm('Подтверждение', 'Имеются не сохраненные данные! Сохранить перед выходом?', true).subscribe(
        result => {
          if (result) {
            this.saveCard();
          } else if (result !== undefined) {
            this.dialogRef.close();
          }
        });
    }
  }

  changeTabIndex(e) {
    this.tabIndex = e;
  }
}
