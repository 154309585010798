import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MonitoringActComponent} from "./reports/monitoring-act/monitoring-act.component";
import {ComparePlansComponent} from "./reports/compare-plans/compare-plans.component";


@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss']
})
export class ReportHeaderComponent implements OnInit, OnDestroy {

  reportName;
  @ViewChild('monitoringAct', {static: false}) monitoringAct: MonitoringActComponent;
  @ViewChild('comparePlans', {static: false}) comparePlans: ComparePlansComponent;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if(this.monitoringAct) this.monitoringAct = null;
    if(this.comparePlans) this.comparePlans = null;
  }

  showReport(nameReport) {
    this.reportName = name;
    if (this[nameReport])
      this[nameReport].show();
  }

}
