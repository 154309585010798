import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

  public genFilePlansReport(name, id, extension, fileName) {
    return this.http.post<any>(`api/report/file`, {name, id, extension, fileName}
      /*{headers: new HttpHeaders({ timeout: `300000` })}*/
    );
  }
}
