import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Dictionaries} from '../dictionaries';
import {gridDictionary} from '../../common-element/list-generator/generator-interfaces';
import {ListGeneratorComponent} from '../../common-element/list-generator/list-generator.component';
import {isBoolean} from 'util';
import {DocumentService} from '../../../services/document.service';
import {AddDocumentComponent} from '../document/add-document.component';
import {DocumentComponent} from '../document/document.component';

@Component({
  selector: 'app-documents-chooser',
  templateUrl: './documents-chooser.component.html',
  styleUrls: ['./documents-chooser.component.scss'],
  providers: [DocumentService]
})
export class DocumentsChooserComponent implements OnInit, OnDestroy {
  dictionary: gridDictionary;
  getUrl: string;
  form: string;
  objectId: string;
  @ViewChild('gridDocs', {static: false}) tableDocs: ListGeneratorComponent;
  selectedRowKeys: any[] = [];

  constructor(public dialogRef: MatDialogRef<DocumentsChooserComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog) {
    this.form = this.data.form;
    if (this.data.objectId) {
      this.objectId = this.data.objectId;
    }
  }

  ngOnInit() {
    this.dictionary = JSON.parse(JSON.stringify(Dictionaries.dictionaries.find(x => x.id === 'choose-documents')));
    if (this.data.allowAdd === false) this.dictionary.allowAdd = false;
    this.getUrl = this.dictionary.get;
    if (isBoolean(this.data.multi)) {
      this.dictionary.hideSelectedColumn = !this.data.multi;
      this.dictionary.allowChoice = this.data.multi;
    }
    if (!this.isEmpty(this.data.form) && !this.isEmpty(this.data.objectId) && !this.dictionary.get.includes('form=') && !this.dictionary.get.includes('objectId=')) {
      this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + 'form=' + this.data.form + '&objectId=' + this.data.objectId;
    } else if (!this.isEmpty(this.data.objectId) && this.isEmpty(this.data.form) && !this.dictionary.get.includes('objectId=')) {
      this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + 'objectId=' + this.data.objectId;
    } else if (this.isEmpty(this.data.objectId) && !this.isEmpty(this.data.form) && !this.dictionary.get.includes('form=')) {
      this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + 'form=' + this.data.form;
    }

    if (this.data.linkObject && this.data.linkObject === true) {
      this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + 'common=false';
    }
    if (this.data.hideCommon) {
      this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + 'hideCommon=true';
    }

    if (this.data.documentTypes && this.data.documentTypes.length > 0) {
      this.data.documentTypes.forEach(x => this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + 'documentTypes=' + x);
    }
    if (this.data.packageDocId)
      this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + 'packageDocId=' + this.data.packageDocId;
    if (this.data.hideIds)
      this.data.hideIds.forEach(x => this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + 'hideIds=' + x);

    if (this.data.typeLink) {
      this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + 'typeLink=' + this.data.typeLink;
      const column = this.dictionary.columns.find(x => x.dataField === 'object');
      column.caption = this.data.typeLink === 'title' && 'ИП/Элементы ИП' || 'Объект/Подобъект';
      if (this.data.filteringOwnObject && this.data.mainDocumentId) {
        this.dictionary.toolbarTemplate = this.dictionary.toolbarTemplate || [];
        this.dictionary.toolbarTemplate.push({location: 'before', template: 'filteringOwnObject'});
        this.dictionary.queryParam = [['mainDocumentId', this.data.mainDocumentId]];
      }
    }

    ['journal'].forEach(x => this.data[x]
      && (this.dictionary.get += `${(this.dictionary.get.includes('?') && '&'  || '?')}${x}=${this.data[x]}`));

    this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + 'requireTotalCount=true';
  }

  ngOnDestroy(): void {
    this.dictionary.get = this.getUrl;
  }

  closeDialog() {
    this.dictionary.get = this.getUrl;
    this.dialogRef.close();
  }

  choiceClick(data) {
    if (!this.data.multi) {
      return;
    }
    this.dictionary.get = this.getUrl;
    this.dialogRef.close(data);
  }

  rowClick(rowInfo, newRow = false) {
    if (newRow) {
      let dialogRef: any;
      if (this.data.form) {
        dialogRef = this.dialog.open(AddDocumentComponent, {
          minWidth: '640px',
          maxWidth: '1280px',
          maxHeight: '90vh',
          disableClose: true,
          data: {form: this.form, objectId: this.objectId}
        });
      } else {
        dialogRef = this.dialog.open(AddDocumentComponent, {
          minWidth: '640px',
          maxWidth: '1280px',
          maxHeight: '90vh',
          disableClose: true
        });
      }
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.result && result.result.newRow) {
          if (this.data.multi) {
            this.tableDocs.addNewRow(result.result.data, false);
            if (this.tableDocs.selectedRowKeys) {
              this.selectedRowKeys = this.tableDocs.selectedRowKeys;
            }
            this.selectedRowKeys.push(result.result.data.id);
            this.tableDocs.selectedRow(this.selectedRowKeys, true);
          } else {
            this.dictionary.get = this.getUrl;
            this.dialogRef.close({document: result.result.data});
          }
        }
      });
    } else {
      if (this.data.multi) {
        this.openDocumentCard(rowInfo.id);
      } else {
        this.dictionary.get = this.getUrl;
        this.dialogRef.close({document: rowInfo});
      }
    }
  }

  openDocumentCard(id) {
    const dialogRef = this.dialog.open(DocumentComponent, {
      minWidth: '30%',
      maxWidth: '85%',
      maxHeight: '90vh',
      data: {id: !!id ? id : 0},
      disableClose: true
    });
  }

  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  buttonIconClick(e) {
    console.log(e);
    if (e.id === 'download' && e.data) {
      this.openDocumentCard(e.data.id);
    }
  }
}
