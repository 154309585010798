import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListGeneratorComponent} from './list-generator/list-generator.component';
import {HeaderComponent} from './header/header.component';
import {
  MatButtonModule,
  MatExpansionModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatSidenavModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {RouterModule} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DevExtremeModule} from 'devextreme-angular';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {UserComponent} from '../service/service/users/user/user.component';
import {UserLkComponent} from '../service/service/users/user-lk/user-lk.component';
import {ResetPasswordComponent} from '../service/service/users/reset-password.component';
import {HideDirective} from './hide.directive';
import {ConfirmComponent} from './components/confirm/confirm.component';
import {ConfirmService} from './components/confirm/confirm.service';
import {SelectorComponent} from './components/selector/selector.component';
import {UploadFileComponent} from './upload-file/upload-file.component';
import {UserOrgListComponent} from './user-org-list/user-org-list.component';
import {DelegationComponent} from './delegation/delegation.component';
import {SubContractorsComponent} from './subcontractors/subcontractors.component';
import {ChooseExecutorComponent} from './choose-executor/choose-executor.component';
import {TreeObjectHierarchyComponent} from './tree-object-hierarchy/tree-object-hierarchy.component';
import {CommentsComponent} from './comments/comments.component';
import {FormsModule} from '@angular/forms';
import {PasswordStrengthBarComponent} from '../service/service/users/password-strength-bar.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CopyLinkComponent} from './copy-link/copy-link.component';
import {ObjectService} from '../../services/object.service';
import {ObjectSearchComponent} from './search/object-search/object-search.component';
import {ChooseExecutorComponentNew} from './choose-executor-new/choose-executor-new.component';
import {InvestService} from '../../services/invest.service';
import {ContragentComponent} from "../object/contragent/contragent.component";
import {AdminAreaService} from '../../services/admin-area.service';
import {FinancePlansService} from "../plans/finance-plans.service";
import {EnterCharactersComponent} from "./enter-characters/enter-characters.component";
import {AdderComponent} from "./components/adder/adder.component";
import { WhatnewsComponent } from './whatnews/whatnews.component';
import {LinkedInvNumbers} from "../subobjects/linked-inv-numbers/linked-inv-numbers.component";
import { ReportHeaderComponent } from './report-header/report-header.component';
import { MonitoringActComponent } from './report-header/reports/monitoring-act/monitoring-act.component';
import { ComparePlansComponent } from './report-header/reports/compare-plans/compare-plans.component';
import { SelectPlanComponent } from './report-header/reports/select-plan/select-plan.component';
import { NavbarComponent } from './navbar/navbar.component';
import { VersionComponent } from './version/version.component';
import {FormatNumberPipe} from "../../pipe/format.pipe";
import { AgreementAdditionalExecutorComponent } from './agreement-aditional-executor/agreement-aditional-executor.component';
import {ChangePasswordComponent} from "./change-password/change-password.component";

@NgModule({
  imports: [CommonModule, MatToolbarModule, RouterModule, MatMenuModule, FontAwesomeModule, DevExtremeModule, MatButtonModule, MatTooltipModule,
    MatDividerModule, MatDialogModule, MatSidenavModule, MatIconModule, MatExpansionModule, MatListModule, FormsModule, NgbModule],
  declarations: [ListGeneratorComponent, HeaderComponent, UserComponent,UserLkComponent,ContragentComponent, ResetPasswordComponent, PasswordStrengthBarComponent, HideDirective,
    ConfirmComponent, SelectorComponent, AdderComponent, UploadFileComponent, UserOrgListComponent, TreeObjectHierarchyComponent, DelegationComponent,SubContractorsComponent,
    ChooseExecutorComponent, CommentsComponent, CopyLinkComponent, ObjectSearchComponent, ChooseExecutorComponentNew, EnterCharactersComponent, WhatnewsComponent, LinkedInvNumbers,
    ReportHeaderComponent, MonitoringActComponent, ComparePlansComponent, SelectPlanComponent, NavbarComponent, VersionComponent, FormatNumberPipe, AgreementAdditionalExecutorComponent, ChangePasswordComponent],
    exports: [ListGeneratorComponent, HeaderComponent, UserComponent, UserLkComponent, ContragentComponent, ResetPasswordComponent, PasswordStrengthBarComponent, UploadFileComponent,
        UserOrgListComponent, TreeObjectHierarchyComponent, ChooseExecutorComponent, DelegationComponent, SubContractorsComponent, CommentsComponent, CopyLinkComponent,
        ChooseExecutorComponentNew, EnterCharactersComponent, NavbarComponent, VersionComponent, HideDirective, FormatNumberPipe, AgreementAdditionalExecutorComponent, ChangePasswordComponent],
  providers: [ConfirmService, ObjectService, InvestService, AdminAreaService,FinancePlansService, EnterCharactersComponent],
  entryComponents: [UserComponent,UserLkComponent,ContragentComponent, ResetPasswordComponent, PasswordStrengthBarComponent, ConfirmComponent,AdderComponent, SelectorComponent, UserOrgListComponent,
    TreeObjectHierarchyComponent, ChooseExecutorComponent, EnterCharactersComponent, LinkedInvNumbers]
})
export class CommonElementModule {
}
