import {gridDictionary} from '../common-element/list-generator/generator-interfaces';

export class Dictionaries {
  public static dictionaries: gridDictionary[] = [
    {
      id: 'Objects',
      name: 'Объекты',
      fileNameExport: 'Объекты',
      get: 'api/object/list',
      primaryKey: 'id',
      remoteOperations: true,
      countPage: 15,
      wordWrap: true,
      allowRefresh: true,
      allowAdd: true,
      hideRowStatus: true,
      showCountRow: true,
      editRole: 'objects',
      columns: [
        {
          caption: 'Код объекта КС',
          dataField: 'code',
          dataType: 'string'
        },
        {
          caption: 'Наименование объекта КС',
          dataField: 'fullName',
          dataType: 'string'
        },
        {
          caption: 'Версия',
          dataField: 'version',
          dataType: 'string'
        },
        {
          caption: 'Статус',
          dataField: 'status',
          dataType: 'string'
        },
        {
          caption: 'Состояние работ',
          dataField: 'workState',
          dataType: 'string'
        },
        {
          caption: 'Филиал',
          dataField: 'filial',
          dataType: 'string'
        },
        {
          caption: 'Ответственное подразделение',
          dataField: 'department',
          dataType: 'string'
        },
        {
          caption: 'Укрупненная полная стоимость (тыс.руб)',
          dataField: 'estimatedCost',
          dataType: 'number',
          format: '###,###,###,###,##0'
        },
        {
          caption: 'Дата начала строительства',
          dataField: 'planStartDate',
          dataType: 'date',
          format: 'MM.yyyy'
        },
        {
          caption: 'Дата завершения строительства',
          dataField: 'planEndDate',
          dataType: 'date',
          format: 'MM.yyyy'
        }]
    },
    {
      id: 'subobject-enter',
      showCaption: true,
      name: 'Ввод в ОФ',
      fileNameExport: 'Редактирование ввода в ОФ',
      showCountRow: true,
      fastDelete: false,
      primaryKey: 'id',
      remoteOperations: false,
      countPage: 20,
      editMode: 'cell',
      allowUpdate: true,
      // editRole: '',
      columnAutoWidth: true,
      wordWrap: true,
      allowRefresh: true,
      customButton: [{
        text: 'Экспортировать в Парус',
        hint: 'Экспортировать в Парус',
        value: 'sail',
        icon: 'sail-icon'
      }, {
        text: 'Расшифровка СМР и ПНР',
        hint: 'Расшифровка СМР и ПНР',
        value: 'smrDetail',
        icon: 'insertcolumnright'
      }],
      allowReport: 'Переформировать КС-14',
      columns: [
        {
          caption: 'Наименование подобъекта',
          allowEdit: false,
          dataField: 'subobject_name',
          dataType: 'string',
          cellTemplate: 'longTextTemplate',
          width: 200,
          captionTooltipText: 'Наименование подобъекта'
        }, {
          caption: 'Код подобъекта',
          allowEdit: false,
          dataField: 'code',
          dataType: 'string',
          cellTemplate: 'longTextTemplate',
          width: 300,
          captionTooltipText: 'Код подобъекта'
        }, {
          caption: 'Физ. хар-ки',
          dataField: 'physicalCharacteristics',
          dataType: 'number',
          allowEdit: false,
          cellTemplate: 'columnBolt',
          width: 80,
          buttons: [
            {
              icon: 'fa fa-eye',
              hint: 'Физ. хар-ки',
              id: 'sub'
            }
          ],
          captionTooltipText: 'Открыть физические характеристики (факт)'
        }, {
          caption: 'Номер документа',
          allowEdit: false,
          dataField: 'document_number',
          dataType: 'string',
          cellTemplate: 'longTextTemplate',
          width: 300,
          captionTooltipText: 'Номер документа'
        }, {
          caption: 'Дата документа',
          allowEdit: false,
          dataField: 'document_date',
          dataType: 'date',
          captionTooltipText: 'Дата документа'
        }, {
          caption: 'Наименование документа',
          allowEdit: false,
          dataField: 'document_name',
          dataType: 'string',
          captionTooltipText: 'Наименование документа',
          buttons: [{
            icon: 'fa fa-eye',
            hint: 'Открыть',
            id: 'open',
            show: data => data.document_id ? 'block' : 'none'
          }
          ]
        }, {
          caption: 'Статус документа',
          allowEdit: false,
          dataField: 'document_status',
          dataType: 'string',
          captionTooltipText: 'Статус документа'
        }, {
          caption: 'ПИР',
          allowEdit: true,
          dataField: 'pir',
          dataType: 'number',
          summaryType: 'customSummary',
          captionTooltipText: 'ПИР',
          minWidth: 80
        }, {
          caption: 'СМР',
          allowEdit: true,
          dataField: 'smr',
          dataType: 'number',
          summaryType: 'customSummary',
          captionTooltipText: 'СМР',
          minWidth: 80
        }, {
          caption: 'Расшифровка СМР и ПНР',
          allowEdit: false,
          dataField: 'smrDetail',
          dataType: 'number',
          columns: [],
          visible: false
        }, {
          caption: 'Поставка оборудования',
          allowEdit: true,
          dataField: 'po',
          dataType: 'number',
          summaryType: 'customSummary',
          captionTooltipText: 'Поставка оборудования',
          minWidth: 80
        }, {
          caption: 'Прочее',
          allowEdit: true,
          dataField: 'other',
          dataType: 'number',
          summaryType: 'customSummary',
          captionTooltipText: 'Прочее',
          minWidth: 80
        }, {
          caption: 'ПНР',
          allowEdit: true,
          dataField: 'pnr',
          dataType: 'number',
          summaryType: 'customSummary',
          captionTooltipText: 'ПНР',
          minWidth: 80
        }, {
          caption: 'Затраты ОКС/УКС',
          allowEdit: true,
          dataField: 'expenses',
          dataType: 'number',
          summaryType: 'customSummary',
          captionTooltipText: 'Затраты ОКС/УКС',
          minWidth: 80
        }, {
          caption: 'Охранные зоны',
          allowEdit: true,
          dataField: 'guards',
          dataType: 'number',
          summaryType: 'customSummary',
          captionTooltipText: 'Охранные зоны',
          minWidth: 80
        }]
    },
    {
      id: 'building-control-enter-of-selected',
      showCaption: false,
      name: 'Процесс формирования КС-14',
      fileNameExport: 'Процесс формирования КС-14',
      hideSelectedColumn: true,
      showCountRow: true,
      fastDelete: false,
      primaryKey: 'id',
      remoteOperations: false,
      countPage: 20,
      editMode: 'cell',
      allowUpdate: true,
      // editRole: '',
      columnAutoWidth: true,
      wordWrap: true,
      allowRefresh: true,
      customButton: [
        {
          text: 'КC-14',
          hint: 'КС-14',
          icon: 'add',
          value: 'add-ks-14'
        }, {
          text: 'Расшифровка СМР и ПНР',
          hint: 'Расшифровка СМР и ПНР',
          value: 'smrDetail',
          icon: 'insertcolumnright'
        }
      ],
      columns: [
        {
          caption: 'Наименование подобъекта',
          allowEdit: false,
          dataField: 'name',
          dataType: 'string',
          cellTemplate: 'longTextTemplate',
          minWidth: 200,
          captionTooltipText: 'Наименование подобъекта',
        },
        {
          caption: 'Физ. хар-ки (факт)',
          dataField: 'physicalCharacteristics',
          dataType: 'number',
          allowEdit: false,
          cellTemplate: 'columnBolt',
          minWidth: 120,
          buttons: [
            {
              icon: 'fa fa-eye',
              hint: 'Физ. хар-ки',
              id: 'sub'
            }
          ],
          captionTooltipText: 'Открыть физические характеристики (факт)'
        }, {
          caption: 'Сумма НЗС',
          allowEdit: false,
          dataField: 'currentNzs',
          dataType: 'number',
          captionTooltipText: 'Сумма НЗС',
          summaryType: 'sum',
          format: '#,##0.##',
          width: 120
        }, {
          caption: 'ПИР',
          allowEdit: true,
          dataField: 'pir',
          dataType: 'number',
          captionTooltipText: 'ПИР',
          summaryType: 'sum',
          format: '#,##0.##',
          width: 120
        }, {
          caption: 'СМР',
          allowEdit: true,
          dataField: 'smr',
          dataType: 'number',
          captionTooltipText: 'СМР',
          summaryType: 'sum',
          format: '#,##0.##',
          width: 120
        }, {
          caption: 'Расшифровка СМР и ПНР',
          allowEdit: false,
          dataField: 'smrDetail',
          dataType: 'number',
          columns: [],
          visible: false
        }, {
          caption: 'ПНР',
          allowEdit: true,
          dataField: 'pnr',
          dataType: 'number',
          captionTooltipText: 'ПНР',
          summaryType: 'sum',
          format: '#,##0.##',
          width: 120
        }, {
          caption: 'Прочее',
          allowEdit: true,
          dataField: 'other',
          dataType: 'number',
          captionTooltipText: 'Прочее',
          summaryType: 'sum',
          format: '#,##0.##',
          width: 120
        }, {
          caption: 'Поставка оборудования',
          allowEdit: true,
          dataField: 'po',
          dataType: 'number',
          captionTooltipText: 'Поставка оборудования',
          summaryType: 'sum',
          format: '#,##0.##',
          width: 120
        }, {
          caption: 'Затраты ОКС/УКС',
          allowEdit: true,
          dataField: 'expenses',
          dataType: 'number',
          captionTooltipText: 'Затраты ОКС/УКС',
          summaryType: 'sum',
          format: '#,##0.##',
          width: 120
        }, {
          caption: 'Охранные зоны',
          allowEdit: true,
          dataField: 'guards',
          dataType: 'number',
          captionTooltipText: 'Охранные зоны',
          summaryType: 'sum',
          format: '#,##0.##',
          width: 120
        }]
    },
    {
      id: 'documents',
      name: 'Документы',
      fileNameExport: 'Документы',
      primaryKey: 'id',
      choiceOnClick: true,
      hideRowStatus: true,
      columns: [{
        caption: 'Номер документа',
        dataField: 'number',
        dataType: 'string'
      }, {
        caption: 'Дата документа',
        dataField: 'documentDate',
        dataType: 'date',
        sort: 'desc'
      }, {
        caption: 'Объект/Подобъект',
        dataField: 'object',
        dataType: 'string'
      }, {
        caption: 'Тип документа',
        dataField: 'documentType',
        dataType: 'string'
      }, {
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string'
      }, {
        caption: 'Описание',
        dataField: 'description',
        dataType: 'string'
      }, {
        caption: 'Версия',
        dataField: 'version',
        dataType: 'string'
      }, {
        caption: 'Статус',
        dataField: 'status',
        dataType: 'string'
      }, {
        caption: 'А',
        dataField: 'isActive',
        dataType: 'boolean'
      }, {
        caption: 'Дата создания',
        dataField: 'createdAt',
        dataType: 'date'
      }]
    },
    {
      id: 'sets',
      name: 'Наборы характериситик',
      primaryKey: 'id',
      columnAutoWidth: true,
      wordWrap: true,
      hideSelectedColumn: true,
      hideFilterHead: true,
      hideFilterRow: true,
      hideChooser: true,
      columns: [{
        caption: 'Наименование',
        dataField: 'name',
        dataType: 'string',
        sort: 'asc'
      },
        {
          caption: 'Комментарий',
          dataField: 'comments',
          dataType: 'string'
        }]
    },
    {
      id: 'subobject-characters1',
      editRole: 'subobject',
      showCaption: true,
      name: 'Характеристики подобъекта',
      fileNameExport: 'Характеристики подобъекта',
      primaryKey: 'id',
      editMode: 'cell',
      allowUpdate: true,
      hideSelectedColumn: true,
      hideFilterHead: true,
      hideFilterRow: true,
      hideChooser: true,
      columnAutoWidth: true,
      wordWrap: true,
      columns: [
        {
          caption: 'Описание характеристики',
          dataField: 'name',
          dataType: 'string',
          allowEdit: false,
          sort: 'asc'
        },
        {
          caption: 'Плановый объем: Ввод',
          dataField: 'planInputValue',
          dataType: 'string'
        },
        {
          caption: 'Выполненный объем: Ввод',
          dataField: 'factInputValue',
          dataType: 'string',
          allowEdit: false
        },
        {
          caption: 'Единица измерения',
          dataField: 'unit',
          dataType: 'string',
          allowEdit: false
        }]
    },
    {
      id: 'subobject-characters2',
      editRole: 'subobject',
      showCaption: true,
      name: 'Характеристики подобъекта',
      fileNameExport: 'Характеристики подобъекта',
      primaryKey: 'id',
      editMode: 'cell',
      allowUpdate: true,
      hideSelectedColumn: true,
      hideFilterHead: true,
      hideFilterRow: true,
      hideChooser: true,
      columnAutoWidth: true,
      wordWrap: true,
      columns: [
        {
          caption: 'Описание характеристики',
          dataField: 'name',
          dataType: 'string',
          allowEdit: false,
          sort: 'asc'
        },
        {
          caption: 'Характеристика до реализации ИП',
          dataField: 'beforeValue',
          dataType: 'string'
        },
        {
          caption: 'Плановый объем: Ввод',
          dataField: 'planInputValue',
          dataType: 'string'
        },
        {
          caption: 'Плановый объем: Вывод',
          dataField: 'planOutputValue',
          dataType: 'string'
        },
        {
          caption: 'Выполненный объем: Ввод',
          dataField: 'factInputValue',
          dataType: 'string',
          allowEdit: false
        },
        {
          caption: 'Выполненный объем: Вывод',
          dataField: 'factOutputValue',
          dataType: 'string',
          allowEdit: false
        },
        {
          caption: 'Единица измерения',
          dataField: 'unit',
          dataType: 'string',
          allowEdit: false
        }]
    },
    {
      id: 'document-mandatory-check',
      showCaption: true,
      name: 'Документы для ввода в ОФ',
      fileNameExport: 'Документы для ввода в ОФ',
      // showCountRow: true,
      fastDelete: false,
      primaryKey: 'id',
      remoteOperations: false,
      countPage: 20,
      // editRole: '',
      columnAutoWidth: true,
      wordWrap: true,
      allowRefresh: true,
      hideChooser: true,
      hideSelectedColumn: true,
      columns: [
        {
          caption: 'Тип документа',
          dataField: 'document_type_name',
          dataType: 'string',
          cellTemplate: 'longTextTemplate',
          width: 250,
          sort: 'asc'
        },
        {
          caption: 'Признак обязательности',
          dataField: 'is_required',
          dataType: 'boolean',
          width: 120
        }, {
          caption: 'Номер документа',
          dataField: 'number',
          dataType: 'string',
          cellTemplate: 'longTextTemplate',
          width: 400
        }, {
          caption: 'Дата документа',
          dataField: 'document_date',
          dataType: 'date'
        }, {
          caption: 'Наименование',
          dataField: 'name',
          dataType: 'string',
          buttons: [{
            icon: 'fa fa-eye',
            hint: 'Открыть',
            id: 'open',
            show: data => data.document_id ? 'block' : 'none'
          }
          ]
        }
      ]
    }
  ];
}
