import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {gridDictionary} from '../list-generator/generator-interfaces';
import {ChooseExecutorComponent} from '../choose-executor/choose-executor.component';
import {MatDialog} from '@angular/material';
import {DxDataGridComponent} from 'devextreme-angular';
import {ListGeneratorComponent} from '../list-generator/list-generator.component';
import notify from 'devextreme/ui/notify';
import {SubscriptionLike} from 'rxjs';
import {AuthenticationService} from '../../../services/authentication.service';
import {ActivatedRoute} from "@angular/router";
import {ContragentComponent} from "../../object/contragent/contragent.component";
import {RoleComponent} from "../../service/service/roles/role/role.component";
import {TreeLkContragentComponent} from "../../service/service/tree-lk-contragent/tree-lk-contragent.component";
import {UserService} from "../../../services/user.service";

export interface Gensubcontractor {
  id: number
  gencontractorId: number;
  subcontractorId: number;
  startPeriod: Date,
  endPeriod: Date,
  genShortName: string,
  subShortName: string,
  genInn: string,
  subInn: string
}

@Component({
  selector: 'app-subcontractors',
  templateUrl: './subcontractors.component.html',
  styleUrls: ['./subcontractors.component.scss'],
  providers: [UserService]
})
export class SubContractorsComponent implements OnInit, OnDestroy {
  //@Input() gencontractor = true;
  @Input() gencontractor = true;
  @Input() heightGrid;
  @Input() widthGrid;
  @Output() changedData = new EventEmitter<any>();
  subscriptions: SubscriptionLike[] = [];
  sub: any;
  gen: any;
  _gencontractor;
  _subcontractor;
  ids: number[];
  gensubcontractor: Gensubcontractor[];
  something;
  validationRule = {
    type: 'custom',
    message: 'Дата начала периода должна быть меньше или равной дате окончания!',
    validationCallback: (e) => {
      if (e.data.startPeriod && e.data.endPeriod) {
        const start = new Date(e.data.startPeriod).setHours(0);
        const end = new Date(e.data.endPeriod).setHours(12);
        return start <= end;
      } else {
        return true;
      }
    }
  };
  validationRuleContragent = {
    type: 'custom',
    message: 'Генподрядчик и субподрядчик не могут совпадать!',
    validationCallback: (e) => {
      if (e.data.gencontractorId && e.data.subcontractorId) {
        const gen = e.data.gencontractorId;
        const sub = e.data.subcontractorId;
        return gen != sub;
      } else {
        return true;
      }
    }
  };

  dictionary: gridDictionary = {
    id: 'subcontractors',
    primaryKey: 'id',
    name: 'Субподрядчик',
    remoteOperations: true,
    handleCellChanging: true,
    allowDelete: true,
    allowRefresh: true,
    allowUpdate: true,
    allowAdd: true,
    addRowCustom: true,
    editMode: 'batch',

    countPage: 15,
    wordWrap: true,
    fastDelete: true,
    hideFilterPanel: true,
    hideRowStatus: true,
    get: 'api/admin/subcontractors',
    post: 'api/admin/subcontractors',
    put: 'api/admin/subcontractors',
    delete: 'api/admin/subcontractors',
    columns: [
      {
        dataField: 'gencontractorId',
        caption: 'gencontractorId',
        dataType: 'number',
        visible: false
      }, {
        dataField: 'subcontractorId',
        caption: 'subcontractorId',
        dataType: 'number',
        visible: false
      },
      {
        dataField: 'number',
        caption: 'Номер договора',
        dataType: 'string',
        width: 150,
      },
      {
        dataField: 'date',
        caption: 'Дата договора',
        dataType: 'date',
        width: 110,
      },
      {
        dataField: 'genShortName',
        caption: 'Генподрядчик',
        dataType: 'string',
        cellTemplate: 'columnSubcontractorsInfo',
        editCellTemplate: 'columnSubcontractorsButtons',
        required: true,
        width: undefined,
        //allowEdit: true,
        validationRule: [this.validationRuleContragent]
      }, {
        dataField: 'subShortName',
        caption: 'Субподрядчик',
        dataType: 'string',
        width: undefined,
        //allowEdit: false,
        //visible: false,
        cellTemplate: 'columnSubcontractorsInfo',
        editCellTemplate: 'columnSubcontractorsButtons',
        /*validationRule: [{
          type: 'required'
        }],

         */
        validationRule: [this.validationRuleContragent]
      }, {
        dataField: 'isSupplier',
        caption: 'Признак поставщика',
        dataType: 'boolean',
        width: 130,
      }, {
        dataField: 'startPeriod',
        caption: 'Начало работ по договору',
        dataType: 'date',
        width: 110,
        validationRule: [this.validationRule]
      }, {
        dataField: 'endPeriod',
        caption: 'Окончание работ по договору',
        dataType: 'date',
        width: 110,
        validationRule: [this.validationRule]
      }, {
        dataField: 'description',
        caption: 'Примечание',
        dataType: 'string',
        width: 170,
      }, {
        dataField: 'genInn',
        caption: 'genInn',
        dataType: 'string',
        visible: false
      }, {
        dataField: 'subInn',
        caption: 'subInn',
        dataType: 'string',
        visible: false
      }, {
        dataField: 'genName',
        caption: 'genName',
        dataType: 'string',
        visible: false
      }, {
        dataField: 'subName',
        caption: 'subName',
        dataType: 'string',
        visible: false
      }]
  };

  @ViewChild('subcontractorsList', {static: false}) subcontractorsList: ListGeneratorComponent;

  constructor(public dialog: MatDialog, private activatedRoute: ActivatedRoute,private userService: UserService,) {
    console.log(this.activatedRoute.snapshot.data);
  }

  ngOnInit() {
    this.dictionary.fieldDelete = 'genShortName';
    this.dictionary.showCountRow = true;
  }

  choosePerson(field, newRow = true, dataRow?) {
    let hideExecutor = [];
    if (this.gencontractor) {
      if (field === 'gencontractor' && dataRow && dataRow.data.gencontractorId) {
        hideExecutor.push(dataRow.data.gencontractorId * -1);
      } else if (field === 'subcontractor' && dataRow && dataRow.data.subcontractorId) {
        hideExecutor.push(dataRow.data.subcontractorId * -1);
      }
    }

    console.log(field,newRow,dataRow)
    const dialogRef = this.dialog.open(
      TreeLkContragentComponent, {
        maxWidth: '75%',
        maxHeight: '130vh',
        data: {
          selectedRowKeys: [],
          executors: hideExecutor,
          singleSelect: true,
          hideSelect: hideExecutor
        }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (newRow) {
          if (field === 'gencontractor') {
            this._gencontractor = result[0];
          } else {
            this._subcontractor = result[0];
          }
          this.subcontractorsList.dataGrid.instance.addRow();
        } else if (dataRow) {
          if (field === 'gencontractor') {
            this.subcontractorsList.dataGrid.instance.cellValue(dataRow.rowIndex, 'gencontractorId', result[0].id);
            this.subcontractorsList.dataGrid.instance.cellValue(dataRow.rowIndex, 'genShortName',result[0].shortName);
            this.subcontractorsList.dataGrid.instance.cellValue(dataRow.rowIndex, 'genInn', result[0].inn);
          } else {
            this.subcontractorsList.dataGrid.instance.cellValue(dataRow.rowIndex, 'subcontractorId', result[0].id);
            this.subcontractorsList.dataGrid.instance.cellValue(dataRow.rowIndex, 'subShortName', result[0].shortName);
            this.subcontractorsList.dataGrid.instance.cellValue(dataRow.rowIndex, 'subInn', result[0].inn);

          }
        }
      }
      //this.new = false;
      console.log(result[0])
    });
  }


  buttonIconClick(e) {
    console.log(e);
    if (e) {
      if (e.id === 'gencontractorId' && e.data) {
        e.data.contragentId = e.data.gencontractorId
      }else{e.data.contragentId = e.data.subcontractorId}

      if(!e.data.contragentId ){this.choosePerson(e.id, false, e)}
      else if(e.data.subcontractorId==e.data.gencontractorId){this.choosePerson(e.id, false, e)}
      else if(e.data.statusRow ===  'i')  {this.choosePerson(e.id === 'gencontractorId' ? 'gencontractor' : 'subcontractorId' , false, e)}
      else if(e.data.statusRow ===  's')  {this.seeContragent(e.id, false, e)};
    }

  }
  seeContragent(field, newRow = true, dataRow?) {
    console.log(field,newRow,dataRow);
    let hideExecutor = [];
    if (field === 'gencontractorId' && dataRow && dataRow.data.gencontractorId) {
      hideExecutor.push(dataRow.data.gencontractorId * -1);
      dataRow.data.contragentId = dataRow.data.gencontractorId
    } else if (field === 'subcontractorId' && dataRow && dataRow.data.subcontractorId) {
      hideExecutor.push(dataRow.data.subcontractorId * -1);
      dataRow.data.contragentId = dataRow.data.subcontractorId
    }

    if (dataRow.data.contragentId) {
      const dialogRef = this.dialog.open(ContragentComponent, {
        minWidth: '640px',
        maxWidth: '1280px',
        maxHeight: '90vh',
        data: {
          id: dataRow.data.contragentId,
          executors: hideExecutor,
          singleSelect: true,
          hideSelect: hideExecutor
        },

        disableClose: false
      });
      dialogRef.afterClosed().subscribe((resultType) => {
      });
    } else {
      notify('Карточка контрагента отсутствует!', 'warning', 2000);
    }

  }


  addClick(e) {
    console.log(e);
    this.choosePerson(this.gencontractor ? 'gencontractor' : 'subcontractor');
  }
  modifiedData(e) {
    console.log(e);
    if (this._gencontractor) {
      e.gencontractorId = this._gencontractor.id;
      e.genShortName = this._gencontractor.shortName;
      e.genInn = this._gencontractor.inn;
      this._gencontractor = null;
    } else if (this._subcontractor) {
      e.subcontractorId = this._subcontractor.id;
      e.subShortName = this._subcontractor.shortName;
      e.subInn = this._subcontractor.inn;
      this._subcontractor = null;
    }
    this.changedData.emit();
  }

  saveData() {
    this.subcontractorsList.dataGrid.instance.saveEditData();
  }

  checkValidation() {
    return this.subcontractorsList.checkValidationRules();
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

}
