import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Dictionaries} from '../../dictionaries';
import {AuthenticationService} from "../../../../services/authentication.service";

@Component({
  selector: 'app-common-choice',
  templateUrl: './common-choice.component.html',
  styleUrls: ['./common-choice.component.scss']
})
export class CommonChoiceComponent implements OnInit, OnDestroy {
  dictionary: any;
  getQueryParam: any;
  getUrl: string;
  dataSource: any;
  heightGrid: number;

  constructor(public dialogRef: MatDialogRef<CommonChoiceComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public authService: AuthenticationService,
  ) {

  }

  ngOnInit() {
    const cdkoverlaycontainer = document.getElementsByClassName('cdk-overlay-container') as HTMLCollectionOf<HTMLElement>;
    if (cdkoverlaycontainer) {
      cdkoverlaycontainer[0].style.zIndex = '1501';
    }
    this.dictionary = Dictionaries.dictionaries.find(x => x.id === this.data.dictionary);
    if (['choiceOnClickDoc', 'choiceOnClickTypeDocs', 'choiceTypeDocs', 'choiceOnClickContract'].includes(this.data.dictionary)) {
      this.dictionary = JSON.parse(JSON.stringify(this.dictionary));
    }
    if (this.data.queryParam)
      this.dictionary.queryParam = (this.dictionary.queryParam || []).concat(this.data.queryParam);

    if (this.dictionary.queryParam) {
      this.getQueryParam = this.dictionary.queryParam;
    }
    if (this.data.disableDefaultFilter) {
      this.dictionary.defaultFilter = undefined
    }
    if (this.data.queryParam) {
      this.dictionary.queryParam = this.data.queryParam;
    }
    this.getUrl = this.dictionary.get;
    if (!this.isEmpty(this.data.form) && !this.dictionary.get.includes('form=')) {
      this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + 'form=' + this.data.form;
    }
    if (this.data.objectId && this.data.objectId.length > 0) {
      this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + (this.data.showCommon ? '' : 'common=false&') + `objectId=${this.data.objectId[0]}`;
    }
    if (this.data.typeId) {
      this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + `typeId=${this.data.typeId}`;
    }
    if (this.data.hideCommon) {
      this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + 'hideCommon=true';
    }
    if (this.data.checkParentType) {
      this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + `checkParentType=${this.data.checkParentType}`;
    }
    if (this.data.dataSource) {
      this.dataSource = this.data.dataSource;
    }

    if (this.data.titleId) {
      this.dictionary.get = this.dictionary.get + (this.dictionary.get.includes('?') ? '&' : '?') + `titleId=${this.data.titleId}`;
    }

    if (['choiceOnClickDoc', 'choiceOnClickTypeDocs', 'choiceTypeDocs'].includes(this.data.dictionary) && !this.data.withoutCheckTypeLink) {
      this.dictionary.showCaption = this.data.dictionary === 'choiceOnClickTypeDocs';
      const column = this.dictionary.columns.find(x => x.dataField === 'object');
      switch (this.data.typeLink) {
        case 'title':
          this.dictionary.queryParam = [['typeLink', 'title']];
          this.dictionary.name = 'Выбор типов для ИП/элемента ИП';
          if (column) { column.caption = 'ИП/Элементы ИП'; }
          break;
        default:
          this.dictionary.queryParam = [['typeLink', 'object']];
          this.dictionary.name = 'Выбор типов для объекта/подобъекта';
          if (column) { column.caption = 'Объект/Подобъект'; }
          break;
      }
    }

    ['journal'].forEach(x => this.data[x]
      && (this.dictionary.get += `${(this.dictionary.get.includes('?') && '&'  || '?')}${x}=${this.data[x]}`));
  }

  ngOnDestroy(): void {
    this.dictionary.get = this.getUrl;
    if (this.dictionary.queryParam) {
      this.dictionary.queryParam = this.getQueryParam;
    }
  }

  closeDialog() {
    const cdkoverlaycontainer = document.getElementsByClassName('cdk-overlay-container') as HTMLCollectionOf<HTMLElement>;
    if (cdkoverlaycontainer) {
      cdkoverlaycontainer[0].removeAttribute('style');
    }
    this.dictionary.get = this.getUrl;
    if (this.dictionary.queryParam) {
      this.dictionary.queryParam = this.getQueryParam;
    }
    if (this.dictionary.defaultFilter && this.dictionary.defaultFilter.dataSource && this.dictionary.defaultFilter.dataSource.defaultValue) {
      this.dictionary.defaultFilter.dataSource.values = this.dictionary.defaultFilter.dataSource.defaultValue
      this.dictionary.defaultFilter.dataSource.filterText = this.dictionary.defaultFilter.dataSource.setFilterText(this.dictionary.defaultFilter.dataSource.defaultValue);
    }
    this.dialogRef.close();
  }

  choiceClick(data) {
    const cdkoverlaycontainer = document.getElementsByClassName('cdk-overlay-container') as HTMLCollectionOf<HTMLElement>;
    if (cdkoverlaycontainer) {
      cdkoverlaycontainer[0].removeAttribute('style');
    }
    this.dictionary.get = this.getUrl;
    if (this.dictionary.queryParam) {
      this.dictionary.queryParam = this.getQueryParam;
    }
    if (this.dictionary.defaultFilter && this.dictionary.defaultFilter.dataSource && this.dictionary.defaultFilter.dataSource.defaultValue) {
      this.dictionary.defaultFilter.dataSource.values = this.dictionary.defaultFilter.dataSource.defaultValue
      this.dictionary.defaultFilter.dataSource.filterText = this.dictionary.defaultFilter.dataSource.setFilterText(this.dictionary.defaultFilter.dataSource.defaultValue);
    }
    this.dialogRef.close(data);
  }

  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  buttonIconClick(e) {
    console.log(e);
    if (e.id === 'download' && e.data) {
      if (!this.isEmpty(e.data.version)) {
        window.open(`/api/document/file?id=${e.data.documentId}&type=main&version=${e.data.version}`);
      }
    }
  }
}
